import React, { useEffect } from "react";
import { Button, Box, HStack } from "@chakra-ui/react";
import moment from "moment";

import { LICENSES_STATUS, DURATION_LICENSE, TYPE_LICENSE } from "../../utils/DataMaps";
import DomainHead from "./components/DomainHead";
import FormCard from "components/layout/FormCard";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import { ShopAction, ShopSelect } from "store/modules/shop";
import useToastNotification from "hooks/useToastNotification";
import FormBuilder from "components/layout/FormBuilder";
import withPermissions from "components/hoc/withPermissions";
import ButtonPermission from "components/layout/ButtonPermission";

const UpdateLicensePage = () => {
  const { lid, id } = useParams();
  const { select, dispatch } = useBehave();
  const domain = select(ShopSelect.domain(lid));
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const loadingLicense = useTask("fetching", lid);
  const loadingStatus = useTask("updating", `${domain?._id}Status`);
  const loadingTransferLicense = useTask("updating", `${domain?._id}Domain`);
  const loadingPrice = useTask("updating", `${domain?._id}Price`);
  const loadingContacts = useTask("updating", `${domain?._id}Contact`);
  const loadingBillings = useTask("updating", `${domain?._id}Billing`);
  const loadingDelete = useTask("deleting", lid);

  useToastNotification(loadingStatus, { success: { description: "Status updated succesfully" } });
  useToastNotification(loadingTransferLicense, {
    success: { description: "Domain transfered successfully" },
  });
  useToastNotification(loadingPrice, {
    success: { description: "Price and date updated succesfully" },
  });
  useToastNotification(loadingContacts, {
    success: { description: "Contact information updated succesfully" },
  });
  useToastNotification(loadingBillings, {
    success: { description: "Billing information updated succesfully" },
  });
  useToastNotification(loadingDelete, { success: { description: "Domain delete succesfully!" } });

  useTaskCallback(loadingDelete, {
    success: () => navigate(`/${i18n.language}/users/${id}/licenses`),
  });

  const handleChangePlan = (variables: DomainSubscription) => {
    console.log(variables);
  };
  const handleChangePlanStatus = (data: DomainSubscription) => {
    dispatch(ShopAction.changePlanStatus({ _id: domain?._id, status: data.plan.status }));
  };
  const handleTransferDomain = (variables: DomainSubscription) => {
    dispatch(ShopAction.transferDomain({ _id: domain?._id, domain: variables.domain }));
  };
  const handleUpdateContacts = (variables: DomainSubscription) => {
    dispatch(ShopAction.updateContacts({ _id: variables._id, contact: variables.contact }));
  };
  const handleUpdateBilling = (variables: DomainSubscription) => {
    if (!variables.billing) return;
    dispatch(
      ShopAction.updateBillingInformation({ _id: variables._id, billing: variables.billing })
    );
  };
  const handleRenew = (variables: DomainSubscription) => {
    dispatch(
      ShopAction.editPriceAndRenewDate({
        _id: domain?._id,
        price: variables.plan.price,
        date: variables.plan.renewDate,
        auto_renew: variables.auto_renew,
      })
    );
  };

  useEffect(() => {
    if (!lid) return;
    dispatch(ShopAction.fetchDomain(lid));
  }, [lid]);

  return (
    <FormBuilder
      editMode
      cardRender={FormCard}
      empty={!domain}
      loading={loadingLicense.isLoading ? "Loading licenses..." : undefined}
      fallback={{
        message: (
          <>
            {t("lincense.formFallback.message_1")}
            <b>{lid}</b> {t("lincense.formFallback.message_2")}
          </>
        ),
        action: (
          <NavLink to={`/${i18n.language}/users`}>
            <Button>{t("general.button.goBack")}</Button>
          </NavLink>
        ),
      }}
      cards={[
        <DomainHead {...domain} />,
        {
          rightAction: (
            <Button type="submit" variant="brand" isLoading={loadingStatus.isLoading}>
              {t("general.button.save")}
            </Button>
          ),

          submit: {
            onSubmit: handleChangePlanStatus,
          },
          initialValues: domain,
          title: t("license.form.title1st"),
          subtitle: t("license.form.subtitle1st"),
          elements: [
            {
              id: "plan.status",
              type: "select",
              options: LICENSES_STATUS,
            },
          ],
        },
        {
          rightAction: (
            <Button variant="brand" type="submit" isLoading={loadingTransferLicense.isLoading}>
              {t("license.form.action.transfer")}
            </Button>
          ),

          submit: {
            onSubmit: handleTransferDomain,
          },
          title: t("license.form.title2nd"),
          subtitle: t("license.form.subtitle2nd"),
          elements: [
            {
              id: "domain",
              type: "text",
              placeholder: domain?.domain,
            },
          ],
        },
        {
          rightAction: (
            <Button variant="brand" type="submit" disabled={true}>
              {t("license.form.action.changePlan")}
            </Button>
          ),

          submit: {
            onSubmit: handleChangePlan,
          },
          initialValues: domain,
          title: t("license.form.title3rd"),
          subtitle: t("license.form.subtitle3rd"),
          elements: [
            {
              id: "plan.type",
              type: "select",
              disabled: true,
              placeholder: domain?.plan.type,
              options: DURATION_LICENSE,
            },
            {
              id: "plan.id",
              type: "select",
              disabled: true,
              placeholder: domain?.plan.id,
              options: TYPE_LICENSE,
            },
          ],
        },
        {
          rightAction: (
            <Button variant="brand" type="submit" isLoading={loadingPrice.isLoading}>
              {t("general.button.edit")}
            </Button>
          ),

          submit: {
            onSubmit: handleRenew,
          },
          initialValues: domain,
          intialTransforms: [
            [
              "plan.renewDate",
              (v) => moment(v).format("yyyy-MM-DD"),
              (v) => moment(v, "yyyy-MM-DD").toDate().getTime(),
            ],
            ["plan.price", (v) => v / 100, (v) => v * 100],
          ],
          title: t("license.form.title4th"),
          subtitle: t("license.form.subtitle4th"),
          elements: [
            {
              id: "plan.price",
              type: "number",
              rightAddon: "€",
              placeholder: `${domain?.plan.price}`,
            },
            {
              id: "plan.renewDate",
              type: "date",
            },
            {
              id: "auto_renew",
              type: "switch",
              label: t("general.form.label.autoRenew"),
            },
          ],
        },
        {
          rightAction: (
            <Button variant="brand" type="submit" isLoading={loadingContacts.isLoading}>
              {t("general.button.update")}
            </Button>
          ),

          submit: {
            onSubmit: handleUpdateContacts,
          },
          initialValues: domain,
          title: t("license.form.title5th"),
          subtitle: t("license.form.subtitle5th"),
          elements: [
            {
              label: t("general.form.label.name"),
              id: "contact.name",
              type: "text",
              placeholder: domain?.contact.name,
            },
            {
              label: t("general.form.label.email"),
              id: "contact.email",
              type: "text",
              placeholder: domain?.contact.email,
            },
            {
              label: t("general.form.label.phone"),
              id: "contact.phone",
              type: "text",
              placeholder: domain?.contact.phone,
            },
          ],
        },
        {
          rightAction: (
            <Button variant="brand" type="submit" isLoading={loadingBillings.isLoading}>
              {t("general.button.update")}
            </Button>
          ),

          submit: {
            onSubmit: handleUpdateBilling,
          },
          initialValues: domain,
          title: t("license.form.title6th"),
          subtitle: t("license.form.subtitle6th"),
          elements: [
            [
              {
                label: t("general.form.label.name"),
                id: "billing.name",
                type: "text",
                placeholder: domain?.billing?.name,
              },
              {
                label: t("general.form.label.country"),
                id: "billing.address.country",
                type: "text",
                placeholder: domain?.billing?.address.country,
              },
            ],
            [
              {
                label: t("general.form.label.surname"),
                id: "billing.surname",
                type: "text",
                placeholder: domain?.billing?.surname,
              },
              {
                label: t("general.form.label.address"),
                id: "billing.address.address",
                type: "text",
                placeholder: domain?.billing?.address.address,
              },
            ],
            [
              {
                label: t("general.form.label.email"),
                id: "billing.emailPec",
                type: "text",
                placeholder: domain?.billing?.emailPec,
              },
              {
                label: t("general.form.label.city"),
                id: "billing.address.city",
                type: "text",
                placeholder: domain?.billing?.address.city,
              },
            ],
            [
              {
                label: t("general.form.label.piva"),
                id: "billing.vatNumber",
                type: "text",
                placeholder: domain?.billing?.vatNumber,
              },
              {
                label: t("general.form.label.province"),
                id: "billing.address.state",
                type: "text",
                placeholder: domain?.billing?.vatNumber,
              },
            ],
            [
              {
                label: t("general.form.label.uniqueCode"),
                id: "billing.uniqueCode",
                type: "text",
                placeholder: domain?.billing?.uniqueCode,
              },
              {
                label: t("general.form.label.postalCode"),
                id: "billing.address.postalCode",
                type: "text",
                placeholder: domain?.billing?.address.postalCode,
              },
            ],
          ],
        },
        {
          title: "Delete license",
          subtitle: "Handle this actions with care.",
          elements: [
            <HStack w="100%">
              <ButtonPermission
                children="Delete license"
                action="deleteSubscription"
                colorScheme="red"
                variant="solid"
                needConfirmation
                onClick={() =>
                  dispatch(ShopAction.deleteDomain({ _id: lid ?? "", user: id ?? "" }))
                }
                isLoading={loadingDelete.isLoading}
              />
            </HStack>,
          ],
        },
      ]}
    />
  );
};

export default UpdateLicensePage;
