import React from "react";
import { Flex, Text, Button, Box, ThemingProps } from "@chakra-ui/react";

export interface SubmitCardProps<T> {
  loading?: boolean;
  title?: string;
  subtitle?: string;
  action?: string;
  cardRender?: React.JSXElementConstructor<any>;
  color?: ThemingProps<"Button">["colorScheme"];
  onSubmit?: (values: T) => void;
}

const SubmitUser = <T,>(props: SubmitCardProps<T>) => {
  const CardWrapper = ({ children }: any) => {
    if (props.cardRender) return <props.cardRender submit>{children}</props.cardRender>;
    else
      return (
        <Flex direction="column" gap="8px">
          {children}
        </Flex>
      );
  };

  return (
    <CardWrapper>
      <Flex direction="column">
        <Text fontSize="18px" fontWeight="700">
          {props.title}
        </Text>
        <Text opacity="0.5">{props.subtitle}</Text>
      </Flex>
      <Button
        type="submit"
        padding="0px 24px"
        colorScheme={props.color}
        variant="outline"
        isLoading={props.loading}
      >
        {props.action}
      </Button>
    </CardWrapper>
  );
};

export default SubmitUser;
