import { firebaseCall } from "../utils/firebase.api.utils";

export const getDomain: ApiCallback<SimpleId, DomainSubscription> = (payload) => {
  return firebaseCall("admin-getDomain")(payload);
};

export const getUserDomains: ApiCallback<SimpleId, [DomainSubscription]> = (payload) => {
  return firebaseCall("admin-getDomainsForUser")(payload);
};
export const createSubscriptions: ApiCallback<AdminCreatesLicense, AdminCreatesLicenseResponse> = (
  payload
) => {
  return firebaseCall("admin-createSubscription")(payload);
};
export const deleteSubscription: ApiCallback<SimpleIdWithUser, boolean> = (payload) => {
  return firebaseCall("admin-deleteSubscription")(payload);
};
export const changeContact: ApiCallback<UpdatePlanContact, boolean> = (payload) => {
  return firebaseCall("admin-updateSubscriptionContact")(payload);
};
export const changeBilling: ApiCallback<UpdatePlanBilling, boolean> = (payload) => {
  return firebaseCall("admin-updateSubscriptionBilling")(payload);
};
export const changePlanStatus: ApiCallback<ChangePlanStatus, boolean> = (payload) => {
  return firebaseCall("admin-changePlanStatus")(payload);
};
export const changePlan: ApiCallback<ChangePlan, boolean> = (payload) => {
  return firebaseCall("admin-changePlan")(payload);
};
export const transferDomain: ApiCallback<TransferDomain, boolean> = (payload) => {
  return firebaseCall("admin-transferSubscriptionDomain")(payload);
};
export const changePriceDate: ApiCallback<ChangePricePayload, boolean> = (payload) => {
  return firebaseCall("admin-changeSubscriptionPriceDate")(payload);
};
export const getPendingRenewal: ApiCallback<void, RenewalsSchema> = (payload) => {
  return firebaseCall("admin-getPendingRenewal")(payload);
};
export const deletePendingRenewal: ApiCallback<DeleteRenewal, boolean> = (payload) => {
  return firebaseCall("admin-deletePendingRenewal")(payload);
};
