import { ThemeOverride } from "@chakra-ui/react";

export const tabsStyle: ThemeOverride = {
  components: {
    Tab: {
      baseStyle: {
        padding: "5px 0px",
        _focus: {
          boxShadow: "none !important",
        },
        _selected: {
          color: "brand.200",
          borderBottom: "2px solid",
          borderBottomColor: "brand.200",
          padding: "0px",
        },
      },
      variants: {
        variants01: () => ({
          borderRadius: "16px",
        }),
        base: () => ({
          padding: "5px 0px",
          _focus: {
            boxShadow: "none !important",
          },
          _selected: {
            color: "brand.200",
            borderBottom: "2px solid",
            borderBottomColor: "brand.200",
            padding: "0px",
          },
        }),
      },
    },
  },
};
