import { Button, Divider, Flex } from "@chakra-ui/react";
import React from "react";

import { Control, FieldValues, useFieldArray } from "react-hook-form";
import RenderElement from "../Components/RenderElement";
import { BuilderElement } from "../Types/type";

interface ArrayFieldProps {
  id: string;
  control: Control<FieldValues, any>;
  register: any;
  errors: any;
  setValue: any;
  getValues: any;
  elements: Array<BuilderElement<any>>;
  disabled?: boolean;
}

const ArrayField = ({
  control,
  id,
  register,
  elements,
  errors,
  setValue,
  disabled,
  getValues,
}: ArrayFieldProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: id,
  });

  return (
    <Flex direction="column" gap="6px">
      {fields.map((field, index) => (
        <>
          <Flex gap="6px" alignItems="center" key={field.id}>
            {elements.map((e) => (
              <RenderElement
                key={field.id}
                data={{ ...e, id: `${id}.${index}.${e.id}` }}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                register={register}
              />
            ))}
            {!disabled && (
              <Button
                mt="27px"
                onClick={() => remove(index)}
                colorScheme="red"
                variant="outline"
                minWidth="44px"
                w="max-content"
              >
                -
              </Button>
            )}
          </Flex>
        </>
      ))}
      {!disabled && (
        <Button
          onClick={() => append({})}
          colorScheme="green"
          variant="outline"
          w="fit-content"
          alignSelf="self-end"
        >
          +
        </Button>
      )}
    </Flex>
  );
};

export default ArrayField;
