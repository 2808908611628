// Chakra imports
import { Button, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import ReactQuill from "react-quill";
import { RiSendPlaneLine, RiDeleteBin2Line } from "react-icons/ri";
import "./style.css";
import { useTranslation } from "react-i18next";

interface EditorProps {
  loading?: boolean;
  onSend: (s: string) => void;
}

const TextEditor = ({ onSend, loading }: EditorProps) => {
  const [html, setHtml] = useState<string>("");
  const { t } = useTranslation();

  return (
    <Flex w="100%" direction="column" gap="12px">
      <Flex gap="12px" alignItems="center">
        <Text flex="1" fontWeight="bold" fontSize="20px">
          {t("textEditor.placeholder")}
        </Text>
        <Button
          onClick={() => setHtml("")}
          leftIcon={<RiDeleteBin2Line />}
          type="submit"
          variant="outline"
          w="100px"
        >
          {t("textEditor.button.clear")}
        </Button>
        <Button
          isLoading={loading}
          onClick={() => onSend(html ? html : "")}
          leftIcon={<RiSendPlaneLine />}
          type="submit"
          variant="outline"
          w="100px"
        >
          {t("textEditor.button.send")}
        </Button>
      </Flex>
      <ReactQuill placeholder="Write here a message" onChange={setHtml} />
    </Flex>
  );
};

export default TextEditor;
