import { Flex } from "@chakra-ui/react";
import { useBehave, useTask } from "behave-react";
import withPermissions from "components/hoc/withPermissions";
import ButtonPermission from "components/layout/ButtonPermission";
import useToastNotification from "hooks/useToastNotification";
import { ShopAction } from "store/modules/shop";
import { UserAction } from "store/modules/users";

interface TransferRequestActionsProps {
  id: string;
  t: (s: string) => string;
}

const TransferRequestActions = ({ id, t }: TransferRequestActionsProps) => {
  const loading = useTask("accepting", id);
  const loadingRefuse = useTask("refusing", id);
  const { dispatch } = useBehave();
  useToastNotification(loading, { success: { description: "Request accepted succesfully!" } });
  useToastNotification(loadingRefuse, {
    success: { description: "Request rejected succesfully!" },
  });
  const handleAcceptRequest = () => {
    dispatch(ShopAction.acceptTransferRequest(id));
  };
  const handleRefuseRequest = () => {
    dispatch(ShopAction.rejectTransferRequest(id));
  };

  return (
    <Flex gap="10px">
      <ButtonPermission
        variant="outline"
        size="sm"
        isLoading={loading.isLoading}
        colorScheme="green"
        action="acceptPartnerRequest"
        onClick={handleAcceptRequest}
      >
        {t("general.button.accept")}
      </ButtonPermission>
      <ButtonPermission
        colorScheme="red"
        size="sm"
        action="refusePartnerRequest"
        isLoading={loadingRefuse.isLoading}
        variant="outline"
        onClick={handleRefuseRequest}
      >
        {t("general.button.refuse")}
      </ButtonPermission>
    </Flex>
  );
};

export default withPermissions("acceptTransferRequest", TransferRequestActions);
