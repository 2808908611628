import Card from "../../components/layout/Card";
import TableComponent from "components/layout/TableComponent";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Button } from "@chakra-ui/react";
import ColumnsUser from "./TableColumns";
import { useBehave, useTask } from "behave-react";
import { UserAction, UserSelect } from "store/modules/users";
import ButtonPermission from "components/layout/ButtonPermission";
import { IoAdd } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import withPermissions from "components/hoc/withPermissions";

const UsersPage = () => {
  const { t } = useTranslation();
  const { select, dispatch } = useBehave();

  const loadingUser = useTask("fetching", "users");
  const users = select(UserSelect.users);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = () => dispatch(UserAction.fetchUsers());

  const handleQuery = (i: string, data: User) => {
    return (
      data._id.toLowerCase().includes(i) ||
      data.company?.name?.toLowerCase().includes(i) ||
      data.email.toLowerCase().includes(i) ||
      data.name.toLowerCase().includes(i) ||
      data.surname.toLowerCase().includes(i)
    );
  };

  return (
    <Card direction="column" w="100%" p="24px 0px">
      <TableComponent
        columns={ColumnsUser({ t })}
        data={users}
        isLoading={loadingUser.isLoading}
        query={handleQuery}
        queryPlaceholder={t("users.tablePage.queryPlaceholder")}
        pageSiblings={8}
        onRefresh={handleRefresh}
        action2={
          <ButtonPermission to="create" variant="brand" leftIcon={<IoAdd />} action="createUser">
            Create user
          </ButtonPermission>
        }
        pageSize={10}
      />
    </Card>
  );
};

export default UsersPage;
