import { PAction } from "behave-react";
import select from "utils/ArrayUtils";

import { ShopInitialState } from ".";

const AsyncReducers = {
  //ORDERS
  fetchUserOrders: (state: ShopModuleState, action: PAction<string>) => {},
  updateUserOrder: (state: ShopModuleState, action: PAction<UpdateOrder>) => {},
  fetchOrder: (state: ShopModuleState, action: PAction<string>) => {},

  // DOMAINS
  fetchUserDomains: (state: ShopModuleState, action: PAction<string>) => {},
  createDomainSubscription: (state: ShopModuleState, action: PAction<AdminCreatesLicense>) => {},
  deleteDomain: (state: ShopModuleState, action: PAction<SimpleIdWithUser>) => {},
  changePlanStatus: (state: ShopModuleState, action: PAction<ChangePlanStatus>) => {},
  fetchDomain: (state: ShopModuleState, action: PAction<string>) => {},

  editPriceAndRenewDate: (state: ShopModuleState, action: PAction<ChangePricePayload>) => {},
  updateContacts: (state: ShopModuleState, action: PAction<UpdatePlanContact>) => {},
  updateBillingInformation: (state: ShopModuleState, action: PAction<UpdatePlanBilling>) => {},

  // TRANSFER
  fetchTransferRequests: (state: ShopModuleState) => {},
  acceptTransferRequest: (state: ShopModuleState, action: PAction<string>) => {},
  rejectTransferRequest: (state: ShopModuleState, action: PAction<string>) => {},
  transferDomain: (state: ShopModuleState, action: PAction<TransferDomain>) => {},

  fetchCoupons: (state: ShopModuleState) => {},
  fetchUserCoupon: (state: ShopModuleState) => {},
  createCoupon: (state: ShopModuleState, action: PAction<CreateCoupon>) => {},
  deleteCoupon: (state: ShopModuleState, action: PAction<string>) => {},

  fetchRenewals: (state: ShopModuleState) => {},
  deletePendingRenewal: (state: ShopModuleState, action: PAction<DeleteRenewal>) => {},
};

const Reducers = {
  _setCoupons: (state: ShopModuleState, action: PAction<Coupon[]>) => {
    state.coupons = action.payload;
  },

  _setCoupon: (state: ShopModuleState, action: PAction<Coupon>) => {
    select(state.coupons ?? [], "code", action.payload.code).set(action.payload);
  },

  _deleteCoupon: (state: ShopModuleState, action: PAction<string>) => {
    select(state.coupons ?? [], "code", action.payload).pop();
  },

  _deleteDomain: (state: ShopModuleState, action: PAction<SimpleIdWithUser>) => {
    select(state.domains ?? [], "_id", action.payload._id).pop();
  },

  _setDomains: (state: ShopModuleState, action: PAction<DomainSubscription[]>) => {
    state.domains = [
      ...(state.domains?.filter((a) => a.user !== action.payload[0].user) ?? []),
      ...action.payload,
    ];
  },

  _setDomain: (state: ShopModuleState, action: PAction<DomainSubscription>) => {
    select(state.domains ?? [], "_id", action.payload._id).set(action.payload);
  },

  _setDomainStatus: (state: ShopModuleState, action: PAction<ChangePlanStatus>) => {
    select(state.domains ?? [], "_id", action.payload._id).update(
      "plan.status",
      action.payload.status
    );
  },

  _setOrders: (state: ShopModuleState, action: PAction<Order[]>) => {
    state.orders = state.orders ?? [];
    action.payload.forEach((c) => {
      if (select(state.orders ?? [], "_id", c._id).element)
        select(state.orders ?? [], "_id", c._id).set(c);
      else state.orders?.push(c);
    });
  },

  _updateOrder: (state: ShopModuleState, action: PAction<UpdateOrder>) => {
    select(state.orders ?? [], "_id", action.payload._id).merge(action.payload);
  },

  _setTransfers: (state: ShopModuleState, action: PAction<TransferRequest[]>) => {
    state.transfers = action.payload;
  },
  _deleteTransferRequest: (state: ShopModuleState, action: PAction<string>) => {
    select(state.transfers ?? [], "user_id", action.payload).pop();
  },
  _transferDomain: (state: ShopModuleState, action: PAction<TransferDomain>) => {
    select(state.domains ?? [], "_id", action.payload._id).update("domain", action.payload.domain);
  },

  _updatePrice: (state: ShopModuleState, action: PAction<ChangePricePayload>) => {
    const el = select(state.domains ?? [], "_id", action.payload._id).element as DomainSubscription;
    el.auto_renew = action.payload.auto_renew;
    el.plan.price = action.payload.price;
    el.plan.renewDate = action.payload.date;
  },
  _updateContact: (state: ShopModuleState, action: PAction<UpdatePlanContact>) => {
    const el = select(state.domains ?? [], "_id", action.payload._id).element as DomainSubscription;
    el.contact = action.payload.contact;
  },
  _updateBilling: (state: ShopModuleState, action: PAction<UpdatePlanBilling>) => {
    const el = select(state.domains ?? [], "_id", action.payload._id).element as DomainSubscription;
    el.billing = action.payload.billing;
  },

  _setOrder: (state: ShopModuleState, action: PAction<Order>) => {
    select(state.orders ?? [], "_id", action.payload._id).set(action.payload);
  },

  _setRenewals: (state: ShopModuleState, action: PAction<RenewalsSchema>) => {
    state.renewals = action.payload;
  },
  _deleteRenewal: (state: ShopModuleState, action: PAction<DeleteRenewal>) => {
    select(state.renewals?.[action.payload.list].list ?? [], "_id", action.payload._id).pop();
  },
  purge: (state: ShopModuleState) => ({ ...ShopInitialState }),
};

const UserReducers = { actions: Reducers, saga: AsyncReducers };
export default UserReducers;
