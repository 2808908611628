import React from "react";
import { useBehave, useTask } from "behave-react";
import OrderReferralColumn from "./OrderReferral.columns";
import Card from "components/layout/Card";
import { AiOutlinePlus } from "react-icons/ai";
import {
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import TableComponent from "components/layout/TableComponent";
import CreateOrderReferral from "./CreateOrderReferral";
import { UserAction } from "store/modules/users";
import useToastNotification from "hooks/useToastNotification";
import ButtonPermission from "components/layout/ButtonPermission";

const OrderReferralTable = (partner: UserPartner) => {
  const { dispatch } = useBehave();

  const loading = useTask("updating", `${partner._id}referralHistory`);

  const handleQuery = (i: string, data: LinkUsage) => {
    return data.user.includes(i);
  };

  const OrderReferralModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <>
        <ButtonPermission action="editPartnerReferral" onClick={onOpen} variant="outline">
          <Icon as={AiOutlinePlus} w="5" h="5" />
        </ButtonPermission>
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <CreateOrderReferral partner={partner} onFinish={onClose} />
          </ModalContent>
        </Modal>
      </>
    );
  };

  const handleDelete = (link: LinkUsage) => {
    const history = [...(partner.link_referral.history ?? [])];
    const i = history.findIndex((l) => l === link);
    history.splice(i, 1);
    dispatch(
      UserAction.updateReferralHistory({
        _id: partner.link_referral._id,
        history,
        partner: partner._id,
      })
    );
  };

  return (
    <Card direction="column" w="100%" px="0px" mt="20px">
      <TableComponent
        columns={OrderReferralColumn(handleDelete, loading.isLoading, partner)}
        data={partner.link_referral.history}
        queryPlaceholder="Search for history"
        pageSiblings={4}
        pageSize={6}
        query={handleQuery}
        action1={<OrderReferralModal />}
      />
    </Card>
  );
};

export default OrderReferralTable;
