import {
  Text,
  Flex,
  Button,
  Box,
  Popover,
  PopoverTrigger as PT,
  PopoverBody,
  PopoverContent,
} from "@chakra-ui/react";
import { TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";

const PopoverTrigger: any = PT;

interface ColorPopoverProps {
  color: string;
  handleColor: (c: any) => void;
}
const ColorPopover = ({ color, handleColor }: ColorPopoverProps) => {
  const { t } = useTranslation();
  return (
    <Box mt="10px">
      <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
        {t("installGuide.configurator.sectionTitle.color")}
      </Text>
      <Flex alignItems="center">
        <Popover>
          <PopoverTrigger>
            <Button
              size="sm"
              bg="transparent"
              p="0px"
              _hover={{ background: "none" }}
              _visited={{ background: "none" }}
              height="auto"
            >
              <Box
                w="8"
                h="8"
                borderRadius="7px"
                bg={color}
                border="2px solid"
                borderColor={`${color}05`}
              ></Box>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            _focus={{ boxShadow: "none !important" }}
            _selected={{
              color: "brand.200",
              borderBottom: "2px solid",
              borderBottomColor: "brand.200",
              padding: "0px",
            }}
            bg="transparent"
            border="none"
          >
            <PopoverBody>
              <TwitterPicker color={color} onChange={handleColor} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Text fontWeight="400" ml="10px">
          {color}
        </Text>
      </Flex>
    </Box>
  );
};

export default ColorPopover;
