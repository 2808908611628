import { Text, Tag } from "@chakra-ui/react";
import { Column } from "components/layout/TableComponent";
import { NavLink } from "react-router-dom";
import { USER_TY } from "utils/DataMaps";
import { MdDisabledVisible } from "react-icons/md";

interface ColumnUserProps {
  t: (t: string) => string;
}
const ColumnsUser: (t: ColumnUserProps) => Column<User>[] = ({ t }) => [
  {
    title: t("general.tableColumn.title.name"),
    id: "name",
    width: "30%",

    render: (name, data) => (
      <NavLink to={data._id ?? ""}>
        <Text fontSize="14px" color="brand.200" fontWeight="500" textTransform="lowercase">
          <Text textTransform="capitalize">
            {data.name} {data.surname}
          </Text>
        </Text>
      </NavLink>
    ),
  },
  {
    title: t("general.tableColumn.title.email"),
    id: "email",
    width: "30%",
    minWidth: "200px",
    render: (value) => (
      <Text fontSize="14px" fontWeight="500">
        {value}
      </Text>
    ),
  },
  {
    title: t("general.tableColumn.title.id"),
    id: "_id",
    width: "30%",
    minWidth: "200px",
    render: (value) => (
      <Text fontSize="14px" fontWeight="500" color="grey.200">
        {value}
      </Text>
    ),
  },
  {
    title: t("general.tableColumn.title.type"),
    id: "type",
    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        textTransform="uppercase"
        variant="subtle"
        color={USER_TY[data.type].color}
        border="1px solid"
        width="max-content"
        borderColor={USER_TY[data.type].color}
      >
        {USER_TY[data.type].name}
      </Tag>
    ),
  },

  {
    title: t("general.tableColumn.title.license"),
    id: "subscriptions",
    render: (value) => (
      <Text fontSize="14px" fontWeight="500" color="grey.200">
        {value?.length ?? 0}
      </Text>
    ),
  },
  {
    id: "disabled",
    render: (value) =>
      value && (
        <Tag colorScheme="red" gap="8px">
          {t("user.status.disabled")}
          <MdDisabledVisible />
        </Tag>
      ),
  },
];

export default ColumnsUser;
