import React, { useState } from "react";
import FormCard from "components/layout/FormCard";
import FormBuilder from "components/layout/FormBuilder";
import { DURATION_LICENSE, TYPE_LICENSE, DISCOUNT_TYPE } from "utils/DataMaps";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import useToastNotification from "hooks/useToastNotification";
import { UserAction } from "store/modules/users";

interface CreateDiscoutProps {
  id: string;
  onFinish: () => void;
}
const CreateDiscount = ({ id, onFinish }: CreateDiscoutProps) => {
  const [isPercentage, setIsPercentage] = useState(true);

  const { dispatch } = useBehave();

  const loading = useTask("creating", `${id}discount`);

  const handleSubmit = (variables: CreatePartnerDiscount) => {
    variables.data.discount.type = isPercentage ? "PERCENTAGE" : "NET";
    if (variables.data.description === "") delete variables.data.description;
    dispatch(UserAction.createPartnerDiscount({ ...variables, partner: id }));
  };

  return (
    <div>
      <FormBuilder
        cardRender={FormCard}
        initialValues={{
          data: { discount: { type: "PERCENTAGE", amount: 0 } },
          partner: id,
        }}
        cards={[
          {
            submit: {
              onSubmit: handleSubmit,
              action: "Add",
              color: "green",
              loading: loading.isLoading,
            },
            intialTransforms: [
              [
                "data.discount.amount",
                (v) => v * (isPercentage ? 100 : 0.01),
                (v) => v * (isPercentage ? 0.01 : 100),
              ],
            ],
            title: "Create a discount for partner",
            subtitle: "Apply this discount to:",
            elements: [
              {
                id: "data.filter.to_plan",
                type: "select",
                label: "Plan",
                options: TYPE_LICENSE,
              },
              {
                id: "data.filter.to_type",
                type: "select",
                label: "Duration",
                options: DURATION_LICENSE,
              },
              {
                value: isPercentage ? "PERCENTAGE" : "NET",
                type: "select",
                label: "Discount type",
                options: DISCOUNT_TYPE,
                onChange: (e) => setIsPercentage(e.currentTarget.value === "PERCENTAGE"),
              },
              {
                id: "data.discount.amount",
                type: "number",
                label: "Amount",
                placeholder: "Amount",
                rightAddon: isPercentage ? "%" : "€",
              },
              {
                id: "data.description",
                type: "text",
                label: "Description",
                placeholder: "Description",
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default CreateDiscount;
