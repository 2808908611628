import { useBehave, useTask } from "behave-react";
import { AdminSelect } from "store/modules/admin";

const usePermissions = () => {
  const { select } = useBehave();
  const permissions = select(AdminSelect.permissions);
  const loading = useTask("fetching", "admin");
  const permit = (action: AdminActions) => permissions?.[action];

  return { permit, loading, permissions };
};

export default usePermissions;
