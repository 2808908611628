import { Flex, Image, Divider } from "@chakra-ui/react";

const SidebarBrand = () => {
  return (
    <Flex align="center" direction="column">
      <Image mb="16px" width="70%" mr="16px" alt="AccessiwayLogo" src="/icons/acsw-blu-logo.svg" />
      <Divider />
    </Flex>
  );
};

export default SidebarBrand;
