import { Text, Tag, Icon, Button } from "@chakra-ui/react";
import dateFormatter from "../../utils/DateFormatter";
import { BsPlus } from "react-icons/bs";
import { OrderStatusSchema } from "theme/ColorsSchema";
import { NavLink } from "react-router-dom";
import { Column } from "components/layout/TableComponent";

const ColumnsOrders: Column<Order>[] = [
  {
    title: "Description",
    id: "type",
    width: "60%",
    render: (value, data) => {
      console.log(data);
      const v =
        data.type === "credits"
          ? data.credits?.name_id
          : data.domains?.[0].domain +
            ((data.domains?.length ?? 0) > 1 ? " + altri " + (data.domains?.length ?? 0 - 1) : "");
      return (
        <NavLink to={data._id}>
          <Text whiteSpace="nowrap" color="brand.200" fontWeight="500">
            {v}
          </Text>
        </NavLink>
      );
    },
  },
  {
    title: "N. Order",
    id: "orderNumber",
    width: "30%",
    render: (value, data) => (
      <Text fontSize="14px" color="brand.200" fontWeight="500" textTransform="lowercase">
        <Text textTransform="capitalize" color="gray.600">
          {data.orderNumber}
        </Text>
      </Text>
    ),
  },
  {
    title: "Date",
    id: "date",

    render: (value, data) => (
      <Text
        whiteSpace="nowrap"
        fontSize="14px"
        color="brand.200"
        fontWeight="500"
        textTransform="lowercase"
      >
        <Text color="gray.600">{dateFormatter(data.date, "day_month_year")}</Text>
      </Text>
    ),
  },
  {
    title: "Amount",
    id: "domains",

    render: (value, data) => (
      <Text
        whiteSpace="nowrap"
        fontSize="14px"
        color="brand.200"
        fontWeight="500"
        textTransform="lowercase"
      >
        <Text color="gray.600" fontWeight="bold">
          {((data.domains?.[0].plan?.price ?? 0) / 100).toFixed(2)} €
        </Text>
      </Text>
    ),
  },
  {
    title: "Status",
    id: "payment_status",

    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        colorScheme={OrderStatusSchema[data.payment_status ?? 4]}
      >
        {data.payment_status}
      </Tag>
    ),
  },
  {
    id: "_id",

    render: (value) => (
      <NavLink to={value}>
        <Button variant="outline">
          <Icon as={BsPlus} w="5" h="5" />
        </Button>
      </NavLink>
    ),
  },
];

export default ColumnsOrders;
