import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import Card from "components/layout/Card";
import { useTranslation } from "react-i18next";

const LoadingPage = () => {
  const { t } = useTranslation();
  return (
    <Flex width="100%" height="100vh" flexDirection="column">
      <Box background="linear-gradient(135deg, #136EF8 0%, #004FCE 100%)" flex={1} />
      <Box flex={1} />
      <Flex w="100%" h="100%" alignItems="center" justifyContent="center" pos="absolute">
        <Card width="min-content" padding="20px 30px 20px 30px">
          <Flex alignItems="center">
            <Text fontWeight="bold">{t("loadingPage.title")}</Text>
            <Spinner marginLeft="24px" />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default LoadingPage;
