import { useEffect } from "react";
import { UserAction, UserSelect } from "store/modules/users";
import { Flex } from "@chakra-ui/react";
import { useBehave, useTask } from "behave-react";
import ButtonPermission from "components/layout/ButtonPermission";
import useToastNotification from "hooks/useToastNotification";

interface PartnerRequestActionsProps {
  id: string;
  t: (s: string) => string;
  handleRefresh: () => void;
}

const PartnerRequestActions = ({ id, t, handleRefresh }: PartnerRequestActionsProps) => {
  const { select, dispatch } = useBehave();
  const partners = select(UserSelect.partnerRequests);

  const loading = useTask("accepting", id);
  const loadingRefuse = useTask("refusing", id);
  useToastNotification(loading, { success: { description: "Request accepted succesfully!" } });
  useToastNotification(loadingRefuse, {
    success: { description: "Request rejected succesfully!" },
  });
  const handleAcceptRequest = () => {
    dispatch(UserAction.acceptPartnerRequest(id));
  };
  const handleRefuseRequest = () => {
    dispatch(UserAction.refusePartnerRequest(id));
  };

  useEffect(() => {
    if (!partners?.length) handleRefresh();
  }, []);

  return (
    <Flex gap="10px">
      <ButtonPermission
        variant="outline"
        size="sm"
        isLoading={loading.isLoading}
        colorScheme="green"
        action="acceptPartnerRequest"
        onClick={handleAcceptRequest}
      >
        {t("general.button.accept")}
      </ButtonPermission>
      <ButtonPermission
        colorScheme="red"
        size="sm"
        action="refusePartnerRequest"
        isLoading={loadingRefuse.isLoading}
        variant="outline"
        onClick={handleRefuseRequest}
      >
        {t("general.button.refuse")}
      </ButtonPermission>
    </Flex>
  );
};

export default PartnerRequestActions;
