import * as auth from "./auth";
import * as admin from "./admin";
import * as user from "./user";
import * as coupons from "./coupons";
import * as domains from "./domains";
import * as partner from "./partners";

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const init = () => {
  const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
    appId: process.env.REACT_APP_FIREBASE_APP,
  });

  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_APPCHECK_SITE_KEY ?? ""),
    isTokenAutoRefreshEnabled: true,
  });
};

const api = { auth, init, admin, user, coupons, partner, domains };

export default api;
