import { Text, Flex, IconButton, Badge, Button } from "@chakra-ui/react";
import moment from "moment";
import dateFormatter from "../../utils/DateFormatter";
import { useTask } from "behave-react";
import { Column } from "components/layout/TableComponent";
import ButtonPermission from "components/layout/ButtonPermission";
import { NavLink } from "react-router-dom";
import TransferRequestActions from "./TransferRequestActions";

interface ColumnsTransferProps {
  onAccept: (id: string) => void;
  onRefuse: (id: string) => void;
  t: (t: string) => string;
  locale: string;
}
const ColumnsTransfer: (p: ColumnsTransferProps) => Column<TransferRequest>[] = ({
  onAccept,
  onRefuse,
  t,
  locale,
}) => [
  {
    title: t("general.tableColumn.title.userSyntetic"),
    id: "user_literal",
    minWidth: "272px",
    width: "100%",
    render: (value, data) => (
      <NavLink to={`/${locale}/users/${data.user_id}`}>
        <Text fontSize="14px" color="brand.200" fontWeight="500" textTransform="lowercase">
          <Text textTransform="capitalize">{value}</Text>
        </Text>
      </NavLink>
    ),
  },
  {
    title: t("general.tableColumn.title.order"),
    id: "order",

    minWidth: "272px",
    render: (order: Order, data) => (
      <NavLink to={`/${locale}/users/${data.user_id}/orders/${data.order._id}`}>
        <Badge colorScheme="facebook">#{order.orderNumber}</Badge>
      </NavLink>
    ),
  },
  {
    title: t("general.tableColumn.title.date"),
    id: "date",
    minWidth: "272px",
    render: (value) => <Text>{dateFormatter(value, "month_name_day_year")}</Text>,
  },
  {
    title: t("general.tableColumn.title.action"),
    id: "user_id",
    minWidth: "272px",
    render: (value, data) => <TransferRequestActions id={data._id} t={t} />,
  },
];

export default ColumnsTransfer;
