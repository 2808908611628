import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import * as selectors from "./selectors";
import saga from "./saga";

const MODULE = "session";

const INITIAL_STATE: SessionModuleState = {};

const slice = createSlice({
  initialState: INITIAL_STATE,
  name: MODULE,
  reducers: { ...reducers.actions, ...reducers.saga },
});

type RS = keyof typeof reducers.saga;
export type SessionSagas = `${typeof MODULE}/${RS}`;
export const SessionAction = slice.actions;
export const SessionSelect = selectors;
export const SessionSaga = saga;
export const SessionInitialState = INITIAL_STATE;
export default slice.reducer;
