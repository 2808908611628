import React, { useState } from "react";
import {
  Text,
  Flex,
  Button,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Icon,
  useToast,
  Select,
  Link,
} from "@chakra-ui/react";
import { MdContentCopy } from "react-icons/md";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useTranslation } from "react-i18next";

// COMPONENTS
import Card from "../../components/layout/Card";
import ColorPopover from "./components/ColorPopover";
import SectionTitle from "./components/SectionTitle";
import WidgetPreview from "./components/WidgetPreview";

// UTILS
import InstallationScript, { ScriptConfig } from "./utils/InstallationScript";
import { LENGUAGES } from "utils/DataMaps";
import { baseConfig } from "./utils/BaseConfig";

const InstallGuide = () => {
  const { t } = useTranslation();
  const [scriptConfig, setScriptConfig] = useState<ScriptConfig>(baseConfig);
  const [color, setColor] = useState("#136ef8");
  const [colorWidget, setColorWidget] = useState("#136ef8");
  const toast = useToast();

  const BaseScript = InstallationScript(baseConfig);
  const CustomScript = InstallationScript(scriptConfig);

  const handleColor = (c: any) => {
    setColor(c.hex);
    setScriptConfig((s) => ({
      ...s,
      interface: { ...s.interface, color: c.hex },
    }));
  };
  
  const handleColorWidget = (c: any) => {
    setColorWidget(c.hex);
    setScriptConfig((s) => ({
      ...s,
      trigger: { ...s.trigger, color: c.hex },
    }));
  };

  return (
    <div>
      <Card direction="column" w="100%" p="30px">
        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="24px" fontWeight="600" lineHeight="100%">
              {t("installGuide.title")}
            </Text>
            <Link href="https://www.accessiway.com/faq-installation-guides" target="_blank">
              <Button variant="outline">{t("installGuide.action")}</Button>
            </Link>
          </Flex>
          <Text pt="30px" color="#4a5568">
            {t("installGuide.description")}
          </Text>
        </Box>
        <Box pt="30px">
          <Text fontWeight="600" mb="10px" fontSize="20px">
            {t("installGuide.subtitle")}
          </Text>
        </Box>

        <Tabs variant="unstyled" pt="20px">
          <TabList gap="24px">
            <Tab
              style={{ padding: "5px 0px" }}
              _focus={{ boxShadow: "none !important" }}
              _selected={{
                color: "brand.200",
                borderBottom: "2px solid",
                borderBottomColor: "brand.200",
                padding: "0px",
              }}
            >
              {t("installGuide.tabTitle.simple")}
            </Tab>
            <Tab
              style={{ padding: "5px 0px" }}
              _focus={{ boxShadow: "none !important" }}
              _selected={{
                color: "brand.200",
                borderBottom: "2px solid",
                borderBottomColor: "brand.200",
                padding: "0px",
              }}
            >
              {t("installGuide.tabTitle.advanced")}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0" pt="30px">
              <Text color="#4a5568">{t("installGuide.simple.instructions")}</Text>
              <Card mt="30px">
                <Box position="absolute" right="20px" top="20px">
                  <Icon
                    as={MdContentCopy}
                    w="5"
                    h="5"
                    _hover={{
                      color: "#136ef8",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigator.clipboard.writeText(BaseScript).then(() =>
                        toast({
                          title: t("general.notification.success.titleClipboard"),
                          status: "success",
                          position: "bottom-right",
                          duration: 9000,
                          isClosable: true,
                        })
                      )
                    }
                  />
                </Box>
                <SyntaxHighlighter
                  language="javascript"
                  style={a11yLight}
                  codeTagPros={{ style: { fontSize: "13px" } }}
                >
                  {BaseScript}
                </SyntaxHighlighter>
              </Card>
            </TabPanel>
            <TabPanel p="0" pt="20px">
              <Text color="#4a5568">{t("installGuide.advanced.instructions")}</Text>
              <SectionTitle text={t("installGuide.advanced.configurator.titleInterface")} />
              <Flex justifyContent="space-between" mt="30px" mb="30px" alignItems="center">
                <Flex direction="column">
                  <Box>
                    <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
                      {t("installGuide.configurator.sectionTitle.position")}
                    </Text>
                    <Flex gap="10px">
                      <Button
                        size="sm"
                        variant={scriptConfig.interface.positionH === "left" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            interface: { ...s.interface, positionH: "left" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.left")}
                      </Button>
                      <Button
                        size="sm"
                        variant={scriptConfig.interface.positionH === "right" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            interface: { ...s.interface, positionH: "right" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.right")}
                      </Button>
                    </Flex>
                  </Box>
                  <Box mt="10px">
                    <ColorPopover color={color} handleColor={handleColor} />
                  </Box>
                  <Box mt="10px">
                    <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
                      {t("installGuide.configurator.sectionTitle.lenguage")}
                    </Text>
                    <Select
                      onChange={(e) =>
                        setScriptConfig((s) => ({
                          ...s,
                          interface: { ...s.interface, language: e.target.value },
                        }))
                      }
                    >
                      {LENGUAGES.map((l) => (
                        <option value={l.value}>{l.name}</option>
                      ))}
                    </Select>
                  </Box>
                </Flex>
                <WidgetPreview
                  color={color}
                  pathH={scriptConfig.interface.positionH}
                  isInterface={true}
                />
              </Flex>
              <SectionTitle text={t("installGuide.advanced.configurator.titleButton")} />
              <Flex justifyContent="space-between" mt="30px" mb="30px">
                <Flex direction="column">
                  <Box>
                    <Flex alignItems="center">
                      <ColorPopover color={colorWidget} handleColor={handleColorWidget} />
                    </Flex>
                  </Box>

                  <Box mt="10px">
                    <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
                      {t("installGuide.configurator.sectionTitle.positionH")}
                    </Text>
                    <Flex gap="10px">
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.positionH === "left" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: { ...s.trigger, positionH: "left" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.left")}
                      </Button>
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.positionH === "right" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: { ...s.trigger, positionH: "right" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.right")}
                      </Button>
                    </Flex>
                  </Box>
                  <Box mt="10px">
                    <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
                      {t("installGuide.configurator.sectionTitle.positionV")}
                    </Text>
                    <Flex gap="10px">
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.positionV === "bottom" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: { ...s.trigger, positionV: "bottom" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.bottom")}
                      </Button>
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.positionV === "center" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: { ...s.trigger, positionV: "center" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.center")}
                      </Button>
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.positionV === "top" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: { ...s.trigger, positionV: "top" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.top")}
                      </Button>
                    </Flex>
                  </Box>
                  <Box mt="10px">
                    <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
                      {t("installGuide.configurator.sectionTitle.dimension")}
                    </Text>
                    <Flex gap="10px">
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.size === "small" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: { ...s.trigger, size: "small" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.small")}
                      </Button>
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.size === "medium" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: { ...s.trigger, size: "medium" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.medium")}
                      </Button>
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.size === "big" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: { ...s.trigger, size: "big" },
                          }))
                        }
                      >
                        {t("installGuide.configurator.large")}
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
                <WidgetPreview
                  color={colorWidget}
                  pathH={scriptConfig.trigger.positionH}
                  pathV={scriptConfig.trigger.positionV}
                  pathSize={scriptConfig.trigger.size}
                  isWidget={true}
                />
              </Flex>
              <SectionTitle text={t("installGuide.advanced.configurator.titleMobileButton")} />
              <Flex justifyContent="space-between" mt="30px" mb="30px">
                <Flex direction="column">
                  <Box>
                    <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
                      {t("installGuide.configurator.sectionTitle.positionH")}
                    </Text>
                    <Flex gap="10px">
                      <Button
                        size="sm"
                        variant={
                          scriptConfig.trigger.mobile.positionH === "left" ? "brand" : "outline"
                        }
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: {
                              ...s.trigger,
                              mobile: {
                                ...s.trigger.mobile,
                                positionH: "left",
                              },
                            },
                          }))
                        }
                      >
                        {t("installGuide.configurator.left")}
                      </Button>
                      <Button
                        size="sm"
                        variant={
                          scriptConfig.trigger.mobile.positionH === "right" ? "brand" : "outline"
                        }
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: {
                              ...s.trigger,
                              mobile: {
                                ...s.trigger.mobile,
                                positionH: "right",
                              },
                            },
                          }))
                        }
                      >
                        {t("installGuide.configurator.right")}
                      </Button>
                    </Flex>
                  </Box>
                  <Box mt="10px">
                    <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
                      {t("installGuide.configurator.sectionTitle.positionV")}
                    </Text>
                    <Flex gap="10px">
                      <Button
                        size="sm"
                        variant={
                          scriptConfig.trigger.mobile.positionV === "bottom" ? "brand" : "outline"
                        }
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: {
                              ...s.trigger,
                              mobile: {
                                ...s.trigger.mobile,
                                positionV: "bottom",
                              },
                            },
                          }))
                        }
                      >
                        {t("installGuide.configurator.bottom")}
                      </Button>
                      <Button
                        size="sm"
                        variant={
                          scriptConfig.trigger.mobile.positionV === "center" ? "brand" : "outline"
                        }
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: {
                              ...s.trigger,
                              mobile: {
                                ...s.trigger.mobile,
                                positionV: "center",
                              },
                            },
                          }))
                        }
                      >
                        {t("installGuide.configurator.center")}
                      </Button>
                      <Button
                        size="sm"
                        variant={
                          scriptConfig.trigger.mobile.positionV === "top" ? "brand" : "outline"
                        }
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: {
                              ...s.trigger,
                              mobile: {
                                ...s.trigger.mobile,
                                positionV: "top",
                              },
                            },
                          }))
                        }
                      >
                        {t("installGuide.configurator.top")}
                      </Button>
                    </Flex>
                  </Box>
                  <Box mt="10px">
                    <Text fontWeight="500" mb="10px" fontSize="16px" color="#000">
                      {t("installGuide.configurator.sectionTitle.dimension")}
                    </Text>
                    <Flex gap="10px">
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.mobile.size === "small" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: {
                              ...s.trigger,
                              mobile: {
                                ...s.trigger.mobile,
                                size: "small",
                              },
                            },
                          }))
                        }
                      >
                        {t("installGuide.configurator.small")}
                      </Button>
                      <Button
                        size="sm"
                        variant={
                          scriptConfig.trigger.mobile.size === "medium" ? "brand" : "outline"
                        }
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: {
                              ...s.trigger,
                              mobile: {
                                ...s.trigger.mobile,
                                size: "medium",
                              },
                            },
                          }))
                        }
                      >
                        {t("installGuide.configurator.medium")}
                      </Button>
                      <Button
                        size="sm"
                        variant={scriptConfig.trigger.mobile.size === "big" ? "brand" : "outline"}
                        onClick={() =>
                          setScriptConfig((s) => ({
                            ...s,
                            trigger: {
                              ...s.trigger,
                              mobile: {
                                ...s.trigger.mobile,
                                size: "big",
                              },
                            },
                          }))
                        }
                      >
                        {t("installGuide.configurator.large")}
                      </Button>
                    </Flex>
                  </Box>
                </Flex>
                <WidgetPreview
                  pathSize={scriptConfig.trigger.mobile.size}
                  pathH={scriptConfig.trigger.mobile.positionH}
                  pathV={scriptConfig.trigger.mobile.positionV}
                  color={colorWidget}
                  isMobile={true}
                />
              </Flex>

              <Card>
                <Box position="absolute" right="20px" top="20px">
                  <Icon
                    as={MdContentCopy}
                    w="5"
                    h="5"
                    _hover={{
                      color: "#136ef8",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigator.clipboard.writeText(CustomScript).then(() =>
                        toast({
                          title: "Succesfully copied to clipboard.",
                          //description: "We've created your account for you.",
                          status: "success",
                          position: "bottom-right",
                          duration: 9000,
                          isClosable: true,
                        })
                      )
                    }
                  />
                </Box>
                <SyntaxHighlighter language="javascript" style={a11yLight}>
                  {CustomScript}
                </SyntaxHighlighter>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </div>
  );
};

export default InstallGuide;
