import React, { ReactNode } from "react";
import {
  CgDatabase,
  CgUserList,
  CgMathPercent,
  CgProfile,
  CgEuro,
  CgLock,
  CgUndo,
  CgInfo,
} from "react-icons/cg";
import { Icon } from "@chakra-ui/react";
import RegionsManagment from "./pages/Managment";
import CreateUpdateAdmin from "pages/Admin/CreateUpdate";
import CreateUpdateRegion from "pages/Regions/CreateUpdate";
import PartnersRequestsPage from "pages/PartnerRequests/TablePage";
import InstallGuide from "pages/InstallGuide";
import InstallGuideV2 from "pages/InstallGuideV2";
import CreateLicense from "pages/Licenses/Create";
import CreateCoupon from "pages/Coupons/CreatePage";
import CouponsPage from "pages/Coupons/TablePage";
import CreateUser from "pages/Users/CreatePage";
import UsersPage from "pages/Users/TablePage";
import UpdateUser from "pages/Users/UpdatePage";
import TransfersRequestsPage from "pages/TransfersRequests/TablePage";
import Communications from "pages/Communications";
import OrdersList from "pages/Orders/TablePage";
import OrdersPage from "pages/Orders/Update";
import LicensesPage from "pages/Licenses/TablePage";
import PartnerPage from "pages/PartnerPage";
import UpdateLicensePage from "pages/Licenses/Update";
import RenewalsPage from "./pages/Renewals/TablePage";
import ListAllUsers from "pages/ListAllUsers/TablePage";

export interface NavRoute {
  name: string;
  path: string;
  icon?: ReactNode;
  component?: ReactNode;
  messageNavbar?: string;
  hideFromSidebar?: boolean;
  permission?: AdminActions;
}

const routes: NavRoute[] = [
  {
    name: "Users",
    path: "/users",
    icon: <Icon as={CgUserList} width="20px" height="20px" color="inherit" />,
    component: <UsersPage />,
    permission: "getAllUsers",
  },
  {
    name: "Regions and Admins",
    path: "/admin",
    icon: <Icon as={CgDatabase} width="20px" height="20px" color="inherit" />,
    component: <RegionsManagment />,
    permission: "getAdmins",
  },
  {
    name: "Add new Admin",
    path: "/admin/create",
    hideFromSidebar: true,
    component: <CreateUpdateAdmin />,
    permission: "createAdmin",
  },
  {
    name: "Name",
    path: "/admin/:id",
    hideFromSidebar: true,
    component: <CreateUpdateAdmin isEdit />,
  },
  {
    name: "Add new Region",
    path: "/admin/create-region",
    hideFromSidebar: true,
    component: <CreateUpdateRegion />,
  },
  {
    name: "[name]",
    path: "/admin/regions/:id",
    hideFromSidebar: true,
    component: <CreateUpdateRegion isEdit />,
  },
  {
    name: "Create new user",
    path: "/users/create",
    hideFromSidebar: true,
    component: <CreateUser />,
  },
  {
    name: "Manage user",
    path: "/users/:id",
    hideFromSidebar: true,
    component: <UpdateUser />,
  },
  {
    name: "Licenses",
    path: "/users/:id/licenses",
    hideFromSidebar: true,
    component: <LicensesPage />,
  },

  {
    name: "Create new License",
    path: "/users/:id/licenses/create",
    hideFromSidebar: true,
    component: <CreateLicense />,
  },
  {
    name: "Manage license",
    path: "/users/:id/licenses/:lid",
    hideFromSidebar: true,
    component: <UpdateLicensePage />,
  },

  {
    name: "Chat",
    path: "/users/:id/chat",
    hideFromSidebar: true,
    component: <Communications />,
  },
  {
    name: "Orders",
    path: "/users/:id/orders",
    hideFromSidebar: true,
    component: <OrdersList />,
  },
  {
    name: "Orders",
    path: "/users/:id/orders/:oid",
    hideFromSidebar: true,
    component: <OrdersPage />,
  },
  {
    name: "Partner Page",
    path: "/users/:id/partner",
    hideFromSidebar: true,
    component: <PartnerPage />,
  },
  {
    name: "Partner Requests",
    path: "/partner-requests",
    icon: <Icon as={CgProfile} width="20px" height="20px" color="inherit" />,
    component: <PartnersRequestsPage />,
    permission: "getPartnersRequests",
  },

  {
    name: "Coupons",
    path: "/coupons",
    icon: <Icon as={CgMathPercent} width="20px" height="20px" color="inherit" />,
    component: <CouponsPage />,
    permission: "getAllCoupons",
  },
  {
    name: "Coupons",
    path: "/coupons/create",
    hideFromSidebar: true,
    component: <CreateCoupon />,
  },

  {
    name: "Bank Transfers",
    path: "/transfers",
    icon: <Icon as={CgEuro} width="20px" height="20px" color="inherit" />,
    component: <TransfersRequestsPage />,
    permission: "getTransferRequests",
  },
  {
    name: "Install Guide",
    path: "/install-guide",
    icon: <Icon as={CgInfo} width="20px" height="20px" color="inherit" />,
    component: <InstallGuide />,
    permission: "loginAdmin",
  },
  // {
  //   name: "Install Guide V2",
  //   path: "/install-guide-v2",
  //   icon: <Icon as={CgInfo} width="20px" height="20px" color="inherit" />,
  //   component: <InstallGuideV2 />,
  //   permission: "loginAdmin",
  // },
  {
    name: "Renewals",
    path: "/renewals",
    icon: <Icon as={CgUndo} width="20px" height="20px" color="inherit" />,
    component: <RenewalsPage />,
    permission: "getPendingRenewal",
  },
  {
    name: "Authentications",
    path: "/all-users",
    icon: <Icon as={CgLock} width="20px" height="20px" color="inherit" />,
    component: <ListAllUsers />,
    permission: "getRegions",
  },
];

export default routes;
