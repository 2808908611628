import usePermissions from "hooks/usePermissions";
import { Flex, Spinner, Text, Box, Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import Card from "components/layout/Card";
import { useTranslation } from "react-i18next";

const withPermissions = (action: AdminActions, Component: any) => (props) => {
  const { permit, loading } = usePermissions();
  const { t } = useTranslation();
  if (loading.isLoading)
    return (
      <Flex gap="24px" paddingLeft="24px">
        <Spinner /> <Text>{t("general.text.checkPermission")}</Text>
      </Flex>
    );

  if (!permit(action))
    return (
      <Box width="max-content" alignItems="center" w="100%" pt="10%">
        <Flex
          flexDirection="column"
          textAlign="left"
          alignItems="center"
          justifyContent="center"
          w="100%"
          gap="12px"
        >
          <Text fontSize="30px" fontWeight="600">
            Access forbidden
          </Text>
          <Text fontSize="18px" textAlign="center">
            We are sorry, the page you're trying to access has restricted access.
            <br /> Please refer to your administrator.
          </Text>
          <Button variant="outline" mt="10px" width="max-content">
            <NavLink to="/">{t("general.text.takeMeBack")}</NavLink>
          </Button>
        </Flex>
      </Box>
    );
  else return <Component {...props} />;
};

export default withPermissions;
