import react, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TableComponent from "components/layout/TableComponent";
import RenewalsColumns from "./TableColumns";

import Card from "components/layout/Card";
import { Box } from "@chakra-ui/react";
import Steps from "./components/Steps";

import withPermissions from "components/hoc/withPermissions";

import { useBehave, useTask } from "behave-react";
import { ShopAction, ShopSelect } from "store/modules/shop";

const Renewals = () => {
  const { select, dispatch } = useBehave();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const data = select(ShopSelect.renewals);
  const loading = useTask("fetching", "renewals");

  const handleQuery = (i: string, data: DomainSubscription) =>
    data._id.includes(i) || data.domain.toLowerCase().includes(i);

  const handleRefresh = () => {
    dispatch(ShopAction.fetchRenewals());
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const filteredData = [
    data?.attempted_1?.list,
    data?.attempted_2?.list,
    data?.attempted_3?.list,
    data?.attempted_4?.list,
    data?.deactivated?.list,
  ];

  return (
    <Box>
      <Steps current={step} onChange={setStep} />
      <Card px="0px">
        <TableComponent
          pageSiblings={4}
          pageSize={6}
          data={filteredData[step]}
          columns={RenewalsColumns({ t })}
          query={handleQuery}
          isLoading={loading.isLoading}
          queryPlaceholder="Search expired domain..."
          onRefresh={handleRefresh}
        />
      </Card>
    </Box>
  );
};

export default withPermissions("getPendingRenewal", Renewals);
