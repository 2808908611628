import { PAction } from "behave-react";
import { SessionInitialState } from ".";

const AsyncReducers = {};

const Reducers = {
  authenticate: (state: SessionModuleState, action: PAction<AuthenticateUser>) => {
    state.authenticated = action.payload.authenticated;
    state.user = action.payload.user;
  },
  purge: (state: SessionModuleState) => ({ ...SessionInitialState }),
};

const SessionReducers = { actions: Reducers, saga: AsyncReducers };
export default SessionReducers;
