import { useState } from "react";
import { Divider, Switch } from "@chakra-ui/react";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import useToastNotification from "hooks/useToastNotification";
import { useTranslation } from "react-i18next";
import { CgStark } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import FormBuilder from "components/layout/FormBuilder";
import { UserAction } from "store/modules/users";
import { COUNTRIES, PHONE_COUNTRIES, USER_TYPE } from "utils/DataMaps";
import Regex from "../../utils/Regex";
import validateVAT from "utils/ValidateVAT";
import FormCard from "components/layout/FormCard";
import withPermissions from "components/hoc/withPermissions";

const CreateUser = () => {
  const { dispatch } = useBehave();
  const loading = useTask("creating", "user");
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  useToastNotification(loading, {
    success: { description: t("users.notification.success.description") },
  });

  useTaskCallback(loading, {
    success: (a) => navigate(`/${i18n.language}/users/${a?._id}`),
  });

  const [isPartner, setIsPartner] = useState(false);
  const [userType, setUserType] = useState<UserType>("PRIVATE");
  const [addBilling, setAddBilling] = useState(false);

  const handleSubmit = (variables: CreateUser) => {
    if (!isPartner) delete variables.partner;
    if (!addBilling) delete variables.billing;

    if (!variables.company?.role) delete variables.company.role;
    if (!variables.hs_id) delete variables.hs_id;
    dispatch(UserAction.createUser({ ...variables, type: userType }));
  };

  return (
    <FormBuilder
      initialValues={{ type: "PRIVATE" }}
      cardRender={FormCard}
      submit={{
        loading: loading.isLoading,
        onSubmit: handleSubmit,
        action: t("users.button.create"),
        color: "green",
        title: t("users.form.action.title"),
        subtitle: t("users.form.action.subtitle"),
        cardRender: ({ children }) => (
          <FormCard key="submit" submit>
            {children}
          </FormCard>
        ),
      }}
      cards={[
        {
          title: t("users.form.title1st"),
          subtitle: t("users.form.subtitle1st"),
          elements: [
            [
              {
                id: "name",
                type: "text",
                label: t("general.form.label.name"),
                placeholder: t("general.form.placeholder.name"),
                required: true,
              },
              {
                id: "surname",
                type: "text",
                label: t("general.form.label.surname"),
                placeholder: t("general.form.placeholder.surname"),
                required: true,
              },
            ],
            {
              id: "email",
              type: "email",
              label: t("general.form.label.email"),
              pattern: Regex.email,
              placeholder: t("general.form.placeholder.email"),
              required: true,
            },
            {
              type: "select",
              label: t("users.form.label.type"),
              options: USER_TYPE,
              value: userType,
              onChange: (e) => {
                setUserType(e.target.value);
                setIsPartner(e.target.value === "PARTNER");
              },
              placeholder: t("users.form.placeholder.type"),
            },
            [
              {
                id: "phonePrefix",
                type: "select",
                options: PHONE_COUNTRIES,
                required: true,
                flex: 0.5,
                label: t("general.form.label.country"),
              },
              {
                id: "phone",
                type: "number",
                flex: 2,
                pattern: Regex.mobile,
                label: t("general.form.label.phone"),
                placeholder: t("general.form.placeholder.phone"),
                required: true,
              },
            ],
            [
              {
                id: "company.name",
                type: "text",
                label: t("users.form.label.company"),
                placeholder: t("users.form.placeholder.company"),
                required: true,
              },
            ],
          ],
        },

        {
          title: t("users.form.title2nd"),
          elements: [
            {
              id: "hs_id",
              type: "text",
              label: t("users.form.label.hubspotId"),
              placeholder: t("users.form.placeholder.hubspotId"),
            },
          ],
        },

        {
          title: t("users.form.title3rd"),
          subtitle: t("users.form.subtitle3rd"),
          rightAction: <Switch isChecked={addBilling} onChange={() => setAddBilling((v) => !v)} />,
          collapsed: !addBilling,
          elements: [
            [
              {
                id: "billing.name",
                type: "text",
                label: t("general.form.label.name"),
                placeholder: t("general.form.placeholder.name"),
                required: true,
              },
              {
                id: "billing.surname",
                type: "text",
                label: t("general.form.label.surname"),
                placeholder: t("general.form.placeholder.surname"),
                required: true,
              },
            ],
            {
              id: "billing.emailPec",
              type: "email",
              label: t("general.form.label.emailPEC"),
              placeholder: t("general.form.placeholder.emailPEC"),
              required: true,
            },
            [
              {
                id: "billing.vatNumber",
                type: "text",
                label: t("general.form.label.piva"),
                placeholder: t("general.form.placeholder.piva"),
                validate: (v) => (validateVAT(v) ? undefined : "Invalid VAT number"),
                required: true,
              },
              {
                id: "billing.uniqueCode",
                type: "text",
                label: t("general.form.label.uniqueCode"),
                placeholder: t("general.form.placeholder.uniqueCode"),
                required: true,
              },
            ],
            <Divider mt="6px" mb="6px" />,
            [
              {
                id: "billing.address.country",
                type: "select",
                label: t("general.form.label.country"),
                placeholder: t("general.form.placeholder.country"),
                options: COUNTRIES,
                required: true,
              },
              {
                id: "billing.address.city",
                type: "text",
                label: t("general.form.label.city"),
                placeholder: t("general.form.placeholder.city"),
                required: true,
              },
            ],
            {
              id: "billing.address.address",
              type: "text",
              label: t("general.form.label.address"),
              placeholder: t("general.form.placeholder.address"),
              required: true,
            },
            [
              {
                id: "billing.address.state",
                type: "text",
                label: t("general.form.label.state"),
                placeholder: t("general.form.placeholder.state"),
                required: true,
              },
              {
                id: "billing.address.postalCode",
                type: "number",
                label: t("general.form.label.postalCode"),
                placeholder: t("general.form.placeholder.postalCode"),
                required: true,
              },
            ],
          ],
        },
        {
          title: t("users.form.title4th"),
          subtitle: t("users.form.subtitle4th"),
          hide: !isPartner,
          elements: [
            {
              id: "partner.code",
              type: "text",
              label: t("general.form.label.referralLink"),
              placeholder: t("general.form.placeholder.referralLink"),
              leftAddon: "https://app.accessiway.com?ref=",
              required: true,
            },
            {
              id: "partner.credits",
              type: "number",
              label: t("general.form.label.credits"),
              placeholder: t("general.form.placeholder.credits"),
              rightAddon: <CgStark />,
              required: true,
            },
          ],
        },
      ]}
    />
  );
};
export default withPermissions("createUser", CreateUser);
