import { Flex, Text } from "@chakra-ui/react";
import { CgTrashEmpty } from "react-icons/cg";
import { Column } from "components/layout/TableComponent";
import dateFormatter from "../../../utils/DateFormatter";
import ButtonPermission from "components/layout/ButtonPermission";

const OrderReferralColumns: (
  onDelete: (id: LinkUsage) => void,
  loading: boolean,
  partner: UserPartner
) => Column<LinkUsage>[] = (onDelete, loading, partner) => [
  {
    title: "Date",
    id: "date",
    width: "35%",
    render: (value, data) => <Text>{dateFormatter(data.date, "month_name_day_year")}</Text>,
  },
  {
    title: "Amount",
    id: "amount",
    width: "35%",
    render: (value, data) => <Text>€ {((data.amount ?? 0) / 100).toFixed(0)}</Text>,
  },
  {
    title: "Cart",
    id: "items",
    width: "35%",
    render: (value, data) => <Text>{data.items?.length ?? 0}</Text>,
  },
  {
    title: "Edit",
    id: "date",
    width: "35%",
    render: (value, data) => {
      return (
        <Flex>
          <ButtonPermission
            variant="link"
            size="sm"
            p="0px"
            action="deleteReferralHistory"
            onClick={() => onDelete(data)}
            whiteSpace="nowrap"
            colorScheme="red"
            isLoading={loading}
            mr="5px"
            leftIcon={<CgTrashEmpty />}
          />
        </Flex>
      );
    },
  },
];

export default OrderReferralColumns;
