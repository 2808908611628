import { useEffect, useState } from "react";
import Card from "../../components/layout/Card";
import ButtonPermission from "components/layout/ButtonPermission";
import AdminColumns from "./TableColumns";
import { IoAdd } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useBehave, useTask } from "behave-react";
import { AdminAction, AdminSelect } from "store/modules/admin";
import withPermissions from "components/hoc/withPermissions";
import TableComponent from "components/layout/TableComponent";

const AdminTable = () => {
  const { t } = useTranslation();
  const loadingUsers = useTask("fetching", "admins");
  const { select, dispatch } = useBehave();
  const admins = select(AdminSelect.admins);
  const handleRefresh = () => dispatch(AdminAction.getAdmins());

  return (
    <Card direction="column" w="100%" px="0px">
      <TableComponent
        title={t("admin.table.title")}
        columns={AdminColumns(t)}
        data={admins}
        onRefresh={handleRefresh}
        pageSize={6}
        action1={
          <ButtonPermission
            type="submit"
            pr="40px"
            to="create"
            pl="40px"
            size="md"
            action="createAdmin"
            leftIcon={<IoAdd />}
            variant="brand"
          >
            {t("admin.table.action.addUser")}
          </ButtonPermission>
        }
        isLoading={loadingUsers.isLoading}
      />
    </Card>
  );
};
export default withPermissions("getAdmins", AdminTable);
