import { Text, Tag, Flex, Box, Icon, Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { Column } from "components/layout/TableComponent";
import { typeColor, bgTypeColor } from "../../../utils/DataMaps";

const LicenseColumns: Column<DomainSubscription>[] = [
  {
    title: "Domain",
    id: "domain",
    width: "50%",

    render: (value, data) => (
      <NavLink to={"/users/" + data._id}>
        <Text fontSize="14px" color="brand.200" fontWeight="500" textTransform="lowercase">
          <Text>{data.domain}</Text>
        </Text>
      </NavLink>
    ),
  },

  {
    title: "Piano",
    id: "plan",
    width: "25%",
    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        variant="subtle"
        color={typeColor[data.plan.id]}
        bg={bgTypeColor[data.plan.id]}
      >
        {data.plan.id}
      </Tag>
    ),
  },
  {
    title: "Tipo",
    id: "plan",
    width: "25%",
    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        variant="subtle"
        border="1px solid"
        borderColor={typeColor[data.plan.type]}
        color={typeColor[data.plan.type]}
      >
        {data.plan.type}
      </Tag>
    ),
  },

  {
    title: "Price",
    id: "payment",
    width: "50%",
    render: (value, data) => (
      <Text fontSize="14px" color="gray.800" fontWeight="500" textTransform="lowercase">
        <Text textTransform="capitalize">
          {((data.plan.price_with_vat ?? data.plan.price) / 100).toFixed(2)}€
        </Text>
      </Text>
    ),
  },
];

export default LicenseColumns;
