import React, { useEffect, useState } from "react";
import { DURATION_LICENSE, PHONE_COUNTRIES, TYPE_LICENSE } from "utils/DataMaps";

import Regex from "../../utils/Regex";
import FormCard from "components/layout/FormCard";
import { useNavigate, useParams } from "react-router-dom";
import { UserSelect } from "store/modules/users";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import PLANS from "utils/PlansData";
import { useTranslation } from "react-i18next";
import useToastNotification from "hooks/useToastNotification";
import { ShopAction } from "store/modules/shop";
import moment from "moment";
import { Badge } from "@chakra-ui/react";
import FormBuilder from "components/layout/FormBuilder";
import withPermissions from "components/hoc/withPermissions";

const CreateLicense = () => {
  const { select, dispatch } = useBehave();
  const [isTrial, setIsTrial] = useState(false);
  const [planDuration, setPlanDuration] = useState<SubscriptionType>("MONTH");
  const [planType, setPlanType] = useState<SubscriptionPlan>("STANDARD");
  const [isNgo, setIsNgo] = useState(false);
  const { id } = useParams<{ id: string }>();
  const user = select(UserSelect.user(id ?? ""));
  const loading = useTask("creating", "domain");
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [renewDate, setRenewDate] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setRenewDate(expirationDate());
    setPrice(PLANS[planType].plans[planDuration].amount);
  }, [planDuration, planType]);

  useToastNotification(loading, { success: { description: "License created succesfully!" } });

  useTaskCallback(loading, {
    success: () => navigate(`/${i18n.language}/users/${id}/licenses`),
  });

  const handleSubmit = (variables: AdminCreatesLicense) => {
    if (isTrial) {
      delete variables.auto_renew;
      delete variables.omaggio;
      delete variables.user_pay;
      delete variables.generateInvoice;
    }
    if (isNgo) {
      delete variables.price;
    }

    const payload = { ...variables, renewDate, price };
    payload.item.plan.type = planDuration;
    payload.item.plan.id = planType;
    dispatch(ShopAction.createDomainSubscription(payload));
  };

  const expirationDate = () => {
    if (planDuration === "MONTH") return Date.now() + 1000 * 60 * 60 * 24 * 30;
    else if (planDuration === "YEAR") return Date.now() + 1000 * 60 * 60 * 24 * 365;
    else if (planDuration === "TRIAL") return Date.now() + 1000 * 60 * 60 * 24 * 7;
    else return Date.now() + 1000 * 60 * 60 * 24 * 30;
  };

  return (
    <FormBuilder
      initialValues={{
        user: id,
        price: PLANS.STANDARD.plans.MONTH.amount / 100,
        renewDate: expirationDate(),
        item: {
          domain: "",
          plan: { id: "STANDARD", type: "MONTH" },

          contact: {
            phone: user.phone,
            country: user.phonePrefix,
            email: user.email,
            name: `${user.name} ${user.surname}`,
          },
        },
      }}
      cardRender={FormCard}
      submit={{
        loading: loading.isLoading,
        onSubmit: handleSubmit,
        action: "Create License",
        color: "green",
        title: "Confirm License creation?",
        subtitle: "Do you want to confirm License creation",
      }}
      cards={[
        {
          title: "License info",
          subtitle: "Create a new License for all the users",
          elements: [
            {
              id: "item.domain",
              type: "text",
              label: "Domain",
              pattern: Regex.domain,
              patternError: "*Domain not vailid",
              placeholder: "Insert domain name",
              required: true,
            },
            [
              {
                value: planDuration,
                type: "select",
                label: "Duration",
                options: DURATION_LICENSE,
                onChange: (e) => {
                  setPlanDuration(e.target.value);
                  setIsTrial(e.target.value === "TRIAL" || e.target.value === "LONG_TRIAL");
                },
              },
              {
                onChange: (e) => {
                  setPlanType(e.target.value);
                },
                value: planType,
                type: "select",
                label: "Plan type",
                options: TYPE_LICENSE,
              },
            ],
          ],
        },
        {
          title: "User info",
          subtitle: "Set to which products this License applies to",
          elements: [
            [
              {
                id: "item.contact.name",
                type: "text",
                label: "Full name",
                placeholder: "FUll name",
                required: true,
                flex: 1,
              },
              {
                id: "item.contact.email",
                type: "text",
                label: "Email",
                placeholder: "Email",
                required: true,
                flex: 1,
              },
            ],
            [
              {
                id: "item.contact.country",
                type: "select",
                options: PHONE_COUNTRIES,
                placeholder: "User phone",
                required: true,
                flex: 0.2,
                label: "Country",
              },
              {
                label: "Phone",
                id: "item.contact.phone",
                type: "text",
                placeholder: "000 00 00 00",
                required: true,
              },
            ],
          ],
        },
        {
          title: "Price",

          elements: [
            {
              hidden: isTrial,
              id: "omaggio",
              type: "switch",
              label: "Free license?",
              description:
                "The license price is set to 0 € and will be activated on the accessibe NGO account",
              onChange: () => setIsNgo((s) => !s),
            },
            {
              type: "number",
              label: "Price",
              placeholder: "Prezzo",
              required: true,
              value: price / 100,
              onChange: (e) => setPrice(e.target.value * 100),
              rightAddon: "€",
              hidden: isTrial || isNgo,
            },
            {
              value: moment(renewDate).format("yyyy-MM-DD"),
              onChange: (e) =>
                setRenewDate(moment(e.target.value, "yyyy-MM-DD").toDate().getTime()),
              type: "date",
              label: "Renewal date",
              placeholder: "Renewal date",
              required: true,
            },
            {
              id: "auto_renew",
              type: "switch",
              label: "Automatic renew?",
              hidden: isTrial,
              description: "The license will automatically renew once the renewal date has passed.",
            },
          ],
        },

        {
          title: "Order",
          hide: isTrial,
          elements: [
            {
              id: "user_pay",
              type: "switch",
              label: "Do you charge the user?",
              description:
                "If selected, the user will be able to complete the payment of the specified amount from their dashboard. If not selected, the order is considered already paid or managed externally.",
            },
            {
              id: "generateInvoice",
              type: "switch",
              label: "Generate the invoice?",
              description:
                "Automatically generate the invoice for the order. (It will not be sent to the customer).",
            },
          ],
        },
      ]}
    />
  );
};

export default withPermissions("createSubscription", CreateLicense);
