import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  FormLabel,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorModeValue,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";

interface TagsFieldProps {
  errors?: any;
  label?: string;
  onChange?: (value: string[]) => void;
  value?: string[];
  id: string;
}

const TagsField = (props: TagsFieldProps) => {
  const { label, id, value, onChange, errors } = props;

  const [tags, setTags] = useState(value ?? []);

  const keyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setTags([...tags, e.currentTarget.value]);
      e.currentTarget.value = "";
    }
  };

  useEffect(() => {
    onChange?.(tags);
  }, [tags]);

  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const bg = useColorModeValue("brand.500", "brand.400");
  return (
    <Box mt="12px">
      <FormControl isInvalid={errors?.password}>
        <FormLabel ms="6px" htmlFor={id} fontWeight="bold" fontSize="sm" mb="8px">
          {label}
        </FormLabel>
        <Flex
          direction="row"
          p="12px"
          wrap="wrap"
          bg="transparent"
          border="1px solid"
          borderColor={borderColor}
          borderRadius="16px"
          _focus={{ borderColor: "teal.300" }}
          minH="40px"
          h="stretch"
          cursor="text"
        >
          {tags.map((tag, index) => {
            return (
              <Tag
                fontSize="xs"
                h="25px"
                mb="6px"
                me="6px"
                borderRadius="12px"
                variant="solid"
                bg={bg}
                key={index}
              >
                <TagLabel w="100%">{tag}</TagLabel>
                <TagCloseButton
                  justifySelf="flex-end"
                  color="white"
                  onClick={() => setTags([...tags.filter((element) => element !== tag)])}
                />
              </Tag>
            );
          })}
          <Input
            variant="main"
            bg="transparent"
            border="none"
            p="0px"
            onKeyDown={keyPress}
            fontSize="sm"
          />
        </Flex>
        <FormErrorMessage fontSize="smaller">
          {errors?.[id] && (errors[id].message?.lenght ? errors[id].message : "*  Required")}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
};

export default TagsField;
