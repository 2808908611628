
import select from "utils/ArrayUtils";
import { RootState } from "../..";

export const regions = (state: RootState) => state.admin.regions
export const region = (_id: string) => (state: RootState) =>
  select(state.admin.regions ?? [], "_id", _id).element as Region;
export const admins = (state: RootState) => state.admin.admins;
export const admin = (_id: string) => (state: RootState) =>
  select(state.admin.admins ?? [], "_id", _id).element as Admin;
export const permissions = (state: RootState) => state.admin.permissions;
