import { allCountries } from "country-telephone-data";

type DMap<T> = { name: string; value: T }[];

export const PERMISSIONS: DMap<PermissionLevel> = [
  { name: "Admin", value: "admin" },
  { name: "Advanced Sales", value: "advanced_sales" },
  { name: "Customer Service", value: "customer_service" },
  { name: "Partnership Manager", value: "executive" },
  { name: "Account Manager", value: "account_manager" },
  { name: "Account Executive", value: "account_executive" },
  
];

export const USER_TYPE: DMap<UserType> = [
  { name: "Private user", value: "PRIVATE" },
  { name: "Partner", value: "PARTNER" },
  { name: "Public administration", value: "PUBLIC_ADMINISTRATION" },
];

export const USER_TY = {
  PRIVATE: {
    name: "Private",
    color: "#00d756",
  },
  PUBLIC_ADMINISTRATION: {
    name: "Public administration",
    color: "#f56058",
  },
  PARTNER: {
    name: "Partner",
    color: "#136ef6",
  },
};

export const LENGUAGES = [
  { name: "English", value: "en" },
  { name: "Italian", value: "it" },
  { name: "Español", value: "es" },
  { name: "Français", value: "fr" },
  { name: "Português", value: "pt" },
  { name: "Deutsche", value: "de" },
  { name: "Nederlands", value: "nl" },
  { name: "日本語", value: "jp" },
  { name: "台灣", value: "tw" },
  { name: "中文", value: "ct" },
  { name: "עברית", value: "he" },
  { name: "русский", value: "ru" },
  { name: "الإمارات العربية المتحدة", value: "ar" },
  { name: "Polski", value: "pl" },
  { name: "Türk", value: "tr" },
];

export const TYPE_COUPON = [
  { name: "All", value: "all" },
  { name: "Jumbo", value: "JUMBO" },
  { name: "Huge", value: "HUGE" },
  { name: "Large", value: "LARGE" },
  { name: "Standard", value: "STANDARD" },
];

export const TYPE_LICENSE = [
  { name: "Jumbo", value: "JUMBO" },
  { name: "Huge", value: "HUGE" },
  { name: "Large", value: "LARGE" },
  { name: "Standard", value: "STANDARD" },
];

export const DURATION_COUPON = [
  { name: "All", value: "all" },
  { name: "Monthly", value: "MONTH" },
  { name: "Yearly", value: "YEAR" },
];

export const DURATION_LICENSE: DMap<SubscriptionType> = [
  { name: "Monthly", value: "MONTH" },
  { name: "Yearly", value: "YEAR" },
  { name: "Free trial", value: "TRIAL" },
  { name: "Long trial", value: "LONG_TRIAL" },
];

export const DISCOUNT_TYPE: DMap<Coupon["discount"]["type"]> = [
  { name: "Percentage", value: "PERCENTAGE" },
  { name: "Fixed amount", value: "NET" },
];

export const PHONE_COUNTRIES: DMap<string | undefined> = [
  { value: undefined, name: "None" },
  ...allCountries.map((c) => ({
    name: "+" + c.dialCode + " " + c.name,
    value: c.dialCode,
  })),
];

export const COUNTRIES: DMap<string | undefined> = [
  { value: undefined, name: "None" },
  ...allCountries.map((c) => ({
    name: c.name,
    value: c.name,
  })),
];

export const USER_TYPE_COLORS = {
  LARGE: "#00d756",
  HUGE: "#f56058",
  TRIAL: "#f56058",
  JUMBO: "#ed893e",
  MONTH: "#ed893e",
  STANDARD: "#136ef6",
  YEAR: "#136ef6",
  UNDEFINED: "#adadad",
};
export const DISCOUNT_TYPE_COLORS = {
  PERCENTAGE: "#00d756",
  NET: "#f56058",
};

export const REGIONS_DB = [
  { name: "Italia", value: "AW_IT" },
  { name: "Germany", value: "AW_DE" },
  { name: "France", value: "AW_FR" },
  { name: "Austria", value: "AW_AU" },
];

export const LICENSES_STATUS_COlORS = {
  PENDING: { color: "#ed893e", bg: "#ed893e30", value: "PENDING" },
  ACTIVE: { color: "#00d756", bg: "#00d75630", value: "ACTIVE" },
  ACTIVE_TRANSFER_PENDING: { color: "#ed893e", bg: "#ed893e30", value: "ACTIVE_TRANSFER_PENDING" },
  PENDING_TRANSFER: { color: "#ed893e", bg: "#ed893e30", value: "PENDING_TRANSFER" },
  PAYMENT_REFUSED: { color: "#f56058", bg: "#f5605830", value: "PAYMENT_REFUSED" },
  DISABLED: { color: "#f56058", bg: "#f5605830", value: "DISABLED" },
  EXPIRED_PAY_ERROR: { color: "#f56058", bg: "#f5605830", value: "EXPIRED_PAY_ERROR" },
  EXPIRED_DISABLED: { color: "#f56058", bg: "#f5605830", value: "EXPIRED_DISABLED" },
  WRONG_PLAN: { color: "#f56058", bg: "#f5605830", value: "WRONG_PLAN" },
  ACCESSIBE_ERROR: { color: "#f56058", bg: "#f5605830", value: "ACCESSIBE_ERROR" },
};
export const LICENSES_STATUS = [
  { name: "license.status.pending", value: "PENDING" },
  { name: "license.status.active", value: "ACTIVE" },
  { name: "license.status.activeTransfer", value: "ACTIVE_TRANSFER_PENDING" },
  { name: "license.status.paymentRefused", value: "PAYMENT_REFUSED" },
  { name: "license.status.disabled", value: "DISABLED" },
  { name: "license.status.expiredPayError", value: "EXPIRED_PAY_ERROR" },
  { name: "license.status.expiredDisabled", value: "EXPIRED_DISABLED" },
  { name: "license.status.wrongPlan", value: "WRONG_PLAN" },
  { name: "license.status.accessibeError", value: "ACCESSIBE_ERROR" },
];

export const typeColor = {
  LARGE: "#00d756",
  HUGE: "#f56058",
  TRIAL: "#f56058",
  JUMBO: "#ed893e",
  MONTH: "#ed893e",
  STANDARD: "#136ef6",
  YEAR: "#136ef6",
};
export const bgTypeColor = {
  LARGE: "#00d75630",
  HUGE: "#f5605830",
  TRIAL: "#f5605830",
  JUMBO: "#ed893e30",
  MONTH: "#ed893e30",
  STANDARD: "#136ef630",
  YEAR: "#136ef630",
};

export const statusTypeColor = {
  PENDING: { color: "#ed893e", bg: "#ed893e30", name: "PENDING" },
  ACTIVE: { color: "#00d756", bg: "#00d75630", name: "ACTIVE" },
  ACTIVE_TRANSFER_PENDING: { color: "#ed893e", bg: "#ed893e30", name: "ACTIVE TRANSFER PENDING" },
  PENDING_TRANSFER: { color: "#ed893e", bg: "#ed893e30", name: "PENDING TRANSFER" },
  PAYMENT_REFUSED: { color: "#f56058", bg: "#f5605830", name: "PAYMENT REFUSED" },
  DISABLED: { color: "#f56058", bg: "#f5605830", name: "DISABLED" },
  EXPIRED_PAY_ERROR: { color: "#f56058", bg: "#f5605830", name: "EXPIRED PAY ERROR" },
  EXPIRED_DISABLED: { color: "#f56058", bg: "#f5605830", name: "EXPIRED DISABLED" },
  WRONG_PLAN: { color: "#f56058", bg: "#f5605830", name: "WRONG PLAN" },
  ACCESSIBE_ERROR: { color: "#f56058", bg: "#f5605830", name: "ACCESSIBE ERROR" },
};
