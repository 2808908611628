import React, { useState } from "react";
// Chakra imports
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup,
  Button,
  Popover,
  PopoverTrigger as OPT,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  Icon,
  PopoverBody,
  Avatar,
  AvatarBadge,
  AvatarProps,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";

const PopoverTrigger: any = OPT;

interface AvatarEditProps extends AvatarProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onChangeUrl: (value: string) => void;
}

const AvatarEdit = ({ isOpen, onOpen, onClose, onChangeUrl, ...rest }: AvatarEditProps) => {
  const { t } = useTranslation();

  const handleSave = () => {
    const { value } = document.getElementById("avatar-edit-input") as HTMLInputElement;
    onChangeUrl(value);
    onClose();
  };

  return (
    <Avatar {...rest}>
      <AvatarBadge boxSize="1.25em" bg="#E8F1FF">
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom">
          <PopoverTrigger>
            <Button variant="rounded" padding="2px">
              <Icon as={MdEdit} color="brand.200" />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Stack>
                <Input
                  id="avatar-edit-input"
                  defaultValue={rest.src}
                  size="sm"
                  placeholder={t("avatarPopover.input.placeholder")}
                />
                <ButtonGroup display="flex" justifyContent="flex-end" size="sm">
                  <Button variant="outline" onClick={onClose}>
                    {t("general.button.cancel")}
                  </Button>
                  <Button colorScheme="teal" variant="brand" onClick={handleSave}>
                    {t("general.button.save")}
                  </Button>
                </ButtonGroup>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </AvatarBadge>
    </Avatar>
  );
};
export default AvatarEdit;
