import Card from "components/layout/Card";
import { Tag, Icon, Text, Flex, Box, Link } from "@chakra-ui/react";
import { FiExternalLink } from "react-icons/fi";
import { AiOutlineWarning } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const DomainHead = ({ domain, _id, orderNumber, user, ngo, refound }: DomainSubscription) => {
  const { t } = useTranslation();

  return (
    <Card height="fit-content" mb="20px">
      <Tag
        fontSize="25px"
        color="brand.500"
        fontWeight="medium"
        p="15px 20px"
        w="fit-content"
        background="#f6faff"
        mb="15px"
      >
        {domain}
        <Icon as={FiExternalLink} ml="7px" w="5" h="5" />
      </Tag>

      {ngo && (
        <Tag
          w="fit-content"
          color="brand.500"
          fontWeight="medium"
          p="7px 20px"
          background="#f6faff"
        >
          {t("license.domainHead.tag.ngo")}
        </Tag>
      )}
      <Box>
        <Box mt="15px" mb="15px" ml="5px">
          <Flex gap="10px">
            <Text color="brand.500" fontWeight="bold">
              {t("license.domainHead.tag.id")} :
            </Text>
            <Text>{_id}</Text>
          </Flex>

          <Flex gap="10px">
            <Text color="brand.500" fontWeight="bold">
              {t("license.domainHead.tag.orderNumber")} :
            </Text>
            <Text>{orderNumber}</Text>
          </Flex>

          <Flex gap="10px">
            <Text color="brand.500" fontWeight="bold">
              {t("license.domainHead.tag.userId")} :
            </Text>
            <Text>{user}</Text>
          </Flex>
        </Box>

        {refound && (
          <Tag p="15px 20px" w="fit-content" background="orange.50">
            <Box>
              <Icon as={AiOutlineWarning} w="5" h="5" mr="7px" color="orange.500" />
              <Text fontSize="18px" fontWeight="bold" pb="5px" pt="3px" color="orange.500">
                {t("license.domainHead.refound")}
              </Text>
              <Text>{t("license.domainHead.refoundDescription")}</Text>
              <Text>
                {t("license.domainHead.refoundOrderNumber")}: {refound}
              </Text>
            </Box>
          </Tag>
        )}
      </Box>
    </Card>
  );
};

export default DomainHead;
