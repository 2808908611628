import {
  Avatar,
  AvatarGroup,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useBehave } from "behave-react";
import { useTranslation } from "react-i18next";
import { AdminSelect } from "store/modules/admin";

interface SelectTeamModalProps {
  open: boolean;
  selected?: number;
  onSelect?: (s: string) => void;
  onClose: () => void;
}

const SelectTeamModal = ({ onSelect, open, onClose }: SelectTeamModalProps) => {
  const { select } = useBehave();
  const { t } = useTranslation();
  const teams = select(AdminSelect.regions);

  return (
    <Modal isOpen={open} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("selectTeamModal.title")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap="6px" mb="24px">
            {teams?.map((t) => (
              <InputGroup
                h="55px"
                cursor="pointer"
                onClick={() => {
                  onSelect?.(t._id);
                }}
              >
                <Input
                  pointerEvents="none"
                  value={t.name}
                  h="100%"
                  contentEditable={false}
                  fontWeight="800"
                  borderRadius="16px"
                />
                <InputRightElement
                  h="100%"
                  w="120px"
                  children={
                    <AvatarGroup size="sm" max={3}>
                      {t.admins?.map((e) => (
                        <Avatar name={e.email} src={e.profile_pic} />
                      ))}
                    </AvatarGroup>
                  }
                />
              </InputGroup>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectTeamModal;
