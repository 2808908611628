import select from "utils/ArrayUtils";
import { RootState } from "../..";

export const users = (state: RootState) => state.users.users ?? [];
export const authUsers = (state: RootState) => state.users.authUsers ?? [];
export const user = (_id: string) => (state: RootState) =>
  select(state.users.users ?? [], "_id", _id).element as User;
export const partnerRequests = (state: RootState) => state.users.partner_requests ?? [];
export const communicationsForUser = (_id?: string) => (state: RootState) =>
  state.users.communications?.filter((a) => a.user === _id) ?? [];
export const partner = (id?: string) => (state: RootState) => {
  const u = select(state.users.users ?? [], "_id", id).element as User;
  return select(state.users.partners ?? [], "_id", u.partner?._id).element as UserPartner;
};
