import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import * as selectors from "./selectors";
import saga from "./saga";

const MODULE = "user";

const INITIAL_STATE: UsersModuleState = {};

const slice = createSlice({
  initialState: INITIAL_STATE,
  name: MODULE,
  reducers: { ...reducers.actions, ...reducers.saga },
});

type RS = keyof typeof reducers.saga;
export type UserSagas = `${typeof MODULE}/${RS}`;
export const UserAction = slice.actions;
export const UserSelect = selectors;
export const UserSaga = saga;
export const UserInitialState = INITIAL_STATE;
export default slice.reducer;
