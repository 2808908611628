import { Avatar, Flex, Tag, Text } from "@chakra-ui/react";
import AvatarRegionComponent from "components/layout/AvatarRegionComponent";
import ButtonPermission from "components/layout/ButtonPermission";
import { Column } from "components/layout/TableComponent";
import { MdEdit } from "react-icons/md";
import { NavLink } from "react-router-dom";

import { AdminColorSchema } from "theme/ColorsSchema";

const AdminColumns: (t: (s: string) => string) => Column<Admin>[] = (t) => [
  {
    id: "first_name",
    title: t("general.tableColumn.title.name"),
    width: "20%",
    render: (value, data) => (
      <NavLink to={data._id}>
        <Flex alignItems="center" gap="12px">
          <Avatar src={data.profile_pic} name={data.email} size="sm" />
          <Text whiteSpace="nowrap" fontSize="sm" fontWeight="700">
            {value} {data.last_name}
          </Text>
        </Flex>
      </NavLink>
    ),
  },
  {
    id: "email",
    title: t("general.tableColumn.title.email"),
    width: "70%",
    render: (value) => (
      <Text whiteSpace="nowrap" fontSize="sm" fontWeight="500">
        {value}
      </Text>
    ),
  },
  {
    id: "region",
    title: t("general.tableColumn.title.region"),
    render: (value) => (value ? <AvatarRegionComponent size="sm" region={value} /> : null),
  },

  {
    id: "permission_level",
    title: t("general.tableColumn.title.permissionLevel"),
    minWidth: "200px",
    render: (value) => (
      <Tag variant="subtle" colorScheme={(AdminColorSchema as any)[value]} fontWeight="700">
        {value === "admin"
          ? t("general.permissionLevel.admin")
          : value === "standard_sales"
          ? t("general.permissionLevel.standardSales")
          : value === "advanced_sales"
          ? t("general.permissionLevel.advancedSales")
          : value === "executive"
          ? t("general.permissionLevel.executive")
          : value === "customer_service"
          ? t("general.permissionLevel.customerService")
          : value}
      </Tag>
    ),
  },
  {
    width: "60px",
    id: "_id",
    render: (value) => (
      <ButtonPermission variant="unstyled" leftIcon={<MdEdit />} action="updateAdmin" to={value} />
    ),
  },
];

export default AdminColumns;
