import { Button, Divider, HStack, Tag, Text } from "@chakra-ui/react";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import ButtonPermission from "components/layout/ButtonPermission";
import Card from "components/layout/Card";
import useToastNotification from "hooks/useToastNotification";
import { useTranslation } from "react-i18next";
import { IoChatbubble } from "react-icons/io5";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import FormBuilder from "components/layout/FormBuilder";
import { UserAction, UserSelect } from "store/modules/users";
import { COUNTRIES, PHONE_COUNTRIES, USER_TY } from "utils/DataMaps";
import FormCard from "components/layout/FormCard";
import Regex from "../../utils/Regex";
import validateVAT from "utils/ValidateVAT";
import { useEffect } from "react";
import withPermissions from "components/hoc/withPermissions";

type EditUserTypes = EditUserPaylad["data"] | EditBillingPayload["data"] | EditUserHubspotId;

const UpdateUser = () => {
  const { id } = useParams<{ id: string }>();
  const { dispatch, select } = useBehave();

  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const user = select(UserSelect.user(id ?? ""));
  const loadingUser = useTask("fetching", id);
  const loadingInfos = useTask("updating", id);
  const loadingBilling = useTask("updating", `billing${id}`);
  const loadingHubspot = useTask("updating", `hs${id}`);
  const loadingDisable = useTask("disabling", id);
  const loadingActivate = useTask("enabling", id);
  const loadingDelete = useTask("deleting", id);
  const loadingPartner = useTask("creating", `partner${id}`);

  useToastNotification(loadingInfos, {
    success: { description: t("users.notification.success.descriptionInfo") },
  });
  useToastNotification(loadingBilling, {
    success: { description: t("users.notification.success.descriptionBilling") },
  });
  useToastNotification(loadingHubspot, {
    success: { description: t("users.notification.success.descriptionHubspot") },
  });
  useToastNotification(loadingDisable, {
    success: { description: t("users.notification.success.descriptioUserDisable") },
  });
  useToastNotification(loadingActivate, {
    success: { description: t("users.notification.success.descriptionUserEnable") },
  });
  useToastNotification(loadingDelete, {
    success: { description: t("users.notification.success.descriptionUserDelete") },
  });
  useToastNotification(loadingPartner, {
    success: { description: t("users.notification.success.descriptionPartnerCreate") },
  });
  useTaskCallback(loadingDelete, {
    success: (a) => navigate(`/${i18n.language}/users`),
  });
  const handleUpdateInfos = (data: EditUserTypes) => {
    const variables = data as EditUserPaylad["data"];
    if (!variables.company?.role) delete variables.company.role;
    if (!id) return;
    dispatch(UserAction.editUser({ user: id, data: variables }));
  };
  const handleUpdateBilling = (data: EditUserTypes) => {
    const variables = data as EditBillingPayload["data"];
    if (!id) return;
    dispatch(UserAction.editBillingInfo({ user: id, data: variables }));
  };
  const handleUpdateHubspotID = (data: EditUserTypes) => {
    const variables = data as EditUserHubspotId;
    if (!id) return;
    dispatch(UserAction.editHusbpotId(variables));
  };
  useEffect(() => {
    if (!id) return;
    dispatch(UserAction.fetchUser(id));
  }, [id]);

  return (
    <FormBuilder
      cardRender={FormCard}
      loading={loadingUser.isLoading ? "Loading user.." : undefined}
      floating={
        <Card padding="0px">
          <NavLink to="chat">
            <Button colorScheme="messenger" leftIcon={<IoChatbubble />}>
              Open chat
            </Button>
          </NavLink>
        </Card>
      }
      editMode
      empty={!user}
      fallback={{
        message: (
          <>
            {t("adminCreate.formFallback.message_1")}
            <b>{id}</b> {t("adminCreate.formFallback.message_2")}
          </>
        ),
        action: (
          <NavLink to={`/${i18n.language}/users`}>
            <Button>{t("general.button.goBack")}</Button>
          </NavLink>
        ),
      }}
      cards={[
        {
          title: `${user?.name} ${user?.surname}`,
          subtitle: user?.company.name,
          rightAction: (
            <Tag
              textTransform="uppercase"
              color={USER_TY[user?.type]?.color}
              border="1px solid"
              borderColor={USER_TY[user?.type]?.color}
            >
              {USER_TY[user?.type]?.name}
            </Tag>
          ),
          submit: {
            action: t("general.button.update"),
            onSubmit: handleUpdateInfos,
            loading: loadingInfos.isLoading,
          },
          initialValues: user
            ? {
                company: user?.company,
                email: user?.email,
                name: user?.name,
                surname: user?.surname,
                phone: user?.phone,
                phonePrefix: user?.phonePrefix,
              }
            : undefined,
          elements: [
            [
              {
                id: "name",
                type: "text",
                label: t("general.form.label.name"),
                placeholder: t("general.form.placeholder.name"),
                required: true,
              },
              {
                id: "surname",
                type: "text",
                label: t("general.form.label.surname"),
                placeholder: t("general.form.placeholder.surname"),
                required: true,
              },
            ],
            {
              id: "email",
              type: "email",
              label: t("general.form.label.email"),
              pattern: Regex.email,
              placeholder: t("general.form.placeholder.email"),
              required: true,
            },

            [
              {
                id: "phonePrefix",
                type: "select",
                options: PHONE_COUNTRIES,
                required: true,
                flex: 0.5,
                label: t("general.form.label.country"),
              },
              {
                id: "phone",
                type: "number",
                flex: 2,
                pattern: Regex.mobile,
                label: t("general.form.label.phone"),
                placeholder: t("general.form.placeholder.phone"),
                required: true,
              },
            ],
            [
              {
                id: "company.name",
                type: "text",
                label: t("users.form.label.company"),
                placeholder: t("users.form.placeholder.company"),
              },
            ],
          ],
        },
        {
          title: t("users.form.title3rd"),
          subtitle: t("users.form.subtitle3rd"),
          submit: {
            action: t("general.button.update"),
            onSubmit: handleUpdateBilling,
            loading: loadingBilling.isLoading,
          },
          initialValues: user?.payment.billingInformations as EditUserTypes,
          elements: [
            [
              {
                id: "name",
                type: "text",
                label: t("general.form.label.name"),
                placeholder: t("general.form.placeholder.name"),
                required: true,
              },
              {
                id: "surname",
                type: "text",
                label: t("general.form.label.surname"),
                placeholder: t("general.form.placeholder.surname"),
                required: true,
              },
            ],
            {
              id: "emailPec",
              type: "email",
              label: t("general.form.label.emailPEC"),
              placeholder: t("general.form.placeholder.emailPEC"),
              required: true,
            },
            [
              {
                id: "vatNumber",
                type: "text",
                label: t("general.form.label.piva"),
                placeholder: t("general.form.placeholder.piva"),
                validate: (v) => (validateVAT(v) ? undefined : "Invalid VAT number"),
                required: true,
              },
              {
                id: "uniqueCode",
                type: "text",
                label: t("general.form.label.uniqueCode"),
                placeholder: t("general.form.placeholder.uniqueCode"),
                required: true,
              },
            ],
            <Divider mt="6px" mb="6px" />,
            [
              {
                id: "address.country",
                type: "select",
                label: t("general.form.label.country"),
                placeholder: t("general.form.placeholder.country"),
                options: COUNTRIES,
                required: true,
              },
              {
                id: "address.city",
                type: "text",
                label: t("general.form.label.city"),
                placeholder: t("general.form.placeholder.city"),
                required: true,
              },
            ],
            {
              id: "address.address",
              type: "text",
              label: t("general.form.label.address"),
              placeholder: t("general.form.placeholder.address"),
              required: true,
            },
            [
              {
                id: "address.state",
                type: "text",
                label: t("general.form.label.state"),
                placeholder: t("general.form.placeholder.state"),
                required: true,
              },
              {
                id: "address.postalCode",
                type: "number",
                label: t("general.form.label.postalCode"),
                placeholder: t("general.form.placeholder.postalCode"),
                required: true,
              },
            ],
          ],
        },
        {
          title: t("users.form.title2nd"),
          submit: {
            action: "Update",
            onSubmit: handleUpdateHubspotID,
            loading: loadingHubspot.isLoading,
          },
          initialValues: {
            hs_id: user?.hs_id ?? "",
            user: id ?? "",
          },
          elements: [
            {
              id: "hs_id",
              type: "text",
              placeholder: t("users.form.placeholder.hubspotId"),
              required: true,
            },
          ],
        },
        {
          hide: !!user?.partner?._id,
          title: t("users.form.title5th"),
          subtitle: t("users.form.subtitle5th"),
          rightAction: (
            <ButtonPermission
              children="Upgrade"
              action="upgradeToPartner"
              variant="brand"
              onClick={() => dispatch(UserAction.upgradeToPartner(id ?? ""))}
              isLoading={loadingPartner.isLoading}
            />
          ),
          elements: [],
        },
        {
          hide: !user?.partner?._id,
          title: t("users.form.title6th"),
          rightAction: (
            <NavLink to="partner">
              <Button children="Manage" variant="brand" />
            </NavLink>
          ),
          elements: [],
        },
        {
          subtitle: <Text fontWeight="bold">{t("users.form.subtitle7th")}</Text>,
          rightAction: (
            <HStack>
              <NavLink to="licenses">
                <Button
                  children={`${user?.subscriptions?.length ?? ""} Licenses`}
                  variant="outline"
                />
              </NavLink>
              <NavLink to="orders">
                <Button children={`${user?.orders?.length ?? ""} Orders`} variant="outline" />
              </NavLink>
            </HStack>
          ),
          elements: [],
        },

        {
          title: t("users.form.title8th"),
          subtitle: "Handle this actions with care.",
          elements: [
            <HStack w="100%">
              <ButtonPermission
                flex={1}
                children={user?.disabled ? t("users.button.activate") : t("users.button.disable")}
                action={user?.disabled ? "activateUser" : "disableUser"}
                colorScheme={user?.disabled ? "green" : "red"}
                variant="outline"
                needConfirmation
                onClick={() =>
                  dispatch(
                    user?.disabled
                      ? UserAction.activateUser(id ?? "")
                      : UserAction.disableUser(id ?? "")
                  )
                }
                isLoading={loadingActivate.isLoading || loadingDisable.isLoading}
              />
              ,
              <ButtonPermission
                flex={1}
                children="Delete user"
                action="forceDeleteUser"
                colorScheme="red"
                variant="solid"
                needConfirmation
                onClick={() => dispatch(UserAction.deleteUser({ _id: id ?? "" }))}
                isLoading={loadingDelete.isLoading}
              />
            </HStack>,
          ],
        },
      ]}
    />
  );
};

export default withPermissions("editUser", UpdateUser);
