import React, { useEffect } from "react";
import { useBehave, useTask } from "behave-react";
import { NavLink, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import ButtonPermission from "components/layout/ButtonPermission";
import Card from "../../components/layout/Card";
import LicensesColumn from "./TableColumns";
import TableComponent from "components/layout/TableComponent";
import { ShopAction, ShopSelect } from "store/modules/shop";
import { UserSelect } from "store/modules/users";
import { useTranslation } from "react-i18next";
import withPermissions from "components/hoc/withPermissions";

const LicensesPage = () => {
  const { t } = useTranslation();
  const { select, dispatch } = useBehave();
  const { id } = useParams<{ id: string }>();

  const loadingLicense = useTask("fetching", "domain");
  const user = select(UserSelect.user(id ?? ""));
  const licenses = select(ShopSelect.domains(id ?? ""));
  console.log("le licenze sono" + licenses);

  const handleRefresh = () => {
    if (user && user.subscriptions?.length) dispatch(ShopAction.fetchUserDomains(id ?? ""));
  };

  useEffect(() => {
    if (user && user.subscriptions?.length) dispatch(ShopAction.fetchUserDomains(id ?? ""));
  }, [id]);

  const handleQuery = (i: string, data: DomainSubscription) =>
    data._id.includes(i) || data.domain.includes(i) || data.plan.id.toLowerCase().includes(i);

  return (
    <Card direction="column" w="100%" px="0px">
      <TableComponent
        columns={LicensesColumn({ t })}
        data={licenses}
        queryPlaceholder={t("licenses.tablePage.queryPlaceholder")}
        pageSiblings={4}
        pageSize={6}
        query={handleQuery}
        onRefresh={handleRefresh}
        isLoading={loadingLicense.isLoading}
        action1={
          <NavLink to="create">
            <ButtonPermission action="createSubscription" variant="brand">
              {t("licenses.tablePage.button.newLicense")}
            </ButtonPermission>
          </NavLink>
        }
      />
    </Card>
  );
};

export default withPermissions("getDomainsForUser", LicensesPage);
