import { Box, useDisclosure } from "@chakra-ui/react";
import AdminNavbar from "components/layout/Navbar/NavbarAdmin";
import Sidebar from "../layout/Sidebar/Sidebar";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import routes, { NavRoute } from "routes";
import usePermissions from "hooks/usePermissions";

const Authorized = () => {
  const { permit, permissions } = usePermissions();
  const { onOpen } = useDisclosure();
  const { i18n } = useTranslation();
  const location = useLocation();
  const [brand, setBrand] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setBrand(getActiveRoute(routes));
    setMessage(getActiveNavbarText(routes));
  }, [location]);

  const getActiveRoute = (routes: NavRoute[]) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location?.pathname === "/" + i18n.language + routes[i].path) {
        return routes[i].name;
      }
    }
    return "Page";
  };

  const getActiveNavbarText = (routes: NavRoute[]) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location?.pathname === "/" + i18n.language + routes[i].path) {
        return routes[i].messageNavbar ?? "";
      }
    }
    return "";
  };

  const _Routes: (_routes: NavRoute[]) => ReactNode[] = (_routes: NavRoute[]) =>
    _routes.reduce((list: ReactNode[], route: NavRoute) => {
      if (route.component) {
        return [...list, <Route key={route.path} path={route.path} element={route.component} />];
      } else return list;
    }, []);

  return (
    <Box>
      <Sidebar routes={routes} />
      <AdminNavbar onOpen={onOpen} brandText={brand} message={message} />
      <Box
        mx="auto"
        p={{ base: "20px", md: "30px" }}
        pt={{ base: "260px", md: "160px", xl: "160px" }}
        pe="20px"
        minH="100vh"
        w={{ base: "100%", xl: "calc( 100% - 290px )" }}
        maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
        float="right"
      >
        <Routes>
          {_Routes(routes)}
          {<Route path="/*" element={<Navigate to="users" />} />}
        </Routes>
      </Box>
    </Box>
  );
};

export default Authorized;
