import {
  Avatar,
  AvatarGroup,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";
import SelectTeamModal from "../SelectTeamModal";
import { useTranslation } from "react-i18next";
import { useBehave } from "behave-react";
import { AdminSelect } from "store/modules/admin";

interface SelectTeamProps {
  selected: string;
  disabled?: boolean;
  onSelect: any;
}

const SelectTeam = ({ selected, onSelect, disabled }: SelectTeamProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { select } = useBehave();
  const regions = select(AdminSelect.regions);
  const region = regions?.find((e) => e._id === selected);

  return (
    <>
      <FormControl isRequired={true} opacity={disabled ? 0.5 : 1}>
        <FormLabel
          display="flex"
          ms="10px"
          htmlFor="password"
          fontSize="sm"
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          {t("selectTeam.label")}
        </FormLabel>
        <InputGroup
          h="42px"
          cursor="pointer"
          mb="16px"
          onClick={() => setOpen(true)}
          variant="main"
        >
          <Input
            pointerEvents="none"
            value={region?.name ?? selected}
            h="100%"
            fontWeight="800"
            borderRadius="16px"
            variant="main"
          />
          <InputRightElement
            h="100%"
            w="120px"
            children={
              <AvatarGroup size="sm" max={3}>
                {region?.admins?.map((u) => (
                  <Avatar name={u.email} src={u.profile_pic} />
                ))}
              </AvatarGroup>
            }
          />
        </InputGroup>
      </FormControl>
      <SelectTeamModal
        open={!disabled && open}
        onClose={() => setOpen(false)}
        onSelect={(a) => {
          onSelect(a);
          setOpen(false);
        }}
      />
    </>
  );
};

export default SelectTeam;
