import { Box, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { FieldValues } from "react-hook-form";
import { BuilderElement, ExtendedBuilderElement } from "../Types/type";
import FormElement from "./FormElement";

interface RenderElementProps<T extends FieldValues> {
  data: ExtendedBuilderElement<T> | BuilderElement<T>[];
  errors: any;
  register?: any;
  setValue: any;
  getValues: any;
  control?: any;
}

const RenderElement = <T extends FieldValues>({
  data,
  errors,
  register,
  setValue,
  control,
  getValues,
}: RenderElementProps<T>) => {
  const element = data as BuilderElement<T>;
  const elements = data as BuilderElement<T>[];

  if (React.isValidElement(element)) return data as any;

  if (elements?.[0]?.type) {
    return (
      <Flex gap="12px" width="100%" key={elements[0].label ?? elements[0].id}>
        {elements.map((el, i) =>
          el.hidden ? null : (
            <Box key={el.placeholder ?? el.label ?? i} flex={el.flex ?? 1}>
              <FormElement
                errors={errors}
                data={el}
                getValues={getValues}
                setValue={setValue}
                register={register}
              />
            </Box>
          )
        )}
      </Flex>
    );
  }
  if (element.hidden) return null;

  return (
    <FormElement
      errors={errors}
      data={element}
      setValue={setValue}
      getValues={getValues}
      register={register}
      control={control}
    />
  );
};
export default RenderElement;
