import api from "api";
import { createTask, PAction, putAction, takeLatest } from "behave-react";
import { call } from "typed-redux-saga";

import { ShopAction, ShopSaga, ShopSagas } from ".";

function* fetchCoupons() {
  const task = createTask("fetching", "coupons");
  yield task.start();
  try {
    const { data } = yield* call(api.coupons.getAllCoupons);
    yield putAction(ShopAction._setCoupons(data?.result ?? []));
    yield task.success(data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* createCoupon(action: PAction<CreateCoupon>) {
  const task = createTask("creating", "coupon");
  yield task.start();

  try {
    const { data } = yield* call(api.coupons.createCoupon, action.payload);
    if (!data?.result) throw Error("false");
    yield putAction(ShopAction._setCoupon(data.result));
    yield task.success(data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* deleteCoupon(action: PAction<string>) {
  const task = createTask("deleting", action.payload);
  yield task.start();

  try {
    yield* call(api.coupons.deleteCoupon, { _id: action.payload });
    yield task.success(true);
    yield putAction(ShopAction._deleteCoupon(action.payload));
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* deleteDomain(action: PAction<SimpleIdWithUser>) {
  const task = createTask("deleting", action.payload._id);
  yield task.start();

  try {
    yield* call(api.domains.deleteSubscription, action.payload);
    yield task.success(true);
    yield putAction(ShopAction._deleteDomain(action.payload));
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchUserDomains(action: PAction<string>) {
  const task = createTask("fetching", "domain");
  yield task.start();

  try {
    const request = yield* call(api.domains.getUserDomains, { _id: action.payload });
    yield putAction(ShopAction._setDomains(request?.data?.result ?? []));
    yield task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* createDomain(action: PAction<AdminCreatesLicense>) {
  const task = createTask("creating", "domain");
  yield task.start();

  try {
    const request = yield* call(api.domains.createSubscriptions, action.payload);

    if (request?.data?.result?.domain)
      yield putAction(ShopAction._setDomains([request.data.result.domain]));
    yield task.success(true);
  } catch (e: any) {
    yield task.fail(e.data?.message);
  }
}

function* changeDomainPlanStatus(action: PAction<ChangePlanStatus>) {
  const task = createTask("updating", `${action.payload._id}Status`);
  yield task.start();
  try {
    yield call(api.domains.changePlanStatus, action.payload);
    yield putAction(ShopAction._setDomainStatus(action.payload));
    yield task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

// function* changeDomainPlan(action: PAction<DomainSubscription>) {
//   const task = createTask("updating", `${action.payload._id}Plan`);
//   yield task.start();
//   try {
//     yield call(api.domains.changePlan, {_id : action.payload._id, plan: action.payload.plan});
//     yield putAction(ShopAction._setDomainPlan(action.payload));
//     yield task.success(true);
//   } catch (e: any) {
//     yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
//   }
// }

function* transferDomain(action: PAction<TransferDomain>) {
  const task = createTask("updating", `${action.payload._id}Domain`);
  yield task.start();
  try {
    yield call(api.domains.transferDomain, action.payload);
    yield putAction(ShopAction._transferDomain(action.payload));
    yield task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchUserOrders(action: PAction<string>) {
  const task = createTask("fetching", "orders");
  yield task.start();

  try {
    const request = yield* call(api.user.getUserOrders, { _id: action.payload });
    yield putAction(ShopAction._setOrders(request?.data?.result ?? []));
    yield task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* updateUserOrder(action: PAction<UpdateOrder>) {
  const task = createTask("updating", "orders");
  yield task.start();

  try {
    yield* call(api.user.updateOrderForUser, action.payload);
    yield putAction(ShopAction._updateOrder(action.payload));
    yield task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchTransferRequests() {
  const task = createTask("fetching", "transfers");
  yield task.start();

  try {
    const request = yield* call(api.user.getTransferRequests);
    yield putAction(ShopAction._setTransfers(request.data?.result ?? []));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* acceptTransferRequest(action: PAction<string>) {
  const task = createTask("accepting", action.payload);
  yield task.start();

  try {
    const request = yield* call(api.user.acceptTransferRequest, { _id: action.payload });
    yield putAction(ShopAction._deleteTransferRequest(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* refuseTransferRequest(action: PAction<string>) {
  const task = createTask("refusing", action.payload);
  yield task.start();

  try {
    const request = yield* call(api.user.deleteTransferRequest, { _id: action.payload });
    yield putAction(ShopAction._deleteTransferRequest(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* editPriceAndRenewDate(action: PAction<ChangePricePayload>) {
  const task = createTask("updating", `${action.payload._id}Price`);
  yield task.start();

  try {
    const request = yield* call(api.domains.changePriceDate, action.payload);
    yield* putAction(ShopAction._updatePrice(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* updateContacts(action: PAction<UpdatePlanContact>) {
  const task = createTask("updating", `${action.payload._id}Contact`);
  yield task.start();

  try {
    const request = yield* call(api.domains.changeContact, action.payload);
    yield* putAction(ShopAction._updateContact(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* updateBillingInformation(action: PAction<UpdatePlanBilling>) {
  const task = createTask("updating", `${action.payload._id}Billing`);
  yield task.start();

  try {
    const request = yield* call(api.domains.changeBilling, action.payload);
    yield* putAction(ShopAction._updateBilling(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchDomain(action: PAction<string>) {
  const task = createTask("fetching", action.payload);
  yield task.start();

  try {
    const result = yield* call(api.domains.getDomain, { _id: action.payload });
    if (result.data?.result) yield* putAction(ShopAction._setDomain(result.data?.result));

    yield* task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchOrder(action: PAction<string>) {
  const task = createTask("fetching", action.payload);
  yield task.start();

  try {
    const result = yield* call(api.user.getOrder, { _id: action.payload });
    if (result.data?.result) yield* putAction(ShopAction._setOrder(result.data?.result));
    yield* task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchRenewals() {
  const task = createTask("fetching", "renewals");
  yield task.start();

  try {
    const result = yield* call(api.domains.getPendingRenewal);
    if (result.data?.result) yield* putAction(ShopAction._setRenewals(result.data?.result));
    yield* task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* deletePendingRenewal(action: PAction<DeleteRenewal>) {
  const task = createTask("deleting", "renewals");
  yield task.start();

  try {
    const result = yield* call(api.domains.deletePendingRenewal, action.payload);
    if (result.data?.result) yield* putAction(ShopAction._deleteRenewal(action.payload));
    yield* task.success(true);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* Saga() {
  yield* takeLatest<ShopSagas>("shop/fetchCoupons", fetchCoupons);
  yield* takeLatest<ShopSagas>("shop/createCoupon", createCoupon);
  yield* takeLatest<ShopSagas>("shop/deleteCoupon", deleteCoupon);
  yield* takeLatest<ShopSagas>("shop/deleteDomain", deleteDomain);
  yield* takeLatest<ShopSagas>("shop/editPriceAndRenewDate", editPriceAndRenewDate);
  yield* takeLatest<ShopSagas>("shop/updateContacts", updateContacts);
  yield* takeLatest<ShopSagas>("shop/updateBillingInformation", updateBillingInformation);
  yield* takeLatest<ShopSagas>("shop/fetchDomain", fetchDomain);
  yield* takeLatest<ShopSagas>("shop/fetchUserDomains", fetchUserDomains);
  yield* takeLatest<ShopSagas>("shop/createDomainSubscription", createDomain);
  yield* takeLatest<ShopSagas>("shop/changePlanStatus", changeDomainPlanStatus);
  yield* takeLatest<ShopSagas>("shop/fetchUserOrders", fetchUserOrders);
  yield* takeLatest<ShopSagas>("shop/updateUserOrder", updateUserOrder);
  yield* takeLatest<ShopSagas>("shop/fetchOrder", fetchOrder);

  yield* takeLatest<ShopSagas>("shop/transferDomain", transferDomain);
  yield* takeLatest<ShopSagas>("shop/fetchTransferRequests", fetchTransferRequests);
  yield* takeLatest<ShopSagas>("shop/updateUserOrder", updateUserOrder);
  yield* takeLatest<ShopSagas>("shop/updateUserOrder", updateUserOrder);
  yield* takeLatest<ShopSagas>("shop/acceptTransferRequest", acceptTransferRequest);
  yield* takeLatest<ShopSagas>("shop/rejectTransferRequest", refuseTransferRequest);

  yield* takeLatest<ShopSagas>("shop/fetchRenewals", fetchRenewals);
  yield* takeLatest<ShopSagas>("shop/deletePendingRenewal", deletePendingRenewal);
}

export default Saga;
