import { useState, useEffect } from "react";
import TableComponent from "components/layout/TableComponent";
import ColumnsUser from "./TableColumns";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import { UserAction, UserSelect } from "store/modules/users";
import { useTranslation } from "react-i18next";
import Card from "components/layout/Card";
import { Button } from "@chakra-ui/react";
import useToastNotification from "hooks/useToastNotification";
import withPermissions from "components/hoc/withPermissions";
import ButtonPermission from "components/layout/ButtonPermission";

const ListAllUsers = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { t, i18n } = useTranslation();
  const { select, dispatch } = useBehave();
  const loadingUser = useTask("fetching", "get-auth-users");
  const loadingDeleteUser = useTask("deleting", "auth-users");
  const users = select(UserSelect.authUsers);

  useToastNotification(loadingDeleteUser, {
    success: { description: t("authUser.notification.success.description") },
  });
  
  useTaskCallback(loadingDeleteUser, { success: () => setSelectedIds([]) });

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = () => dispatch(UserAction.getAuthUser());

  const handleQuery = (i: string, data: UserRecord) => {
    return data.email?.toLowerCase().includes(i) || data.uid.toLowerCase().includes(i);
  };
  const handleDelete = () => {
    dispatch(UserAction.deleteAuthUsers({ ids: selectedIds }));
  };
  const handleChange = (uid) => {
    if (!selectedIds.includes(uid)) {
      setSelectedIds((s) => [...s, uid]);
    } else {
      setSelectedIds((s) => s.filter((s) => s !== uid));
    }
  };

  return (
    <Card direction="column" w="100%" p="24px 0px">
      <TableComponent
        columns={ColumnsUser({
          t,
          onChange: handleChange,
          locale: i18n.language,
          selected: selectedIds,
        })}
        data={users}
        pageSize={20}
        isLoading={loadingUser.isLoading}
        query={handleQuery}
        queryPlaceholder={t("users.tablePage.queryPlaceholder")}
        onRefresh={handleRefresh}
        action1={
          <ButtonPermission
            action="deleteAuthUsers"
            needConfirmation
            onClick={() => handleDelete()}
            variant="outline"
            disabled={!selectedIds.length}
            colorScheme="red"
            isLoading={loadingDeleteUser.isLoading}
          >
            {selectedIds.length ? selectedIds.length : ""} {t("general.button.delete")}
          </ButtonPermission>
        }
      />
    </Card>
  );
};

export default withPermissions("getAuthUser", ListAllUsers);
