import { firebaseCall } from "../utils/firebase.api.utils";

export const loginAdmin: ApiCallback<LoginAdmin, boolean> = (payload) => {
  return firebaseCall("admin-loginAdmin")(payload);
};

export const getAdmins: ApiCallback<void, Admin[]> = (payload) => {
  return firebaseCall("admin-getAdmins")(payload);
};

export const createAdmin: ApiCallback<CreateAdminPayload, Admin> = (payload) => {
  return firebaseCall("admin-createAdmin")(payload);
};

export const updateAdmin: ApiCallback<Admin, boolean> = (payload) => {
  return firebaseCall("admin-updateAdmin")(payload);
};

export const deleteAdmin: ApiCallback<SimpleId, boolean> = (payload) => {
  return firebaseCall("admin-deleteAdmin")(payload);
};

export const getUserRegion: ApiCallback<void, Region> = (payload) => {
  return firebaseCall("admin-getRegion")(payload);
};

export const createRegion: ApiCallback<Region, Region> = (payload) => {
  return firebaseCall("admin-createRegion")(payload);
};

export const updateRegion: ApiCallback<Region, boolean> = (payload) => {
  return firebaseCall("admin-updateRegion")(payload);
};

export const getRegions: ApiCallback<void, Region[]> = (payload) => {
  return firebaseCall("admin-getRegions")(payload);
};
export const getAdminPermissions: ApiCallback<void, AdminPermissionsResponse> = (payload) => {
  return firebaseCall("admin-getAdminPermissions")(payload);
};

export const getAdmin: ApiCallback<void, Admin> = (payload) => {
  return firebaseCall("admin-getAdmin")(payload);
};
