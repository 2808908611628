import api from "api";
import { createTask, PAction, putAction, takeLatest } from "behave-react";
import { call } from "typed-redux-saga";

import { UserAction, UserSagas } from ".";

function* fetchUsers() {
  const task = createTask("fetching", "users");
  yield task.start();

  try {
    const { data } = yield* call(api.user.getAllUsers);
    yield putAction(UserAction._setUsers(data?.result ?? []));
    yield task.success(data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* getAuthUser() {
  const task = createTask("fetching", "get-auth-users");
  yield task.start();
  try {
    const { data } = yield* call(api.auth.getAuthUser);
    yield putAction(UserAction._setAuthUsers(data?.result ?? []));
    yield task.success(data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* createUser(action: PAction<CreateUser>) {
  const task = createTask("creating", "user");
  yield task.start();

  try {
    const { data } = yield* call(api.user.createUser, action.payload);
    if (data?.result) yield putAction(UserAction._setUser(data.result));
    yield task.success(data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* editUser(action: PAction<EditUserPaylad>) {
  const task = createTask("updating", action.payload.user);
  yield task.start();

  try {
    const { data } = yield* call(api.user.editUser, action.payload);
    if (data?.result) yield task.success(data.result);
    yield putAction(UserAction._setUserProfile(action.payload));
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* editBillingInfo(action: PAction<EditBillingPayload>) {
  const task = createTask("updating", `billing${action.payload.user}`);
  yield task.start();
  try {
    const { data } = yield* call(api.user.editBillingInfo, action.payload);
    if (data?.result) yield task.success(data.result);
    yield putAction(UserAction._setUserBilling(action.payload));
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* editHusbpotId(action: PAction<EditUserHubspotId>) {
  const task = createTask("updating", `hs${action.payload.user}`);
  yield task.start();

  try {
    const { data } = yield* call(api.user.editUserHubspotId, action.payload);
    yield putAction(UserAction._setUserHubspotId(action.payload));
    yield task.success(data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* disableUser(action: PAction<string>) {
  const task = createTask("enabling", action.payload);
  yield task.start();

  try {
    const request = yield* call(api.user.disableUser, { _id: action.payload });
    yield putAction(
      UserAction._setUserDisable({
        disabled: true,
        _id: action.payload,
      })
    );
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* activateUser(action: PAction<string>) {
  const task = createTask("enabling", action.payload);
  yield task.start();

  try {
    const request = yield* call(api.user.activateUser, { _id: action.payload });
    yield putAction(
      UserAction._setUserDisable({
        disabled: false,
        _id: action.payload,
      })
    );
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* deleteUser(action: PAction<DeleteUser>) {
  const task = createTask("deleting", action.payload._id);
  yield task.start();
  try {
    const request = yield* call(api.user.forceDeleteUser, action.payload);
    yield putAction(UserAction._deleteUser(action.payload._id));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* deleteAuthUsers(action: PAction<DeleteAuths>) {
  const task = createTask("deleting", "auth-users");
  yield task.start();
  try {
    const request = yield* call(api.auth.deleteAuthUsers, action.payload);
    yield putAction(UserAction._deleteAuthUsers(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    console.log(e);
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* fetchUser(action: PAction<string>) {
  const task = createTask("fetching-user", action.payload);
  yield task.start();
  try {
    const result = yield* call(api.user.getUser, { _id: action.payload });
    if (result.data?.result) yield putAction(UserAction._setUser(result.data?.result));
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchPartnersRequests() {
  const task = createTask("fetching", "partner-req");
  yield task.start();

  try {
    const request = yield* call(api.partner.getAllRequests);
    yield putAction(UserAction._setPartnerRequests(request.data?.result ?? []));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* acceptPartnerRequest(action: PAction<string>) {
  const task = createTask("accepting", action.payload);
  yield task.start();

  try {
    const request = yield* call(api.partner.acceptRequest, { _id: action.payload });
    yield putAction(UserAction._deletePartnerRequest(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* refusePartnerRequest(action: PAction<string>) {
  const task = createTask("refusing", action.payload);
  yield task.start();

  try {
    const request = yield* call(api.partner.refuseRequest, { _id: action.payload });
    yield putAction(UserAction._deletePartnerRequest(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchUserCommunications(action: PAction<string>) {
  const task = createTask("fetching", "communications");
  yield task.start();
  try {
    const request = yield* call(api.user.getCommunicationsForUser, { _id: action.payload });
    yield putAction(UserAction._setCommunications(request?.data?.result ?? []));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* sendUserCommunication(action: PAction<CreateCommunication>) {
  const task = createTask("creating", "communication");
  yield task.start();
  try {
    const request = yield* call(api.user.sendCommunicationToUser, action.payload);
    if (request.data?.result) {
      yield putAction(UserAction._setCommunications([request?.data?.result]));
      yield putAction(UserAction._addCommunicationToUser(request?.data?.result));
    }
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield task.fail(e.data?.message);
  }
}
function* deleteCommunication(action: PAction<SimpleIdWithUser>) {
  const task = createTask("deleting", action.payload._id);
  yield task.start();

  try {
    yield* call(api.user.deleteCommunicationForUser, action.payload);
    yield putAction(UserAction._deleteCommunicationToUser(action.payload));
    yield task.success(true);
  } catch (e: any) {
    yield task.fail(e.data?.message);
  }
}

function* upgradeToPartner(action: PAction<string>) {
  const task = createTask("creating", "partner" + action.payload);
  yield task.start();
  try {
    const request = yield* call(api.partner.upgradeToPartner, { _id: action.payload });
    if (request?.data?.result) yield putAction(UserAction._setUserPartner(request.data.result));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* fetchPartner(action: PAction<string>) {
  const task = createTask("fetching", action.payload);
  yield task.start();
  try {
    const request = yield* call(api.partner.getUserPartner, { _id: action.payload });
    if (request?.data?.result) yield putAction(UserAction._setUserPartner(request.data.result));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* editPartnerReferral(action: PAction<EditPartnerReferral>) {
  const task = createTask("updating", `${action.payload.user}referral`);
  yield task.start();
  try {
    const request = yield* call(api.partner.editPartnerReferral, action.payload);
    if (request?.data?.result) yield putAction(UserAction._updatePartnerReferral(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* addPartnerCredits(action: PAction<AddPartnerCredits>) {
  const task = createTask("updating", `${action.payload.user}credits`);
  yield task.start();
  try {
    const request = yield* call(api.partner.addPartnerCredits, action.payload);
    if (request?.data?.result) yield putAction(UserAction._updatePartnerCredits(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* deletePartner(action: PAction<string>) {
  const task = createTask("deleting", action.payload);
  yield task.start();
  try {
    const request = yield* call(api.partner.deletePartner, { _id: action.payload });
    if (request?.data?.result) yield putAction(UserAction._deletePartner(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* getReferralHistory(action: PAction<string>) {
  const task = createTask("fetching", `${action.payload}referralHistory`);
  yield task.start();
  try {
    const request = yield* call(api.partner.getReferralHistory, { _id: action.payload });
    if (request?.data?.result) yield putAction(UserAction._setReferral(request.data.result));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* updateReferralHistory(action: PAction<UpdateReferralHistory>) {
  const task = createTask("updating", `${action.payload._id}referralHistory`);
  yield task.start();
  try {
    const request = yield* call(api.partner.updateReferralHistory, {
      _id: action.payload._id,
      history: action.payload.history,
    });
    if (request?.data?.result) yield putAction(UserAction._setReferralHistory(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* createPartnerDiscount(action: PAction<CreatePartnerDiscount>) {
  const task = createTask("creating", `${action.payload.partner}discount`);
  yield task.start();
  try {
    const request = yield* call(api.partner.createPartnerDiscount, action.payload);
    if (request?.data?.result)
      yield putAction(
        UserAction._setPartnerDiscount({
          data: request?.data?.result,
          partner: action.payload.partner,
        })
      );
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* deletePartnerDiscount(action: PAction<SimpleIdWithUser>) {
  const task = createTask("deleting", `${action.payload.user}discount`);
  yield task.start();
  try {
    const request = yield* call(api.partner.deletePartnerDiscount, action.payload);
    if (request?.data?.result) yield putAction(UserAction._deletePartnerDiscount(action.payload));
    yield task.success(request?.data?.result);
  } catch (e: any) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* Saga() {
  yield* takeLatest<UserSagas>("user/fetchUsers", fetchUsers);
  yield* takeLatest<UserSagas>("user/getAuthUser", getAuthUser);
  yield* takeLatest<UserSagas>("user/editUser", editUser);
  yield* takeLatest<UserSagas>("user/createUser", createUser);
  yield* takeLatest<UserSagas>("user/editBillingInfo", editBillingInfo);
  yield* takeLatest<UserSagas>("user/editHusbpotId", editHusbpotId);
  yield* takeLatest<UserSagas>("user/disableUser", disableUser);
  yield* takeLatest<UserSagas>("user/activateUser", activateUser);
  yield* takeLatest<UserSagas>("user/deleteUser", deleteUser);
  yield* takeLatest<UserSagas>("user/deleteAuthUsers", deleteAuthUsers);
  yield* takeLatest<UserSagas>("user/fetchUser", fetchUser);

  yield* takeLatest<UserSagas>("user/fetchPartnersRequests", fetchPartnersRequests);
  yield* takeLatest<UserSagas>("user/acceptPartnerRequest", acceptPartnerRequest);
  yield* takeLatest<UserSagas>("user/refusePartnerRequest", refusePartnerRequest);

  yield* takeLatest<UserSagas>("user/fetchUserCommunications", fetchUserCommunications);
  yield* takeLatest<UserSagas>("user/sendUserCommunication", sendUserCommunication);
  yield* takeLatest<UserSagas>("user/deleteCommunication", deleteCommunication);

  yield* takeLatest<UserSagas>("user/upgradeToPartner", upgradeToPartner);
  yield* takeLatest<UserSagas>("user/fetchPartner", fetchPartner);
  yield* takeLatest<UserSagas>("user/editPartnerReferral", editPartnerReferral);
  yield* takeLatest<UserSagas>("user/addPartnerCredits", addPartnerCredits);
  yield* takeLatest<UserSagas>("user/deletePartner", deletePartner);
  yield* takeLatest<UserSagas>("user/getReferralHistory", getReferralHistory);
  yield* takeLatest<UserSagas>("user/updateReferralHistory", updateReferralHistory);
  // yield* takeLatest<UserSagas>("user/deleteReferralHistory", deleteReferralHistory);
  yield* takeLatest<UserSagas>("user/createPartnerDiscount", createPartnerDiscount);
  yield* takeLatest<UserSagas>("user/deletePartnerDiscount", deletePartnerDiscount);
}

export default Saga;
