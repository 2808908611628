import react, { useRef } from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  IconButton,
  PopoverCloseButton,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { MdEdit, MdOutlineAutorenew } from "react-icons/md";
import { AiOutlineMessage, AiOutlineDelete } from "react-icons/ai";

const PopoverDeactivated = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = useRef(null);

  return (
    <>
      <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom"
        closeOnBlur={false}
        size="sm"
      >
        <PopoverTrigger>
          <IconButton size="sm" icon={<MdEdit />} aria-label="edit" variant="unstyled" />
        </PopoverTrigger>
        <PopoverContent p={5} textAlign="left">
          <PopoverArrow />
          <PopoverCloseButton />
          <Button variant="unstyled" display="flex" alignItems="center" justifyContent="start">
            <Icon as={AiOutlineDelete} mr="7px"></Icon>Elimina
          </Button>
          <Button variant="unstyled" display="flex" alignItems="center" justifyContent="start">
            <Icon as={MdOutlineAutorenew} mr="7px"></Icon>Riattiva
          </Button>
          <Button variant="unstyled" display="flex" alignItems="center" justifyContent="start">
            <Icon as={AiOutlineMessage} mr="7px"></Icon>Comunica utente
          </Button>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default PopoverDeactivated;
