import { ReactNode } from "react";
import Card from "../../Card";

interface FormCardProps {
  children: ReactNode;
  key: string;
  submit?: boolean;
}

const FormCard = ({ children, key, submit }: FormCardProps) => {
  if (submit) {
    return (
      <Card
        display="flex"
        flexDirection="row"
        alignItems="center"
        position="fixed"
        right="48px"
        bottom="48px"
        gap="24px"
        width="max-content"
        justifyContent="space-between"
      >
        {children}
      </Card>
    );
  }

  return (
    <Card key={key} direction="column" gap="24px" alignItems="end">
      {children}
    </Card>
  );
};

export default FormCard;
