import React, { ReactNode } from "react";
import { Flex, Skeleton, Stack } from "@chakra-ui/react";
const SkeletonLoading = () => {
  return (
    <Stack px="30px" w="100%" opacity={0.5} mb="24px">
      <Flex w="100%" gap="10px">
        <Skeleton height="40px" w="40px" borderRadius="20px" />
        <Skeleton height="40px" w="20%" />
        <Skeleton height="40px" w="20%" />
        <Skeleton height="40px" w="20%" />
        <Skeleton height="40px" w="20%" />
        <Skeleton height="40px" w="20%" />
      </Flex>
      <Flex w="100%" gap="10px">
        <Skeleton height="40px" w="40px" borderRadius="20px" />
        <Skeleton height="40px" w="20%" />
        <Skeleton height="40px" w="20%" />
        <Skeleton height="40px" w="20%" />
        <Skeleton height="40px" w="20%" />
        <Skeleton height="40px" w="20%" />
      </Flex>
    </Stack>
  );
};

export default SkeletonLoading;
