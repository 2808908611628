import { Spinner, Box, Flex, Image, Text } from "@chakra-ui/react";
import Card from "components/layout/Card";
import CommunicationItem from "./components/ChatCard";
import TextEditor from "components/layout/TextEditor";
import { useBehave, useTask } from "behave-react";
import { UserAction, UserSelect } from "store/modules/users";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useToastNotification from "hooks/useToastNotification";
import { useTranslation } from "react-i18next";
import withPermissions from "components/hoc/withPermissions";

const Communications = () => {
  const { select, dispatch } = useBehave();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const communications = select(UserSelect.communicationsForUser(id));
  const loadingSend = useTask("creating", "communication");
  const loading = useTask("fetching", "communications");

  useToastNotification(loadingSend, { success: { description: "Message send!" } });
  const handleOnSend = (content: string) => {
    if (!id) return;
    dispatch(
      UserAction.sendUserCommunication({ user: id, communication: { content, title: " " } })
    );
  };

  useEffect(() => {
    if (id) dispatch(UserAction.fetchUserCommunications(id));
  }, []);

  return (
    <Flex direction="column" gap="32px">
      <Card direction="column" w="100%" p="30px" h="100%">
        <Box
          w="100%"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="15px"
          p="30px"
          overflow="scroll"
        >
          {loading.isLoading && <Spinner />}
          {!communications?.length ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              w="100%"
              h="100%"
              color="gray.200"
              pt="20px"
              direction="column"
            >
              <Image
                src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                style={{
                  height: 60,
                }}
              />
              <Text>{t("communications.empty")}</Text>
            </Flex>
          ) : (
            communications?.map((e) => <CommunicationItem communication={e} />)
          )}
        </Box>
      </Card>
      <Card>
        <Flex alignItems="flex-end" direction="column">
          <TextEditor onSend={handleOnSend} loading={loadingSend.isLoading} />
        </Flex>
      </Card>
    </Flex>
  );
};

export default withPermissions("sendCommunicationToId", Communications);
