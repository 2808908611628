import { Button, useDisclosure } from "@chakra-ui/react";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { AdminAction, AdminSelect } from "store/modules/admin";
import useToastNotification from "hooks/useToastNotification";

import FormBuilder from "components/layout/FormBuilder";
import FormCard from "components/layout/FormCard";
import { REGIONS_DB } from "utils/DataMaps";
import AvatarEdit from "components/layout/AvatarPopoverEdit";
import { useState } from "react";
import RegionUsers from "./components/RegionUsers";
import { useTranslation } from "react-i18next";
import withPermissions from "components/hoc/withPermissions";

interface CreateUpdateRegionProps {
  isEdit?: boolean;
}

const CreateUpdateRegion = ({ isEdit }: CreateUpdateRegionProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { dispatch, select } = useBehave();
  const { id: _id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const id = _id ?? "";
  const region = select(AdminSelect.region(id));
  const [profilePic, setProfilePic] = useState(region?.logo);
  const navigate = useNavigate();
  const loading = useTask(isEdit ? "updating" : "creating", isEdit ? id : "region");

  useTaskCallback(loading, {
    success: isEdit ? undefined : () => navigate("/admin"),
  });
  useToastNotification(loading, {
    success: isEdit
      ? {
          title: t("general.notification.success.title"),
          description: t("region.notification.success.description"),
        }
      : undefined,
  });

  const handleNewRegion = async (variables: Region) => {
    const payload = { ...variables, logo: profilePic };
    delete payload.admins;
    if (!payload.description?.length) delete payload.description;
    dispatch(AdminAction.createRegion(variables as Region));
  };

  const handleUpdateRegion = async (variables: Region) => {
    const payload = { ...variables } as any;
    delete payload.admins;
    if (!payload.description?.length) delete payload.description;
    if (!payload.logo) delete payload.logo;
    dispatch(
      AdminAction.updateRegion({
        ...payload,
        _id: id,
        logo: profilePic,
      } as Region)
    );
  };

  return (
    <FormBuilder
      initialValues={region}
      cardRender={FormCard}
      empty={!region}
      editMode={isEdit}
      fallback={{
        message: (
          <>
            {t("region.formFallback.message_1")} <b>{id}</b> {t("region.formFallback.message_2")}
          </>
        ),
        action: (
          <NavLink to="admin">
            <Button>Go back</Button>
          </NavLink>
        ),
      }}
      submit={{
        action: isEdit ? t("general.button.update") : t("general.button.create"),
        title: t("general.confirmation.title"),
        color: isEdit ? "brand" : "green",
        loading: loading.isLoading,
        subtitle: isEdit ? t("region.subtitle.titleUpdate") : t("region.subtitle.titleCreate"),
        onSubmit: isEdit ? handleUpdateRegion : handleNewRegion,
      }}
      cards={[
        {
          title: t("region.form.title"),
          subtitle: t("region.form.subtitle"),
          rightAction: (
            <AvatarEdit
              zIndex="99"
              size="lg"
              onChangeUrl={(url) => setProfilePic(url)}
              src={profilePic}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />
          ),
          elements: [
            {
              id: "name",
              type: "text",
              label: t("region.form.label.region"),
              placeholder: t("region.form.placeholder.region"),
              required: true,
            },
            {
              id: "_id",
              type: "select",
              label: t("region.form.label.databaseId"),
              options: REGIONS_DB,
              required: true,
            },
            {
              id: "description",
              type: "textArea",
              label: t("region.form.label.description"),
              placeholder: t("region.form.placeholder.description"),
            },
          ],
        },
        { cardRender: () => <RegionUsers region={region} />, elements: [], hide: !isEdit },
      ]}
    />
  );
};

export default withPermissions("updateRegion", CreateUpdateRegion);
