import { Box, Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";

import AdminTable from "../Admin/TablePage";
import { useTranslation } from "react-i18next";
import RegionTable from "pages/Regions/TablePage";
import withPermissions from "components/hoc/withPermissions";
import usePermissions from "hooks/usePermissions";
import { useEffect } from "react";
import { useBehave } from "behave-react";
import { AdminAction } from "store/modules/admin";

const UserManagement = () => {
  const { t } = useTranslation();
  const { permit } = usePermissions();
  const { dispatch } = useBehave();
  useEffect(() => {
    dispatch(AdminAction.getAdmins());
    dispatch(AdminAction.getRegions());
  }, []);

  return (
    <Box>
      <Tabs variant="soft-rounded">
        <TabList gap="12px" pl="24px" pt="12px">
          {permit("getRegions") && (
            <Tab
              backgroundColor="#136EF810"
              color="#136EF8"
              _selected={{ backgroundColor: "#136EF8", color: "#fff" }}
            >
              {t("userManagement.tabTitle.region")}
            </Tab>
          )}
          <Tab
            backgroundColor="#136EF810"
            color="#136EF8"
            _selected={{ backgroundColor: "#136EF8", color: "#fff" }}
          >
            {t("userManagement.tabTitle.admin")}
          </Tab>
        </TabList>
        <TabPanels>
          {permit("getRegions") && (
            <TabPanel>
              <RegionTable />
            </TabPanel>
          )}
          <TabPanel>
            <AdminTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
export default UserManagement;
