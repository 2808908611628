import { createRoot } from "react-dom/client";
import "react-quill/dist/quill.snow.css";
import App from "./pages/App";
import "assets/css/App.css";
import "./language/i18n";

const container: any = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
