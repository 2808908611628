import { useBehave, useTask } from "behave-react";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { UserAction, UserSelect } from "store/modules/users";
import CreditsBar from "./components/CreditsBar";
import DiscountTable from "./components/DiscountTable";
import OrderReferralTable from "./components/OrderReferralTable";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Card from "components/layout/Card";
import DeletePartner from "./components/DeletePartner";
import withPermissions from "components/hoc/withPermissions";

const PartnerPage = () => {
  const { select, dispatch } = useBehave();
  const { id } = useParams();
  const partner = select(UserSelect.partner(id));
  const { t } = useTranslation();
  const loading = useTask("fetching", id);

  useEffect(() => {
    if (!id) return;
    dispatch(UserAction.fetchPartner(id));
  }, [id]);

  if (loading.isLoading)
    return (
      <Flex alignItems="center" h="40vh" justifyContent="center" gap="16px">
        <Spinner />
        <Text>{t("partnerPage.loading")}</Text>
      </Flex>
    );

  if (!partner)
    return (
      <Flex alignItems="center" h="40vh" justifyContent="center">
        <Text>{t("partnerPage.notFound")}</Text>
      </Flex>
    );

  return (
    <>
      <CreditsBar {...partner} />
      <DiscountTable {...partner} />
      <OrderReferralTable {...partner} />
      <DeletePartner {...partner} />
    </>
  );
};

export default withPermissions("getUserPartner", PartnerPage);
