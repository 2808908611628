import React, { useEffect } from "react";
import { useBehave, useTask } from "behave-react";
import TableComponent from "components/layout/TableComponent";
import {
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import DiscountColumn from "./Discount.columns";
import Card from "components/layout/Card";
import { AiOutlinePlus } from "react-icons/ai";
import CreateDiscount from "./CreateDiscount";
import { UserAction } from "store/modules/users";
import useToastNotification from "hooks/useToastNotification";
import { useParams } from "react-router-dom";
import ButtonPermission from "components/layout/ButtonPermission";

const DiscountTable = (partner: UserPartner) => {
  const { dispatch } = useBehave();
  const loading = useTask("deleting", `${partner.user_id}discount`);

  useToastNotification(loading, { success: { description: "Discount removed succesfully" } });
  const handleQuery = (i: string, data: PartnerDiscount) => {
    return data._id.includes(i) || data.description.includes(i);
  };
  const handleDelete = async (id: string) => {
    await dispatch(UserAction.fetchPartner(id));
    dispatch(UserAction.deletePartnerDiscount({ _id: id, user: partner.user_id }));
  };

  const DiscountModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <>
        <ButtonPermission action="createPartnerDiscount" onClick={onOpen} variant="outline">
          <Icon as={AiOutlinePlus} w="5" h="5" />
        </ButtonPermission>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <CreateDiscount id={partner._id} onFinish={onClose} />
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Card direction="column" w="100%" px="0px" mt="20px">
      <TableComponent
        columns={DiscountColumn(handleDelete, loading.isLoading)}
        data={partner.discounts}
        queryPlaceholder="Find discount:"
        pageSiblings={4}
        pageSize={6}
        query={handleQuery}
        action1={<DiscountModal />}
      />
    </Card>
  );
};

export default DiscountTable;
