import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { Behavior } from "behave-react";
import Store from "../../store";
import Navigation from "components/navigation";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const App = () => {
  useEffect(() => {
    Sentry.init({
      dsn: "https://75bda971d82841f18217d4e1386b0559@o1145321.ingest.sentry.io/6212579",
      integrations: [new BrowserTracing()],
      environment: process.env.REACT_APP_SENTRY_ENV,
      tracesSampleRate: 1.0,
    });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Behavior store={Store.store} persistor={Store.persistor}>
          <Navigation />
        </Behavior>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
