import { Flex, VStack } from "@chakra-ui/react";
import { NavRoute } from "routes";
import Brand from "./Brand";
import Links from "./Links";

interface SidebarContentProps {
  routes: NavRoute[];
}

function SidebarContent({ routes }: SidebarContentProps) {
  return (
    <Flex direction="column" minH="100%" height="max-content" pt="25px" borderRadius="30px">
      <Brand />
      <VStack w="100%" alignItems="start" gap="0px" mt="64px">
        <Links routes={routes} />
      </VStack>
    </Flex>
  );
}

export default SidebarContent;
