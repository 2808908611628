import { createSlice } from "@reduxjs/toolkit";
import reducers from "./reducers";
import * as selectors from "./selectors";
import saga from "./saga";

const MODULE = "shop";

const INITIAL_STATE: ShopModuleState = {};

const slice = createSlice({
  initialState: INITIAL_STATE,
  name: MODULE,
  reducers: { ...reducers.actions, ...reducers.saga },
});

type RS = keyof typeof reducers.saga;
export type ShopSagas = `${typeof MODULE}/${RS}`;
export const ShopAction = slice.actions;
export const ShopSelect = selectors;
export const ShopSaga = saga;
export const ShopInitialState = INITIAL_STATE;
export default slice.reducer;
