import api from "api";
import { createTask, PAction, putAction, takeLatest } from "behave-react";
import { call } from "typed-redux-saga";

import { AdminAction, AdminSagas } from ".";

function* signup(action: PAction<LoginPayload>) {
  const task = createTask("fetching", "auth");

  yield* task.start();
  try {
    yield call(() =>
      api.auth.loginWithEmailPassword(action.payload.email, action.payload.password)
    );
    yield* call(api.auth.loginAdmin);

    yield* task.success(true);
  } catch (e) {
    yield call(() => api.auth.logout());
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* logout() {
  const task = createTask("deleting", "auth");

  yield* task.start();
  try {
    yield* call(api.auth.logout);

    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* getRegion() {
  const task = createTask("fetching", "region");
  yield* task.start();
  try {
    const { data } = yield* call(api.admin.getUserRegion);
    if (data?.result) yield* putAction(AdminAction.setRegion(data?.result));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* getRegions() {
  const task = createTask("fetching", "regions");

  yield* task.start();
  try {
    const { data } = yield* call(api.admin.getRegions);
    yield* putAction(AdminAction.setRegions(data?.result ?? []));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* getAdmins() {
  const task = createTask("fetching", "admins");

  yield* task.start();
  try {
    const { data } = yield* call(api.admin.getAdmins);
    yield* putAction(AdminAction.setAdmins(data?.result ?? []));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* createAdmin(action: PAction<CreateAdminPayload>) {
  const task = createTask("creating", "admins");

  yield* task.start();
  try {
    const { data } = yield* call(api.admin.createAdmin, action.payload);

    if (data?.result) yield* putAction(AdminAction.setAdmin(data?.result));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* updateAdmin(action: PAction<Admin>) {
  const task = createTask("updating", action.payload._id);

  yield* task.start();
  try {
    yield* call(api.admin.updateAdmin, action.payload);
    yield* putAction(AdminAction.setAdmin(action.payload));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* createRegion(action: PAction<Region>) {
  const task = createTask("creating", "region");

  yield* task.start();
  try {
    const { data } = yield* call(api.admin.createRegion, action.payload);

    if (data?.result) yield* putAction(AdminAction.setRegion(data.result));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* updateRegion(action: PAction<Region>) {
  const task = createTask("updating", action.payload._id);

  yield* task.start();
  try {
    yield* call(api.admin.updateRegion, action.payload);
    yield* putAction(AdminAction.setRegion(action.payload));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}
function* deleteAdmin(action: PAction<string>) {
  const task = createTask("deleting", action.payload);

  yield* task.start();
  try {
    yield* call(api.admin.deleteAdmin, { _id: action.payload });
    yield* putAction(AdminAction.removeAdmin(action.payload));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* getAdmin() {
  const task = createTask("fetching", "admin");

  yield* task.start();
  try {
    const { data } = yield* call(api.admin.getAdmin);
    if (data?.result) yield* putAction(AdminAction.setAdmin(data?.result));
    const { data: data2 } = yield* call(api.admin.getAdminPermissions);
    yield* putAction(AdminAction.setPermissions(data2?.result ?? {}));
    yield* task.success(true);
  } catch (e) {
    yield* task.fail((e as any)?.data?.message ?? (e as any)?.message);
  }
}

function* Saga() {
  yield* takeLatest<AdminSagas>("admin/signup", signup);
  yield* takeLatest<AdminSagas>("admin/logout", logout);
  yield* takeLatest<AdminSagas>("admin/getRegions", getRegions);
  yield* takeLatest<AdminSagas>("admin/getRegion", getRegion);
  yield* takeLatest<AdminSagas>("admin/getAdmins", getAdmins);
  yield* takeLatest<AdminSagas>("admin/createAdmin", createAdmin);
  yield* takeLatest<AdminSagas>("admin/updateAdmin", updateAdmin);
  yield* takeLatest<AdminSagas>("admin/updateRegion", updateRegion);
  yield* takeLatest<AdminSagas>("admin/createRegion", createRegion);
  yield* takeLatest<AdminSagas>("admin/deleteAdmin", deleteAdmin);
  yield* takeLatest<AdminSagas>("admin/getAdmin", getAdmin);
}

export default Saga;
