import { Text, Flex, Checkbox, CheckboxGroup, Tag, Avatar } from "@chakra-ui/react";
import AvatarRegionComponent from "components/layout/AvatarRegionComponent";
import { Column } from "components/layout/TableComponent";
import { NavLink } from "react-router-dom";
import { AdminColorSchema } from "theme/ColorsSchema";
interface ColumnUserProps {
  t: (t: string) => string;
  onChange: (p: string) => void;
  locale: string;
  selected: string[];
}
const ColumnsUser: (t: ColumnUserProps) => Column<UserRecord>[] = ({
  t,
  onChange,
  locale,
  selected,
}) => [
  {
    title: "",
    id: "uid",
    width: "0px",
    render: (value, data) => (
      <Flex align="center">
        <Checkbox
          isChecked={selected.includes(value)}
          onChange={() => onChange(data.uid)}
          colorScheme="brandScheme"
          me="10px"
        />
      </Flex>
    ),
  },
  {
    title: t("general.tableColumn.title.email"),
    id: "email",
    width: "100%",

    render: (value) => (
      <Text fontSize="14px" fontWeight="500">
        {value}
      </Text>
    ),
  },
  {
    title: t("general.tableColumn.title.uid"),
    id: "uid",
    width: "100%",

    render: (value, data) => (
      <NavLink to={`/${locale}/users/${value}`}>
        <Text fontSize="14px" fontWeight="500">
          {value}
        </Text>
      </NavLink>
    ),
  },
  {
    title: t("general.tableColumn.title.region"),
    id: "customClaims",
    width: "100%",
    render: (value, data) =>
      data.customClaims?.region ? (
        <AvatarRegionComponent size="sm" region={data.customClaims?.["region"]} />
      ) : null,
  },
  {
    title: t("general.tableColumn.title.permissionLevel"),
    id: "customClaims",
    width: "100%",

    render: (value, data) => (
      <Tag
        variant="subtle"
        colorScheme={(AdminColorSchema as any)[data.customClaims?.["level"]]}
        fontWeight="700"
        width="max-content"
      >
        {data.customClaims?.["level"] === "admin"
          ? t("general.permissionLevel.admin")
          : data.customClaims?.["level"] === "standard_sales"
          ? t("general.permissionLevel.standardSales")
          : data.customClaims?.["level"] === "advanced_sales"
          ? t("general.permissionLevel.advancedSales")
          : data.customClaims?.["level"] === "executive"
          ? t("general.permissionLevel.executive")
          : data.customClaims?.["level"] === "customer_service"
          ? t("general.permissionLevel.customerService")
          : "User"}
      </Tag>
    ),
  },
];

export default ColumnsUser;
