const validateVAT = (code: string) => {
  if (code.length !== 11) return false;

  let x = 0;
  let y = 0;
  let z = 0;
  let c = 0;

  for (let i = 0; i < 11; i++) {
    c = code.charCodeAt(i) - 48;

    if (c < 0 || c > 9) return false;

    if (i % 2 === 0 && i !== 10) x += c;
    if (i % 2 === 1) y += c * 2;
    if (i % 2 == 1 && c >= 5) z++;
  }

  const t = (x + y + z) % 10;

  return c === 10 - (t === 0 ? 10 : t) ? true : false;
};

export default validateVAT;
