import React from "react";
import FormCardFull from "components/layout/FormCard/FormCardPopup";
import FormBuilder from "components/layout/FormBuilder";
import { DURATION_LICENSE, TYPE_LICENSE } from "utils/DataMaps";
import { useBehave, useTask } from "behave-react";
import { UserAction } from "store/modules/users";
import moment from "moment";

interface UpdateHistoryProps {
  partner: UserPartner;
  onFinish: () => void;
}
const CreateOrderReferral = ({ partner, onFinish }: UpdateHistoryProps) => {
  const { dispatch } = useBehave();
  const loading = useTask("updating", `${partner.link_referral._id}referralHistory`);

  const handleSubmit = (variables: LinkUsage) => {
    const history = [...partner.link_referral.history];
    const amount =
      variables?.items.reduce((a, b) => parseFloat(a as any) + parseFloat(b.amount as any), 0) ?? 0;
    if (!amount) return;
    const usage: LinkUsage = { ...variables, user: "admin", amount: amount * 100 };
    history.push(usage);
    dispatch(
      UserAction.updateReferralHistory({
        _id: partner.link_referral._id,
        history,
        partner: partner._id,
      })
    );
  };

  return (
    <FormBuilder
      cardRender={FormCardFull}
      cards={[
        {
          intialTransforms: [
            [
              "date",
              (v) => moment(v).format("yyyy-MM-DD"),
              (v) => moment(v, "yyyy-MM-DD").toDate().getTime(),
            ],
          ],
          submit: {
            onSubmit: handleSubmit,
            action: "Add",
            color: "green",
            loading: loading.isLoading,
          },
          title: "Add order to referral",
          initialValues: {
            items: [{}],
            user: "admin",
          } as any,
          elements: [
            {
              id: "items",
              type: "array",
              arrayElements: [
                {
                  id: "plan",
                  type: "select",
                  label: "Plan",
                  options: TYPE_LICENSE,
                  flex: 1,
                },
                {
                  id: "type",
                  type: "select",
                  label: "Duration",
                  options: DURATION_LICENSE,
                  flex: 1,
                },
                {
                  id: "amount",
                  type: "number",
                  label: "License price",
                  placeholder: "Sold price",
                  flex: 0.2,
                  rightAddon: "€",
                },
              ],
            },

            {
              id: "date",
              type: "date",
              label: "Order date",
              flex: 1,
            },
          ],
        },
      ]}
    />
  );
};

export default CreateOrderReferral;
