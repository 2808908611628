import { useEffect } from "react";
import { Button, Icon } from "@chakra-ui/react";
import { AiOutlineDownload } from "react-icons/ai";
import TableComponent from "components/layout/TableComponent";
import ColumnsOrders from "./TableColumns";

import Card from "components/layout/Card";

import { useBehave, useTask } from "behave-react";
import { ShopAction, ShopSelect } from "store/modules/shop";
import { useParams } from "react-router-dom";
import { UserSelect } from "store/modules/users";
import { useTranslation } from "react-i18next";
import withPermissions from "components/hoc/withPermissions";

const OrdersPage = () => {
  const { select, dispatch } = useBehave();
  const { id } = useParams<{ id: string }>();
  const orders = select(ShopSelect.orders(id ?? ""));
  const loading = useTask("fetching", "orders");
  const user = select(UserSelect.user(id ?? ""));
  const { t } = useTranslation();

  const handleRefresh = () => {
    if (user && user.orders?.length) dispatch(ShopAction.fetchUserOrders(id ?? ""));
  };
  const handleQuery = (i: string, data: Order) => {
    return data.orderNumber.includes(i);
  };

  useEffect(() => {
    handleRefresh();
  }, [id]);

  return (
    <Card direction="column" w="100%" px="0px">
      <TableComponent
        columns={ColumnsOrders}
        data={orders}
        query={handleQuery}
        queryPlaceholder={t("orders.tablePage.queryPlaceholder")}
        pageSiblings={8}
        pageSize={12}
        onRefresh={handleRefresh}
        isLoading={loading.isLoading}
        action2={
          <Button display="none" variant="outline" alignItems="center">
            <a id="downloadAnchorElem" href={"ok"} download="orders">
              <Icon as={AiOutlineDownload} w="5" h="5" />
            </a>
          </Button>
        }
      />
    </Card>
  );
};

export default withPermissions("getOrdersForUser", OrdersPage);
