import { ScriptConfig } from "./InstallationScript";
export const baseConfig: ScriptConfig = {
  trigger: {
    color: "#146ff8",
    size: "medium",
    positionH: "left",
    positionV: "bottom",
    mobile: {
      size: "medium",
      positionH: "left",
      positionV: "bottom",
    },
  },
  interface: {
    color: "#146ff8",
    positionH: "left",
    language: "en",
  },
};
