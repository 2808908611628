import Card from "components/layout/Card";
import { Flex, Text, Button, Box, Icon } from "@chakra-ui/react";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";

interface MultiStepsProps {
  current: number;
  onChange: (current: number) => void;
}

const MultiSteps = (props: MultiStepsProps) => {
  const { t } = useTranslation();
  const onChange = (nextStep) => {
    props.onChange(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
  };
  const onNext = () => onChange(props.current + 1);
  const onPrevious = () => onChange(props.current - 1);

  return (
    <Card mb="20px">
      <Flex mb="35px" justifyContent="space-between" alignItems="center">
        <Text fontSize="25px" fontWeight="bold" color="gray.900" mr="20px">
          {t("renewals.steps.title")}
        </Text>
        <Flex gap="10px">
          <Button onClick={onPrevious} disabled={props.current === 0} variant="outline">
            <Icon as={HiOutlineArrowSmLeft} />
          </Button>
          <Button onClick={onNext} disabled={props.current === 4} variant="outline">
            <Icon as={HiOutlineArrowSmRight} />
          </Button>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" gap="10px">
        <Button
          variant="unstyled"
          onClick={() => props.onChange(0)}
          textAlign="left"
          fontWeight="unset"
          w="100%"
        >
          <Flex alignItems="center">
            <Flex gap="5px" pt="7px" width="100%">
              <Flex direction="column" width="100%">
                <Flex alignItems="center" gap="10px">
                  <Flex
                    flex="none"
                    border="1px solid"
                    borderColor={props.current === 0 ? "blue.500" : "gray.400"}
                    bg={props.current === 0 ? "blue.500" : "none"}
                    borderRadius="full"
                    w="24px"
                    h="24px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize="10px" color={props.current === 0 ? "white" : "gray.400"}>
                      1
                    </Text>
                  </Flex>
                  <Text fontWeight="medium" color="gray.700">
                    {t("renewals.steps.stepTitle")} 1
                  </Text>
                  <Box h="1px" w="100%" bg={props.current === 0 ? "blue.500" : "gray.400"} />
                </Flex>
                <Text fontWeight="normal" pl="35px" color="gray.700">
                  0 - 7 {t("renewals.steps.stepSub")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Button>
        <Button
          variant="unstyled"
          onClick={() => props.onChange(1)}
          textAlign="left"
          fontWeight="unset"
          w="100%"
        >
          <Flex alignItems="center">
            <Flex gap="5px" pt="7px" width="100%">
              <Flex direction="column" width="100%">
                <Flex alignItems="center" gap="10px">
                  <Flex
                    flex="none"
                    border="1px solid"
                    borderColor={props.current === 1 ? "blue.500" : "gray.400"}
                    bg={props.current === 1 ? "blue.500" : "none"}
                    borderRadius="full"
                    w="24px"
                    h="24px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize="10px" color={props.current === 1 ? "white" : "gray.400"}>
                      2
                    </Text>
                  </Flex>
                  <Text fontWeight="medium" color="gray.700">
                    {t("renewals.steps.stepTitle")} 2
                  </Text>
                  <Box h="1px" w="100%" bg={props.current === 1 ? "blue.500" : "gray.400"} />
                </Flex>
                <Text fontWeight="normal" pl="35px" color="gray.700">
                  7 - 15 {t("renewals.steps.stepSub")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Button>
        <Button
          variant="unstyled"
          onClick={() => props.onChange(2)}
          textAlign="left"
          fontWeight="unset"
          w="100%"
        >
          <Flex alignItems="center">
            <Flex gap="5px" pt="7px" width="100%">
              <Flex direction="column" width="100%">
                <Flex alignItems="center" gap="10px">
                  <Flex
                    flex="none"
                    border="1px solid"
                    borderColor={props.current === 2 ? "blue.500" : "gray.400"}
                    bg={props.current === 2 ? "blue.500" : "none"}
                    borderRadius="full"
                    w="24px"
                    h="24px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize="10px" color={props.current === 2 ? "white" : "gray.400"}>
                      3
                    </Text>
                  </Flex>
                  <Text fontWeight="medium" color="gray.700">
                    {t("renewals.steps.stepTitle")} 3
                  </Text>
                  <Box h="1px" w="100%" bg={props.current === 2 ? "blue.500" : "gray.400"} />
                </Flex>
                <Text fontWeight="normal" pl="35px" color="gray.700">
                  15 - 23 {t("renewals.steps.stepSub")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Button>
        <Button
          variant="unstyled"
          onClick={() => props.onChange(3)}
          textAlign="left"
          fontWeight="unset"
          w="100%"
        >
          <Flex alignItems="center">
            <Flex gap="5px" pt="7px" width="100%">
              <Flex direction="column" width="100%">
                <Flex alignItems="center" gap="10px">
                  <Flex
                    flex="none"
                    border="1px solid"
                    borderColor={props.current === 3 ? "blue.500" : "gray.400"}
                    bg={props.current === 3 ? "blue.500" : "none"}
                    borderRadius="full"
                    w="24px"
                    h="24px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize="10px" color={props.current === 3 ? "white" : "gray.400"}>
                      4
                    </Text>
                  </Flex>
                  <Text fontWeight="medium" color="gray.700">
                    {t("renewals.steps.stepTitle")} 4
                  </Text>
                  <Box h="1px" w="100%" bg={props.current === 3 ? "blue.500" : "gray.400"} />
                </Flex>
                <Text fontWeight="normal" pl="35px" color="gray.700">
                  + 23 {t("renewals.steps.stepSub")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Button>
        <Button
          variant="unstyled"
          onClick={() => props.onChange(4)}
          textAlign="left"
          fontWeight="unset"
          w="100%"
        >
          <Flex alignItems="center">
            <Flex gap="5px" pt="7px" width="100%">
              <Flex direction="column" width="100%">
                <Flex alignItems="center" gap="10px">
                  <Flex
                    flex="none"
                    border="1px solid"
                    borderColor={props.current === 4 ? "red.500" : "gray.400"}
                    bg={props.current === 4 ? "red.500" : "none"}
                    borderRadius="full"
                    w="24px"
                    h="24px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize="10px" color={props.current === 4 ? "white" : "gray.400"}>
                      5
                    </Text>
                  </Flex>
                  <Text fontWeight="medium" color="red.500">
                    {t("renewals.steps.stepDeactivate")}
                  </Text>
                  <Box h="1px" w="100%" bg={props.current === 4 ? "red.500" : "gray.400"} />
                </Flex>
                <Text fontWeight="normal" pl="35px" color="red.300">
                  {t("renewals.steps.stepDeactivateSub")}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Button>
      </Flex>
    </Card>
  );
};

export default MultiSteps;
