import React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";

import access from "../../../../utils/DotNotation";

interface TextAreaFieldProps extends TextareaProps {
  id: string;
  label?: string;
  disabled?: boolean;
  errors?: any;
  patternError?: string;
  placeholder?: string;
  formRef?: any;
}

export default function TextAreaField({
  id,
  label,
  variant,
  formRef,
  errors,
  disabled,
  placeholder,
  ...rest
}: TextAreaFieldProps) {
  return (
    <Flex direction="column" w="100%">
      <FormControl isInvalid={access(errors, id)} isDisabled={disabled}>
        {label && (
          <FormLabel
            display="flex"
            ms="10px"
            htmlFor={id}
            fontSize="sm"
            fontWeight="bold"
            _hover={{ cursor: "pointer" }}
          >
            {label}
          </FormLabel>
        )}
        <Textarea
          fontWeight="500"
          placeholder={placeholder}
          borderColor={errors?.[id] ? "red.400" : undefined}
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          {...rest}
          {...formRef}
        />
        <FormErrorMessage fontSize="smaller">
          {access(errors, id) &&
            (access(errors, id).type === "pattern"
              ? rest.patternError
              : access(errors, id).message?.lenght > 0
              ? access(errors, id).message
              : "*  This field is required")}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
}
