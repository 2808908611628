import { Avatar, AvatarGroup, Flex, HStack, Tag, Text } from "@chakra-ui/react";
import ButtonPermission from "components/layout/ButtonPermission";
import { MdEdit } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Column } from "components/layout/TableComponent";

const RegionColumns: (t: (string) => string) => Column<Region>[] = (t) => [
  {
    id: "logo",
    title: t("region.tableColumn.title.region"),
    width: "30%",
    render: (value, data) => (
      <NavLink to={`regions/${data._id}`}>
        <Flex alignItems="center">
          <Avatar name={data.name} src={value} size="sm" me="16px" />
          <Text whiteSpace="nowrap" fontSize="sm" fontWeight="700">
            {data.name}
          </Text>
        </Flex>
      </NavLink>
    ),
  },

  {
    id: "_id",
    title: t("region.tableColumn.title.admins"),
    width: "20%",
    render: (_, data) => (
      <AvatarGroup size="sm" max={2}>
        {data.admins
          ?.filter((e) => e.permission_level === "admin")
          .map((e) => (
            <Avatar name={e.email} src={e.profile_pic} />
          ))}
      </AvatarGroup>
    ),
  },
  {
    id: "_id",
    title: t("region.tableColumn.title.executive"),
    width: "20%",
    render: (_, data) => (
      <AvatarGroup size="sm" max={2}>
        {data.admins
          ?.filter((e) => e.permission_level === "executive")
          .map((e) => (
            <Avatar name={e.email} src={e.profile_pic} />
          ))}
      </AvatarGroup>
    ),
  },
  {
    id: "_id",
    title: t("region.tableColumn.title.advancedSales"),
    width: "20%",
    render: (_, data) => (
      <AvatarGroup size="sm" max={2}>
        {data.admins
          ?.filter((e) => e.permission_level === "advanced_sales")
          .map((e) => (
            <Avatar name={e.email} src={e.profile_pic} />
          ))}
      </AvatarGroup>
    ),
  },
  {
    id: "_id",
    title: t("region.tableColumn.title.standardSales"),
    width: "20%",
    render: (_, data) => (
      <AvatarGroup size="sm" max={2}>
        {data.admins
          ?.filter((e) => e.permission_level === "standard_sales")
          .map((e) => (
            <Avatar name={e.email} src={e.profile_pic} />
          ))}
      </AvatarGroup>
    ),
  },
  {
    id: "_id",
    title: t("region.tableColumn.title.customerService"),
    width: "20%",
    render: (_, data) => (
      <AvatarGroup size="sm" max={2}>
        {data.admins
          ?.filter((e) => e.permission_level === "customer_service")
          .map((e) => (
            <Avatar name={e.email} src={e.profile_pic} />
          ))}
      </AvatarGroup>
    ),
  },

  {
    width: "60px",
    id: "_id",
    render: (value) => (
      <ButtonPermission
        variant="unstyled"
        leftIcon={<MdEdit />}
        action="updateRegion"
        to={`regions/${value}`}
      />
    ),
  },
];

export default RegionColumns;
