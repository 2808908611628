import { Text, Tag } from "@chakra-ui/react";

import { CgTrashEmpty } from "react-icons/cg";
import moment from "moment";
import ButtonPermission from "components/layout/ButtonPermission";
import { Column } from "components/layout/TableComponent";

interface CouponsColumnsProps {
  onDelete: (code: string) => void;
  t: (t: string) => string;
}

const discountLabel = (discount: Coupon["discount"]) => {
  if (discount.type === "PERCENTAGE") return `${(discount.amount * 100).toFixed(0)} %`;
  else return `- ${(discount.amount / 100).toFixed(0)} €`;
};

const filterLabel = (discount?: Coupon["filter"]) => {
  if (!discount) return "All";
  if (discount.to_user) return "Specific user";
  return `${discount.to_type ?? "ALL"} ${discount.to_plan ?? "ALL"}`;
};

const CouponsColumns: (p: CouponsColumnsProps) => Column<Coupon>[] = ({ onDelete, t }) => [
  {
    title: t("coupon.tableColumn.title.code"),
    id: "code",

    render: (value, data) => (
      <Text
        fontSize="14px"
        color="brand.200"
        fontWeight="500"
        textTransform="uppercase"
        whiteSpace="nowrap"
      >
        {data.code}
      </Text>
    ),
  },
  {
    title: t("general.tableColumn.title.description"),
    id: "description",
    width: "70%",
    render: (value) => (
      <Text fontSize="14px" fontWeight="500" opacity="0.6" whiteSpace="nowrap">
        {value}
      </Text>
    ),
  },
  {
    title: t("coupon.tableColumn.title.appliesTo"),
    id: "filter",
    render: (value) => (
      <Tag fontSize="14px" fontWeight="500" opacity="0.6" colorScheme="purple" whiteSpace="nowrap">
        {filterLabel(value)}
      </Tag>
    ),
  },
  {
    title: t("general.tableColumn.title.amount"),
    id: "discount",
    render: (value: Coupon["discount"]) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        opacity="0.6"
        whiteSpace="nowrap"
        colorScheme={value.type === "NET" ? "blue" : "orange"}
      >
        {discountLabel(value)}
      </Tag>
    ),
  },
  {
    title: t("general.tableColumn.title.expiration"),
    id: "expire_date",
    render: (value) =>
      value <= Date.now() ? (
        <Tag fontSize="14px" whiteSpace="nowrap" fontWeight="500" opacity="0.6" colorScheme="red">
          Expired
        </Tag>
      ) : (
        <Text fontSize="12px" whiteSpace="nowrap">
          {moment(value).fromNow()}
        </Text>
      ),
  },
  {
    id: "code",
    render: (value) => {
      return (
        <ButtonPermission
          variant="link"
          action="deleteCoupon"
          size="sm"
          onClick={() => onDelete(value)}
          whiteSpace="nowrap"
          colorScheme="red"
          needConfirmation
          leftIcon={<CgTrashEmpty />}
        />
      );
    },
  },
];

export default CouponsColumns;
