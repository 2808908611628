import { PAction } from "behave-react";
import select from "utils/ArrayUtils";
import { UserInitialState } from ".";

const AsyncReducers = {
  fetchUsers: (state: UsersModuleState) => {},
  getAuthUser: (state: UsersModuleState) => {},
  createUser: (state: UsersModuleState, action: PAction<CreateUser>) => {},
  editUser: (state: UsersModuleState, action: PAction<EditUserPaylad>) => {},
  editBillingInfo: (state: UsersModuleState, action: PAction<EditBillingPayload>) => {},
  disableUser: (state: UsersModuleState, action: PAction<string>) => {},
  activateUser: (state: UsersModuleState, action: PAction<string>) => {},
  editHusbpotId: (state: UsersModuleState, action: PAction<EditUserHubspotId>) => {},
  deleteUser: (state: UsersModuleState, action: PAction<DeleteUser>) => {},
  deleteAuthUsers: (state: UsersModuleState, action: PAction<DeleteAuths>) => {},
  fetchUser: (state: UsersModuleState, action: PAction<string>) => {},

  // COMMUNINCATION
  fetchUserCommunications: (state: UsersModuleState, action: PAction<string>) => {},
  sendUserCommunication: (state: UsersModuleState, action: PAction<CreateCommunication>) => {},
  deleteCommunication: (state: UsersModuleState, action: PAction<SimpleIdWithUser>) => {},
  markCommunicationAsRead: (state: UsersModuleState, action: PAction<string>) => {},

  //PARTNER
  fetchPartnersRequests: (state: UsersModuleState) => {},
  acceptPartnerRequest: (state: UsersModuleState, action: PAction<string>) => {},
  refusePartnerRequest: (state: UsersModuleState, action: PAction<string>) => {},

  fetchPartner: (state: UsersModuleState, action: PAction<string>) => {},
  editPartnerReferral: (state: UsersModuleState, action: PAction<EditPartnerReferral>) => {},
  addPartnerCredits: (state: UsersModuleState, action: PAction<AddPartnerCredits>) => {},
  upgradeToPartner: (state: UsersModuleState, action: PAction<string>) => {},
  deletePartner: (state: UsersModuleState, action: PAction<string>) => {},
  getReferralHistory: (state: UsersModuleState, action: PAction<string>) => {},
  updateReferralHistory: (state: UsersModuleState, action: PAction<UpdateReferralHistory>) => {},
  createPartnerDiscount: (state: UsersModuleState, action: PAction<CreatePartnerDiscount>) => {},
  deletePartnerDiscount: (state: UsersModuleState, action: PAction<SimpleIdWithUser>) => {},
};

const Reducers = {
  _setUsers: (state: UsersModuleState, action: PAction<User[]>) => {
    state.users = action.payload;
  },
  _setAuthUsers: (state: UsersModuleState, action: PAction<UserRecord[]>) => {
    state.authUsers = action.payload;
  },
  _setUser: (state: UsersModuleState, action: PAction<User>) => {
    select(state.users ?? [], "_id", action.payload._id).set(action.payload);
  },
  _setUserProfile: (state: UsersModuleState, action: PAction<EditUserPaylad>) => {
    select(state.users ?? [], "_id", action.payload.user).merge(action.payload.data);
  },
  _setUserBilling: (state: UsersModuleState, action: PAction<EditBillingPayload>) => {
    select(state.users ?? [], "_id", action.payload.user).update(
      "payment.billingInformations",
      action.payload.data
    );
  },
  _setUserHubspotId: (state: UsersModuleState, action: PAction<EditUserHubspotId>) => {
    select(state.users ?? [], "_id", action.payload.user).merge(action.payload);
  },
  _deleteUser: (state: UsersModuleState, action: PAction<string>) => {
    select(state.users ?? [], "_id", action.payload).pop();
  },
  _deleteAuthUsers: (state: UsersModuleState, action: PAction<DeleteAuths>) => {
    const newList = state.authUsers?.filter((a) => !action.payload.ids.includes(a.uid));
    state.authUsers = newList;
  },
  _setUserDisable: (
    state: UsersModuleState,
    action: PAction<{ _id: string; disabled: boolean }>
  ) => {
    select(state.users ?? [], "_id", action.payload._id).update(
      "disabled",
      action.payload.disabled
    );
  },
  _setPartnerRequests: (state: UsersModuleState, action: PAction<PartnerRequest[]>) => {
    state.partner_requests = action.payload;
  },
  _deletePartnerRequest: (state: UsersModuleState, action: PAction<string>) => {
    select(state.partner_requests ?? [], "user_id", action.payload).pop();
  },
  _setCommunications: (state: UsersModuleState, action: PAction<Communication[]>) => {
    state.communications = state.communications ?? [];
    action.payload.forEach((c) => {
      if (select(state.communications ?? [], "_id", c._id).element)
        select(state.communications ?? [], "_id", c._id).set(c);
      else state.communications?.push(c);
    });
  },
  _deleteCommunication: (state: UsersModuleState) => {
    state.communications = [];
  },
  _addCommunicationToUser: (state: UsersModuleState, action: PAction<Communication>) => {
    const user = select(state.users ?? [], "_id", action.payload.user).element as User;
    const _newCommunications = [...(user.communications ?? []), action.payload];
    select(state.users ?? [], "_id", action.payload.user).update(
      "communications",
      _newCommunications
    );
  },
  _deleteCommunicationToUser: (state: UsersModuleState, action: PAction<SimpleIdWithUser>) => {
    const user = select(state.users ?? [], "_id", action.payload.user).element as User;
    const _newCommunications = [...(user.communications ?? [])].filter(
      (d) => d !== action.payload._id
    );
    select(state.communications ?? [], "_id", action.payload._id).pop();
    select(state.users ?? [], "_id", action.payload.user).update(
      "communications",
      _newCommunications
    );
  },

  _setUserPartner: (state: UsersModuleState, action: PAction<UserPartner>) => {
    if (!select(state.partners ?? [], "_id", action.payload._id).element)
      state.partners = [action.payload, ...(state.partners ?? [])];
    select(state.partners ?? [], "_id", action.payload._id).set(action.payload);
    select(state.users ?? [], "_id", action.payload.user_id).merge({
      type: "PARTNER",
      partner: {
        _id: action.payload._id,
        verified: "VERIFIED",
      },
    });
  },
  _updatePartnerReferral: (state: UsersModuleState, action: PAction<EditPartnerReferral>) => {
    const { partner } = select(state.users ?? [], "_id", action.payload.user).element as User;
    if (!partner) return;
    const p = select(state.partners ?? [], "_id", partner._id).element as UserPartner;
    if (!p) return;
    select(state.partners ?? [], "_id", p._id).update("link_referral", {
      ...p.link_referral,
      url_ref: action.payload.new_code,
    });
  },
  _setReferral: (state: UsersModuleState, action: PAction<LinkReferral>) => {
    select(state.partners ?? [], "_id", action.payload.partner_id).update(
      "link_referral",
      action.payload
    );
  },
  _setReferralHistory: (state: UsersModuleState, action: PAction<UpdateReferralHistory>) => {
    const p = select(state.partners ?? [], "_id", action.payload.partner).element as UserPartner;
    if (!p) return;
    select(state.partners ?? [], "_id", action.payload.partner).merge({
      link_referral: { ...p.link_referral, history: action.payload.history },
    });
  },
  _updatePartnerCredits: (state: UsersModuleState, action: PAction<AddPartnerCredits>) => {
    const { partner } = select(state.users ?? [], "_id", action.payload.user).element as User;
    if (!partner) return;
    const p = select(state.partners ?? [], "_id", partner._id).element as UserPartner;
    if (!p) return;

    select(state.partners ?? [], "_id", p._id).merge({
      credits: p.credits + action.payload.credits,
      last_credits_update: Date.now(),
    });
  },
  _setPartnerDiscount: (state: UsersModuleState, action: PAction<CreatePartnerDiscount>) => {
    const p = select(state.partners ?? [], "_id", action.payload.partner).element as UserPartner;
    if (!p) return;
    select(state.partners ?? [], "_id", action.payload.partner).merge({
      discounts: [...(p.discounts ?? []), action.payload.data],
    });
  },
  _deletePartnerDiscount: (state: UsersModuleState, action: PAction<SimpleIdWithUser>) => {
    const { partner } = select(state.users ?? [], "_id", action.payload.user).element as User;
    if (!partner) return;
    const p = select(state.partners ?? [], "_id", partner._id).element as UserPartner;
    if (!p) return;
    select(state.partners ?? [], "_id", partner._id).merge({
      discounts: p.discounts?.filter((a) => a._id !== action.payload._id),
    });
  },
  _deletePartner: (state: UsersModuleState, action: PAction<string>) => {
    select(state.partners ?? [], "_id", action.payload).pop();
  },
  purge: (state: UsersModuleState) => ({ ...UserInitialState }),
};

const UserReducers = { actions: Reducers, saga: AsyncReducers };
export default UserReducers;
