import { Input, InputGroup, useColorModeValue, InputLeftElement, Icon } from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";

interface SearchBarProps {
  width?: string;
  minWidth?: string;
  change: () => void;
  background?: string;
  placeholder?: string;
  borderRadius?: string;
}

export function SearchBar(props: SearchBarProps) {
  const { background, placeholder, borderRadius, width, change, minWidth } = props;

  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  return (
    <InputGroup w={{ base: "100%", md: width }} minW={minWidth}>
      <InputLeftElement
        pointerEvents="none"
        children={<Icon as={MdSearch} color="gray.400" w="5" h="5" />}
      />
      <Input
        variant="outline"
        fontSize="sm"
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight="500"
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : "Search..."}
        onChange={change}
      />
    </InputGroup>
  );
}
