import { Text, Tag, Flex, Box, Icon, Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import dateFormatter from "../../utils/DateFormatter";
import { MdOutlineMail, MdSmartphone, MdEdit } from "react-icons/md";
import { CgTrashEmpty } from "react-icons/cg";
import { typeColor, bgTypeColor, statusTypeColor } from "../../utils/DataMaps";
import ButtonPermission from "components/layout/ButtonPermission";
import { Column } from "components/layout/TableComponent";

interface LicenseColumnsProps {
  t: (t: string) => string;
}

const LicenseColumns: (p: LicenseColumnsProps) => Column<DomainSubscription>[] = ({ t }) => [
  {
    title: t("general.tableColumn.title.domain"),
    id: "domain",
    width: "100%",
    render: (value, data) => (
      <NavLink to={data._id}>
        <Text whiteSpace="nowrap" color="brand.200" fontWeight="500">
          <Text>{data.domain}</Text>
        </Text>
      </NavLink>
    ),
  },
  {
    title: t("general.tableColumn.title.status"),
    id: "plan",

    render: (value, data) => (
      <Flex alignItems="center" width="max-content">
        <Flex
          w="3"
          h="3"
          borderRadius="full"
          bg={statusTypeColor[data.plan.status].bg}
          mr="6px"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            w="1.5"
            h="1.5"
            borderRadius="full"
            bg={statusTypeColor[data.plan.status].color}
          ></Box>
        </Flex>
        <Text
          fontSize="14px"
          color="brand.200"
          fontWeight="500"
          textTransform="lowercase"
          width="max-content"
        >
          <Text
            textTransform="capitalize"
            color={statusTypeColor[data.plan.status].color}
            width="max-content"
          >
            {statusTypeColor[data.plan.status].name}
          </Text>
        </Text>
      </Flex>
    ),
  },
  {
    title: t("general.tableColumn.title.plan"),
    id: "plan",
    width: "140px",
    minWidth: "140px",
    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        variant="subtle"
        color={typeColor[data.plan.id]}
        bg={bgTypeColor[data.plan.id]}
      >
        {data.plan.id}
      </Tag>
    ),
  },
  {
    title: t("general.tableColumn.title.type"),
    id: "plan",
    width: "140px",
    minWidth: "140px",
    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        variant="subtle"
        border="1px solid"
        borderColor={typeColor[data.plan.type]}
        color={typeColor[data.plan.type]}
      >
        {data.plan.type}
      </Tag>
    ),
  },
  {
    title: t("general.tableColumn.title.renewDate"),
    id: "plan",
    width: "140px",
    minWidth: "140px",
    render: (value, data) => (
      <Text fontSize="14px" color="gray.800" fontWeight="500" textTransform="lowercase">
        <Text textTransform="capitalize">
          {dateFormatter(data.plan.renewDate, "month_name_day_year")}
        </Text>
      </Text>
    ),
  },
  {
    title: t("general.tableColumn.title.autoRenew"),
    id: "auto_renew",
    width: "140px",
    minWidth: "140px",
    render: (value, data) => (
      <Text fontSize="14px" color="gray.800" fontWeight="500" textTransform="lowercase">
        <Text textTransform="capitalize">{value ? "Yes" : "No"}</Text>
      </Text>
    ),
  },
  {
    title: t("general.tableColumn.title.contacts"),
    id: "contact",

    minWidth: "200px",
    render: (value, data) => (
      <Box color="gray.800">
        <Flex alignItems="center" mb="7px">
          <Icon as={MdOutlineMail} w="4" h="4" mr="7px" />
          <Text fontSize="14px" fontWeight="500">
            {data.contact.email}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Icon as={MdSmartphone} w="4" h="4" mr="7px" />
          <Text fontSize="14px" fontWeight="500">
            {data.contact.phone}
          </Text>
        </Flex>
      </Box>
    ),
  },
  {
    title: t("general.tableColumn.title.edit"),
    id: "_id",
    width: "100%",
    render: (value, data) => (
      <ButtonPermission
        variant="unstyled"
        leftIcon={<MdEdit />}
        action="updateOrderForUser"
        to={data._id}
      />
    ),
  },
];

export default LicenseColumns;
