import { firebaseCall } from "../utils/firebase.api.utils";

export const getAllCoupons: ApiCallback<void, Coupon[]> = (payload) => {
  return firebaseCall("admin-getAllCoupons")(payload);
};

export const createCoupon: ApiCallback<CreateCoupon, Coupon> = (payload) => {
  return firebaseCall("admin-createCoupon")(payload);
};

export const deleteCoupon: ApiCallback<SimpleId, boolean> = (payload) => {
  return firebaseCall("admin-deleteCoupon")(payload);
};
