import { Box, Flex } from "@chakra-ui/react";
import WidgetPreviewBG from "assets/img/widget_preview.jpg";

interface InterfaceProps {
  color: string;
  pathH: any;
  pathV?: any;
  pathSize?: any;
  isInterface?: boolean;
  isWidget?: boolean;
  isMobile?: boolean;
}
const WidgetPreview = ({
  color,
  pathH,
  pathV,
  pathSize,
  isInterface,
  isWidget,
  isMobile,
}: InterfaceProps) => {
  return (
    <Box>
      {isInterface ? (
        <Flex
          w="350px"
          h="230px"
          //backgroundImage={WidgetPreviewBG}
          backgroundColor="#ededed76"
          borderRadius="24px"
          padding="20px"
          transition="all ease 200ms"
          alignItems={pathV === "top" ? "flex-start" : pathV === "center" ? "center" : "flex-end"}
        >
          <Box transition="all ease 200ms" ms={pathH === "left" ? "0px" : "220px"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="88" height="126" viewBox="0 0 88 126">
              <g id="Raggruppa_2" data-name="Raggruppa 2" transform="translate(765.117 -732.69)">
                <g id="Raggruppa_1" data-name="Raggruppa 1" transform="translate(-765 733)">
                  <rect
                    id="Rettangolo_1"
                    data-name="Rettangolo 1"
                    width="88"
                    height="126"
                    rx="16"
                    transform="translate(-0.117 -0.31)"
                    fill="#eef0f6"
                  />
                  <path
                    id="Rettangolo_3"
                    data-name="Rettangolo 3"
                    d="M0,0H88a0,0,0,0,1,0,0V0a6,6,0,0,1-6,6H6A6,6,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                    transform="translate(-0.117 119.69)"
                    fill={color}
                  />
                  <path
                    id="Rettangolo_2"
                    data-name="Rettangolo 2"
                    d="M6,0H82a6,6,0,0,1,6,6V6a0,0,0,0,1,0,0H0A0,0,0,0,1,0,6V6A6,6,0,0,1,6,0Z"
                    transform="translate(-0.117 -0.31)"
                    fill={color}
                  />
                  <rect
                    id="Rettangolo_4"
                    data-name="Rettangolo 4"
                    width="47"
                    height="7"
                    rx="3.5"
                    transform="translate(6.883 12.689)"
                    fill="#fff"
                  />
                  <rect
                    id="Rettangolo_6"
                    data-name="Rettangolo 6"
                    width="60"
                    height="7"
                    rx="3.5"
                    transform="translate(6.883 22.69)"
                    fill="#fff"
                  />
                  <rect
                    id="Rettangolo_7"
                    data-name="Rettangolo 7"
                    width="40"
                    height="7"
                    rx="3.5"
                    transform="translate(6.883 32.69)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </Box>
        </Flex>
      ) : isWidget ? (
        <Flex
          w="350px"
          h="230px"
          backgroundColor="#ededed76"
          borderRadius="24px"
          padding="20px"
          transition="all ease 200ms"
          alignItems={pathV === "top" ? "flex-start" : pathV === "center" ? "center" : "flex-end"}
        >
          <Box transition="all ease 200ms" ms={pathH === "left" ? "0px" : "270px"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40px"
              height="40px"
              transform={
                pathSize === "small"
                  ? "scale(0.5)"
                  : pathSize === "medium"
                  ? "scale(0.7)"
                  : "scale(1)"
              }
              viewBox="0 0 100 100"
            >
              <g id="Raggruppa_1" data-name="Raggruppa 1" transform="translate(-791.061 -487.061)">
                <circle
                  id="Ellisse_2"
                  data-name="Ellisse 2"
                  cx="50"
                  cy="50"
                  r="50"
                  transform="translate(791.061 487.061)"
                  fill={color}
                />
                <g id="widget_icon" transform="translate(814.918 501.848)">
                  <path
                    id="Tracciato_1"
                    data-name="Tracciato 1"
                    d="M38.171,82.585a3.811,3.811,0,0,0,1.654-.427,3.932,3.932,0,0,0,1.921-5.389S34.116,59.323,32.729,52.92a85.15,85.15,0,0,1-.96-10.937A2.108,2.108,0,0,1,33.156,39.9l17.073-5.122a4.053,4.053,0,0,0-2.241-7.789s-15.792,5.122-21.5,5.122c-5.549,0-21.234-5.015-21.234-5.015A4.219,4.219,0,0,0,.131,29.605a3.969,3.969,0,0,0,2.774,5.122l17.073,5.122a1.928,1.928,0,0,1,1.387,2.081c-.16,2.774-.427,8.75-.96,10.937-1.387,6.4-9.017,23.849-9.017,23.849A4.109,4.109,0,0,0,13.309,82.1a3.819,3.819,0,0,0,5.282-1.921l8.163-16.646,7.79,16.753A3.983,3.983,0,0,0,38.171,82.585Z"
                    transform="translate(0.063 -12.532)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellisse_1"
                    data-name="Ellisse 1"
                    cx="7.789"
                    cy="7.789"
                    r="7.789"
                    transform="translate(19.08 0)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </Box>
        </Flex>
      ) : isMobile ? (
        <Flex
          w="250px"
          h="350px"
          backgroundColor="#ededed76"
          //backgroundImage={WidgetPreviewBG}
          backgroundSize="cover"
          borderRadius="24px"
          padding="20px"
          transition="all ease 200ms"
        >
          <Box
            transition="all ease 200ms"
            ms={pathH === "left" ? "0px" : "170px"}
            marginTop={pathV === "top" ? "0px" : pathV === "center" ? "135px" : "270px"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40px"
              height="40px"
              transform={
                pathSize === "small"
                  ? "scale(0.5)"
                  : pathSize === "medium"
                  ? "scale(0.7)"
                  : "scale(1)"
              }
              viewBox="0 0 100 100"
            >
              <g id="Raggruppa_1" data-name="Raggruppa 1" transform="translate(-791.061 -487.061)">
                <circle
                  id="Ellisse_2"
                  data-name="Ellisse 2"
                  cx="50"
                  cy="50"
                  r="50"
                  transform="translate(791.061 487.061)"
                  fill={color}
                />
                <g id="widget_icon" transform="translate(814.918 501.848)">
                  <path
                    id="Tracciato_1"
                    data-name="Tracciato 1"
                    d="M38.171,82.585a3.811,3.811,0,0,0,1.654-.427,3.932,3.932,0,0,0,1.921-5.389S34.116,59.323,32.729,52.92a85.15,85.15,0,0,1-.96-10.937A2.108,2.108,0,0,1,33.156,39.9l17.073-5.122a4.053,4.053,0,0,0-2.241-7.789s-15.792,5.122-21.5,5.122c-5.549,0-21.234-5.015-21.234-5.015A4.219,4.219,0,0,0,.131,29.605a3.969,3.969,0,0,0,2.774,5.122l17.073,5.122a1.928,1.928,0,0,1,1.387,2.081c-.16,2.774-.427,8.75-.96,10.937-1.387,6.4-9.017,23.849-9.017,23.849A4.109,4.109,0,0,0,13.309,82.1a3.819,3.819,0,0,0,5.282-1.921l8.163-16.646,7.79,16.753A3.983,3.983,0,0,0,38.171,82.585Z"
                    transform="translate(0.063 -12.532)"
                    fill="#fff"
                  />
                  <circle
                    id="Ellisse_1"
                    data-name="Ellisse 1"
                    cx="7.789"
                    cy="7.789"
                    r="7.789"
                    transform="translate(19.08 0)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </Box>
        </Flex>
      ) : null}
    </Box>
  );
};

export default WidgetPreview;
