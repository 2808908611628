import { useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { useBehave, useTask } from "behave-react";
import Card from "../../components/layout/Card";
import ColumnsTransfer from "./TableColumns";
import TableComponent from "components/layout/TableComponent";
import { ShopAction, ShopSelect } from "store/modules/shop";
import { useTranslation } from "react-i18next";
import withPermissions from "components/hoc/withPermissions";

const TransfersRequestsPage = () => {
  const { select, dispatch } = useBehave();
  const loading = useTask("fetching", "transfers");
  const transfers = select(ShopSelect.transfers);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!transfers?.length) handleRefresh();
  }, []);

  const handleQuery = (i: string, data: TransferRequest) => {
    return data.user_literal.toLowerCase().includes(i);
  };
  const handleRefresh = () => dispatch(ShopAction.fetchTransferRequests());

  const handleAcceptRequest = (id: string) => {
    dispatch(ShopAction.acceptTransferRequest(id));
  };
  const handleRefuseRequest = (id: string) => {
    dispatch(ShopAction.rejectTransferRequest(id));
  };

  return (
    <Card direction="column" w="100%" px="0px">
      <TableComponent
        columns={ColumnsTransfer({
          onAccept: handleAcceptRequest,
          onRefuse: handleRefuseRequest,
          t,
          locale: i18n.language,
        })}
        data={transfers}
        isLoading={loading.isLoading}
        query={handleQuery}
        queryPlaceholder={t("transferRequest.table.queryPlaceholder")}
        pageSiblings={8}
        pageSize={6}
        onRefresh={handleRefresh}
      />
    </Card>
  );
};

export default withPermissions("getTransferRequests", TransfersRequestsPage);
