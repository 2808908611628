/**
 *
 * Prices are without VAT
 * and are expressed in cents of € ---> 4900 = 49€
 *
 */
const PLANS: PlanInterface = {
  JUMBO: {
    name_id: "plans.jumbo_name",
    description_id: "plans.jumbo_description",
    accessibe_id: 3,
    plans: {
      MONTH: {
        amount: 34900,
        currency: "eur",
      },
      YEAR: {
        amount: 349000,
        currency: "eur",
      },
      TRIAL: {
        amount: 7,
        currency: "day",
      },
      LONG_TRIAL: {
        amount: 30,
        currency: "day",
      },
    },
  },
  HUGE: {
    accessibe_id: 2,
    name_id: "plans.huge_name",
    description_id: "plans.huge_description",
    plans: {
      MONTH: {
        amount: 19900,
        currency: "eur",
      },
      YEAR: {
        amount: 199000,
        currency: "eur",
      },
      TRIAL: {
        amount: 7,
        currency: "day",
      },
      LONG_TRIAL: {
        amount: 30,
        currency: "day",
      },
    },
  },
  LARGE: {
    accessibe_id: 5,
    name_id: "plans.large_name",
    description_id: "plans.large_description",

    plans: {
      MONTH: {
        amount: 14900,
        currency: "eur",
      },
      YEAR: {
        amount: 149000,
        currency: "eur",
      },
      TRIAL: {
        amount: 7,
        currency: "day",
      },
      LONG_TRIAL: {
        amount: 30,
        currency: "day",
      },
    },
  },
  STANDARD: {
    accessibe_id: 1,
    name_id: "plans.standard_name",
    description_id: "plans.standard_description",
    plans: {
      MONTH: {
        amount: 4900,
        currency: "eur",
      },
      YEAR: {
        amount: 49000,
        currency: "eur",
      },
      TRIAL: {
        amount: 7,
        currency: "day",
      },
      LONG_TRIAL: {
        amount: 30,
        currency: "day",
      },
    },
  },
};

export default PLANS;
