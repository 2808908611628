import { Box, Button, Flex, Heading, Text, VStack, Image, Container } from "@chakra-ui/react";

import Card from "components/layout/Card";
import { useBehave, useTask } from "behave-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { AdminAction } from "store/modules/admin";
import useToastNotification from "hooks/useToastNotification";
import InputField from "components/layout/FormBuilder/Fields/InputField";

const LoginPage = () => {
  const { dispatch } = useBehave();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginPayload>();

  const loading = useTask("fetching", "auth");

  useToastNotification(loading);

  const handleLogin = async (variables: LoginPayload) => {
    dispatch(AdminAction.signup(variables));
  };

  const { t } = useTranslation();

  return (
    <Flex width="100%" height="100vh" flexDirection="column">
      <Box background="linear-gradient(135deg, #136EF8 0%, #004FCE 100%)" flex={1} />
      <Box flex={1} />
      <Flex w="100%" h="100%" alignItems="center" justifyContent="center" pos="absolute">
        <Container flexDirection="column" display="flex" maxW="500px">
          <Image
            w="200px"
            src="/icons/acsw-logo.svg"
            alt="AccessiWay Logo"
            pos="absolute"
            left={{ lg: "48px" }}
            top="32px"
          />
          <Card flexDirection="column" gap="12px" p="32px">
            <Heading>{t("login.title")}</Heading>
            <Text>{t("login.subtitle")}</Text>
            <form autoComplete="none" onSubmit={handleSubmit(handleLogin)}>
              <VStack mt="24px">
                <InputField
                  errors={errors}
                  placeholder="Enter email"
                  label={t("general.form.label.email")}
                  id="email"
                  type="email"
                  formRef={register("email", { required: true })}
                />
                <InputField
                  label={t("general.form.label.password")}
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  errors={errors}
                  formRef={register("password", { required: true })}
                />

                <Button
                  isLoading={loading.isLoading}
                  type="submit"
                  marginTop="32px !important"
                  width="180px"
                  variant="brand"
                >
                  {t("general.button.login")}
                </Button>
              </VStack>
            </form>
          </Card>
        </Container>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
