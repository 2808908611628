import { PAction } from "behave-react";
import select from "utils/ArrayUtils";

import { AdminInitialState } from ".";

const AsyncReducers = {
  signup: (state: AdminModuleState, action: PAction<LoginPayload>) => {},
  logout: (state: AdminModuleState) => {},

  getRegions: (state: AdminModuleState) => {},
  getRegion: (state: AdminModuleState) => {},
  createRegion: (state: AdminModuleState, action: PAction<Region>) => {},
  updateRegion: (state: AdminModuleState, action: PAction<Region>) => {},

  getAdmin: (state: AdminModuleState) => {},
  createAdmin: (state: AdminModuleState, action: PAction<CreateAdminPayload>) => {},
  updateAdmin: (state: AdminModuleState, action: PAction<Admin>) => {},
  deleteAdmin: (state: AdminModuleState, action: PAction<string>) => {},
  getAdmins: (state: AdminModuleState) => {},
};

const Reducers = {
  setRegions: (state: AdminModuleState, action: PAction<Region[]>) => {
    state.regions = action.payload;
  },
  setAdmins: (state: AdminModuleState, action: PAction<Admin[]>) => {
    state.admins = action.payload;
  },
  setAdmin: (state: AdminModuleState, action: PAction<Admin>) => {
    select(state.admins ?? [], "_id", action.payload._id).set(action.payload);
  },
  setRegion: (state: AdminModuleState, action: PAction<Region>) => {
    select(state.regions ?? [], "_id", action.payload._id).set(action.payload);
  },
  removeAdmin: (state: AdminModuleState, action: PAction<string>) => {
    select(state.admins ?? [], "_id", action.payload).pop();
  },
  setPermissions: (state: AdminModuleState, action: PAction<AdminPermissionsResponse>) => {
    state.permissions = action.payload;
  },
  purge: (state: AdminModuleState) => ({ ...AdminInitialState }),
};

const UserReducers = { actions: Reducers, saga: AsyncReducers };
export default UserReducers;
