import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tag,
  useColorModeValue,
} from "@chakra-ui/react";

import PropTypes from "prop-types";

import routes from "routes";
import { useBehave } from "behave-react";
import { AdminAction, AdminSelect } from "store/modules/admin";
import { SessionSelect } from "store/modules/session";
import { SidebarResponsive } from "../Sidebar/Sidebar";
import { SearchBar } from "./SearchBar/SearchBar";
import { useTranslation } from "react-i18next";
import { AdminColorSchema } from "theme/ColorsSchema";
import ButtonPermission from "../ButtonPermission";
import usePermissions from "hooks/usePermissions";
import { NavLink } from "react-router-dom";

export default function HeaderLinks() {
  const menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { t } = useTranslation();
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const { permit, permissions } = usePermissions();
  const showManageProfile = permit("updateAdmin");
  const { i18n } = useTranslation();
  const { dispatch, select } = useBehave();

  const user = select(SessionSelect.user);
  const profile = select(AdminSelect.admin(user?.uid ?? ""));
  const region = select(AdminSelect.region(profile?.region ?? ""));

  const handleLogout = () => dispatch(AdminAction.logout());

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />
      <SearchBar change={() => {}} />
      <Text fontWeight="bold" me="24px" ms="24px" whiteSpace="nowrap">
        {profile?.first_name} {profile?.last_name}
      </Text>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={user?.email ?? ""}
            bg="#11047A"
            src={profile?.profile_pic}
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="16px"
              pe="20px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; {t("navbarLinksAdmin.text")} {user?.email}
            </Text>
          </Flex>
          <NavLink to={`/${i18n.language}/admin/${profile?._id}`}>
            <MenuItem padding="0px">
              <Flex w="100%" mb="0px">
                {showManageProfile && (
                  <Text
                    ps="20px"
                    pt="16px"
                    pb="16px"
                    pe="20px"
                    w="100%"
                    alignItems="center"
                    fontSize="sm"
                    fontWeight="700"
                    color={textColor}
                    borderBottom="1px solid"
                    borderColor={borderColor}
                  >
                    ⚙️&nbsp; Manage profile
                  </Text>
                )}
              </Flex>
            </MenuItem>
          </NavLink>
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="16px"
              pe="20px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              🔒&nbsp; Permission level:
              <Tag
                ml="5px"
                textTransform="capitalize"
                fontWeight="700"
                colorScheme={AdminColorSchema[profile?.permission_level]}
              >
                {profile?.permission_level}
              </Tag>
            </Text>
          </Flex>
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="16px"
              pe="20px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
              ml="5px"
            >
              Region: &nbsp;{region?.name}
            </Text>
          </Flex>

          <Flex flexDirection="column" p="10px">
            <MenuItem
              onClick={handleLogout}
              onKeyDown={handleLogout}
              color="red.400"
              fontWeight="medium"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">{t("general.button.logout")}</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
