import { useEffect } from "react";
import { useBehave, useTask } from "behave-react";
import Card from "../../components/layout/Card";
import ColumnsPartner from "./TableColumns";
import { UserAction, UserSelect } from "store/modules/users";
import TableComponent from "components/layout/TableComponent";
import { useTranslation } from "react-i18next";
import withPermissions from "components/hoc/withPermissions";

const PartnersRequestsPage = () => {
  const { select, dispatch } = useBehave();
  const loading = useTask("fetching", "partner-req");
  const partners = select(UserSelect.partnerRequests);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!partners?.length) handleRefresh();
  }, []);

  const handleQuery = (i: string, data: PartnerRequest) => {
    return data.syntetic_user.toLowerCase().includes(i);
  };
  const handleRefresh = () => dispatch(UserAction.fetchPartnersRequests());

  return (
    <Card direction="column" w="100%" px="0px">
      <TableComponent
        columns={ColumnsPartner({
          t,
          locale: i18n.language,
        })}
        data={partners}
        isLoading={loading.isLoading}
        query={handleQuery}
        queryPlaceholder={t("partnerRequest.table.queryPlaceholder")}
        pageSiblings={8}
        pageSize={6}
        onRefresh={handleRefresh}
      />
    </Card>
  );
};

export default withPermissions("getPartnersRequests", PartnersRequestsPage);
