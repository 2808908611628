import React, { ReactNode } from "react";
import { Box, Button, Flex, InputProps, Text, ThemingProps } from "@chakra-ui/react";
import { FieldValues, useForm } from "react-hook-form";
import access, { Dotted } from "../../../../utils/DotNotation";
import FormElement from "./FormElement";
import RenderElement from "./RenderElement";
import { CardConfig } from "../Types/type";
import _ from "lodash";

const SingleCard = <T extends FieldValues>({
  _errors,
  _register,
  _setValue,
  _control,
  _getValues,
  intialTransforms,
  ...card
}: CardConfig<T>) => {
  const applyTransforms = (data?: T) => {
    if (!data) return undefined;

    const transformed = _.cloneDeep(data);

    intialTransforms?.forEach((element) => {
      const el = access(transformed, element[0]);
      access(transformed, element[0], true, element[1](el));
    });

    return transformed;
  };
  const _handleSubmit = (data?: T) => {
    if (!data) return undefined;
    const transformed = { ...data };

    intialTransforms?.forEach((element) => {
      const el = access(transformed, element[0]);
      access(transformed, element[0], true, element[2](el));
    });

    card.submit?.onSubmit?.(transformed);
  };

  const {
    register: __register,
    handleSubmit,
    setValue: __setValue,
    control: __control,
    getValues: __getValues,
    formState: { errors: __errors },
  } = useForm<T>({
    mode: "onSubmit",
    defaultValues: applyTransforms(card.initialValues) as any,
  });

  const register: typeof __register = card.submit ? __register : _register;
  const setValue: typeof __setValue = card.submit ? __setValue : _setValue;
  const control: typeof __control = card.submit ? __control : _control;
  const getValues: typeof __getValues = card.submit ? __getValues : _getValues;

  const errors = card.submit ? __errors : _errors;

  const CardWrapper = ({ children, _key }: any) => {
    if (card.cardRender) return <card.cardRender _key={_key}>{children}</card.cardRender>;
    else
      return (
        <Flex direction="column" key={_key} gap="8px">
          {children}
        </Flex>
      );
  };

  const FormWrapper = ({ children, _key }: { children: any; _key: any }) => {
    if (card.submit)
      return (
        <form
          key={_key}
          style={{ width: "100%" }}
          autoComplete="off"
          onSubmit={card.submit?.onSubmit ? handleSubmit(_handleSubmit as any) : undefined}
        >
          <Flex direction="column" gap="24px" alignItems="end">
            {children}
          </Flex>
        </form>
      );
    else return children;
  };

  return (
    <CardWrapper _key={card._key}>
      <FormWrapper _key={card._key}>
        <Flex w="100%" alignItems="center" gap="24px">
          <Flex direction="column" flex="1">
            <Text fontSize="24px" fontWeight="700">
              {card.title}
            </Text>
            {card.subtitle}
          </Flex>
          {card.rightAction}
        </Flex>
        {!card.collapsed && !!card.elements.length && (
          <Flex gap="12px" direction="column" w="100%">
            {card.elements.map((a) => (
              <RenderElement
                data={a}
                getValues={getValues}
                errors={errors}
                setValue={setValue}
                register={register}
                control={control}
              />
            ))}
          </Flex>
        )}
        {card.submit?.action && (
          <Button
            type="submit"
            float="right"
            variant="outline"
            colorScheme={card.submit.color}
            isLoading={card.submit.loading}
          >
            {card.submit.action}
          </Button>
        )}
      </FormWrapper>
    </CardWrapper>
  );
};

export default SingleCard;
