import { behaveStore, poolReducer } from "behave-react";
import { combineReducers } from "redux";
import SessionModule, { SessionSaga } from "./modules/session";
import AdminModule, { AdminSaga } from "./modules/admin";
import UserModule, { UserSaga } from "./modules/users";
import ShopModule, { ShopSaga } from "./modules/shop";

const rootReducer = combineReducers({
  ...poolReducer(),
  session: SessionModule,
  admin: AdminModule,
  users: UserModule,
  shop: ShopModule,
});

const { store, persistor } = behaveStore({
  persistKey: "accessiway-admin-app",
  reducers: rootReducer,
  sagas: [SessionSaga, AdminSaga, UserSaga, ShopSaga],
});

export type RootState = ReturnType<typeof rootReducer>;
const Behave = { store, persistor };
export default Behave;
