import { Column } from "components/layout/TableComponent";
import { Text, Tag, Button } from "@chakra-ui/react";
import { LICENSES_STATUS_COlORS } from "../../utils/DataMaps";
import dataFormatter from "../../utils/DateFormatter";
import PopoverEdit from "./components/PopoverEdit";
import PopoverDeactivated from "./components/PopoverDeactivated";

interface RenewalsColumnsProps {
  deactivated?: boolean;
  t: (t: string) => string;
}

const RenewalsColumns: (props: RenewalsColumnsProps) => Column<DomainSubscription>[] = ({
  t,
  deactivated,
}) => [
  {
    title: t("general.tableColumn.title.domain"),
    id: "domain",
    width: "100%",
    render: (value, data) => <Text color="blue.500">{data.domain}</Text>,
  },
  {
    title: t("general.tableColumn.title.date"),
    id: "plan",
    width: "100%",
    render: (value, data) => <Text>{dataFormatter(data.plan.renewDate, "day_month_year")}</Text>,
  },
  {
    title: t("renewals.tableColumn.title.price"),
    id: "plan",
    width: "100%",
    render: (value, data) => <Text fontWeight="bold">{(data.plan.price / 100).toFixed(2)} €</Text>,
  },
  {
    title: t("renewals.tableColumn.title.status"),
    id: "plan",
    width: "100%",
    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        variant="subtle"
        color={LICENSES_STATUS_COlORS[data.plan.status].color}
        bg={LICENSES_STATUS_COlORS[data.plan.status].bg}
      >
        {data.plan.status}
      </Tag>
    ),
  },
  {
    title: t("general.tableColumn.title.action"),
    id: "plan",
    width: "100%",
    render: (value, data) => {
      return deactivated ? (
        <div>
          <PopoverDeactivated />
        </div>
      ) : (
        <div>
          <PopoverEdit />
        </div>
      );
    },
  },
];

export default RenewalsColumns;
