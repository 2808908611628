export const AdminColorSchema: { [key in PermissionLevel]?: string } = {
  admin: "green",
  advanced_sales: "red",
  customer_service: "cyan",
  executive: "blue",
  account_executive: "yellow",
};

export const OrderStatusSchema: { [key in Order["payment_status"]]?: string } = {
  COMPLETED: "green",
  PENDING: "yellow",
  REJECTED: "red",
  PAY_NOW: "blue",
};
