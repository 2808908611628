import React from "react";
import { Text } from "@chakra-ui/react";
interface SectionTitleProps {
  text: string;
}
const SectionTitle = ({ text }: SectionTitleProps) => {
  return (
    <Text
      fontWeight="600"
      pt="20px"
      pb="10px"
      fontSize="20px"
      borderBottom="2px solid"
      borderColor="whitesmoke"
      width="100%"
    >
      {text}
    </Text>
  );
};

export default SectionTitle;
