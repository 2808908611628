import moment from "moment";

const formatVariant = {
  month_day_year: "MM/DD/YY",
  day_month_year: "DD/MM/YY",
  year_month_day: "YY/MM/DD",
  month_name_day_year: "MMM DD, yyyy",
  month_day_hour: "MMM DD, hh:mm a",
};

const format = (date: number, formatType: keyof typeof formatVariant) => {
  return moment(date).format(formatVariant[formatType]);
};
export default format;
