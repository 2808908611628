import select from "utils/ArrayUtils";
import { RootState } from "../..";

export const coupons = (state: RootState) => state.shop.coupons;
export const transfers = (state: RootState) => state.shop.transfers;
export const renewals = (state: RootState) => state.shop.renewals;
export const domains = (_id: string) => (state: RootState) =>
  state.shop.domains?.filter((a) => a.user === _id) ?? [];
export const orders = (_id: string) => (state: RootState) =>
  state.shop.orders?.filter((a) => a.user === _id) ?? [];
export const order = (_id: string) => (state: RootState) =>
  select(state.shop.orders ?? [], "_id", _id).element as Order;
export const domain = (_id?: string) => (state: RootState) =>
  select(state.shop.domains ?? [], "_id", _id).element as DomainSubscription;
