const Regex = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  mobile: /^[0-9]{5,20}$/,
  country: /^[1-9]{1,4}$/,
  domain: /^([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}(.+)?$/,
  postal: /^\d{4,10}$/,
  password: /^(?=.*[a-z.*\d]).{6,}$/,
  couponCode: /^[A-Za-z0-9]{4,30}$/,
};

export default Regex;
