export interface ScriptConfig {
  trigger: {
    color: string;
    size: "small" | "medium" | "big";
    positionH: "left" | "right";
    positionV: "top" | "center" | "bottom";
    mobile: {
      size: "small" | "medium" | "big";
      positionH: "left" | "right";
      positionV: "top" | "center" | "bottom";
    };
  };
  interface: {
    color: string;
    positionH: "left" | "right";
    language: string;
  };
}

const InstallationScript = (config: ScriptConfig) => `<script>

(function(){
  var s = document.createElement('script'),
  e = !document.body ? document.querySelector('head') :
  document.body;
  s.src = 'https://eu.acsbapp.com/apps/app/dist/js/app.js';
  s.async = true;
  s.onload = function(){
    acsbJS.init({
      statementLink : '',
      footerHtml :  '<a  href="https://www.accessiway.com/" target="_blank">AccessiWay. The Web Accessibility Solution</a>',
      hideMobile : false,
      hideTrigger : false,
      language : '${config.interface.language}',
      position : '${config.interface.positionH}',
      leadColor : '${config.interface.color}',
      triggerColor : '${config.trigger.color}',
      triggerRadius : '50%',
      triggerPositionX : '${config.trigger.positionH}',
      triggerPositionY : '${config.trigger.positionV}',
      triggerIcon : 'people',
      triggerSize : '${config.trigger.size}',
      triggerOffsetX : 20,
      triggerOffsetY : 20,
      mobile : {
        triggerSize : '${config.trigger.mobile.size}',
        triggerPositionX : '${config.trigger.mobile.positionH}',
        triggerPositionY : '${config.trigger.mobile.positionV}',
        triggerOffsetX : 10,
        triggerOffsetY : 10,
        triggerRadius : '50%'
      }
   });
  };
  e.appendChild(s);
}());

</script>
`;
export default InstallationScript;
