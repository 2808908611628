import { Text, Tag, Button } from "@chakra-ui/react";
import { Column } from "components/layout/TableComponent";
import { USER_TYPE_COLORS, DISCOUNT_TYPE_COLORS } from "../../../utils/DataMaps";
import { CgTrashEmpty } from "react-icons/cg";
import ButtonPermission from "components/layout/ButtonPermission";

const DiscountColumns: (onDelete: (id) => void, loading: boolean) => Column<PartnerDiscount>[] = (
  od,
  loading
) => [
  {
    title: "Discount",
    id: "discount",
    width: "20%",

    render: (value, data) => (
      <Text fontWeight="bold">
        {data.discount.type === "NET"
          ? "€ " + (data.discount.amount / 100).toFixed(2)
          : "% " + (data.discount.amount * 100).toFixed(2)}
      </Text>
    ),
  },
  {
    title: "Description",
    id: "description",
    width: "70%",
    render: (value, data) => <Text>{data.description ?? "No description"}</Text>,
  },
  {
    title: "Plan",
    id: "filter",

    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        opacity="0.6"
        whiteSpace="nowrap"
        border="1px solid"
        borderColor={USER_TYPE_COLORS[data.filter.to_plan]}
        color={USER_TYPE_COLORS[data.filter.to_plan]}
      >
        {data.filter.to_plan}
      </Tag>
    ),
  },
  {
    title: "Type",
    id: "filter",

    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        opacity="0.6"
        whiteSpace="nowrap"
        border="1px solid"
        borderColor={USER_TYPE_COLORS[data.filter.to_type]}
        color={USER_TYPE_COLORS[data.filter.to_type]}
      >
        {data.filter.to_type}
      </Tag>
    ),
  },
  {
    title: "Discount type",
    id: "discount",
    width: "20%",
    minWidth: "215px",
    render: (value, data) => (
      <Tag
        fontSize="14px"
        fontWeight="500"
        opacity="0.6"
        whiteSpace="nowrap"
        border="1px solid"
        background={`${DISCOUNT_TYPE_COLORS[data.discount.type]}30`}
        color={DISCOUNT_TYPE_COLORS[data.discount.type]}
      >
        {data.discount.type}
      </Tag>
    ),
  },
  {
    title: "Edit",
    id: "_id",

    render: (value) => {
      return (
        <ButtonPermission
          action="deletePartnerDiscount"
          variant="link"
          size="sm"
          whiteSpace="nowrap"
          colorScheme="red"
          isLoading={loading}
          leftIcon={<CgTrashEmpty />}
          onClick={() => od(value)}
        />
      );
    },
  },
];

export default DiscountColumns;
