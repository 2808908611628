import { useToast, UseToastOptions } from "@chakra-ui/react";
import { useTaskCallback } from "behave-react";
import { LoaderHelperApi } from "behave-react/dist/types/types/types";
import { useTranslation } from "react-i18next";

const useToastNotification = (
  task: LoaderHelperApi<any>,
  config?: {
    success?: UseToastOptions;
    error?: UseToastOptions;
  }
) => {
  const toast = useToast();
  const { t } = useTranslation();
  useTaskCallback(task, {
    success: () =>
      config?.success &&
      toast({
        ...config?.success,
        position: "bottom-right",
        status: "success",
      }),
    error: (e) =>
      toast({
        ...config?.error,
        title: t("general.errors.title"),
        description: e,
        position: "bottom-right",
        status: "error",
      }),
  });
};

export default useToastNotification;
