import { firebaseCall } from "../utils/firebase.api.utils";

// PARTNER

export const getUserPartner: ApiCallback<SimpleId, UserPartner> = (payload) => {
  return firebaseCall("admin-getUserPartner")(payload);
};

export const editPartnerReferral: ApiCallback<EditPartnerReferral, boolean> = (payload) => {
  return firebaseCall("admin-editPartnerReferral")(payload);
};

export const addPartnerCredits: ApiCallback<AddPartnerCredits, boolean> = (payload) => {
  return firebaseCall("admin-addPartnerCredits")(payload);
};

export const createPartnerDiscount: ApiCallback<CreatePartnerDiscount, PartnerDiscount> = (
  payload
) => {
  return firebaseCall("admin-createPartnerDiscount")(payload);
};

export const deletePartnerDiscount: ApiCallback<SimpleIdWithUser, boolean> = (payload) => {
  return firebaseCall("admin-deletePartnerDiscount")(payload);
};

export const upgradeToPartner: ApiCallback<SimpleId, UserPartner> = (payload) => {
  return firebaseCall("admin-upgradeToPartner")(payload);
};

export const deletePartner: ApiCallback<SimpleId, void> = (payload) => {
  return firebaseCall("admin-deletePartner")(payload);
};

export const getReferralHistory: ApiCallback<SimpleId, LinkReferral> = (payload) => {
  return firebaseCall("admin-getReferralHistory")(payload);
};

export const deleteReferralHistory: ApiCallback<SimpleId, void> = (payload) => {
  return firebaseCall("admin-deleteReferralHistory")(payload);
};

export const updateReferralHistory: ApiCallback<UpdateReferralHistory, void> = (payload) => {
  return firebaseCall("admin-updateReferralHistory")(payload);
};

// REQUESTS

export const getAllRequests: ApiCallback<void, PartnerRequest[]> = (payload) => {
  return firebaseCall("admin-getPartnersRequests")(payload);
};

export const acceptRequest: ApiCallback<SimpleId, boolean> = (payload) => {
  return firebaseCall("admin-acceptPartnerRequest")(payload);
};

export const refuseRequest: ApiCallback<SimpleId, boolean> = (payload) => {
  return firebaseCall("admin-refusePartnerRequest")(payload);
};
