import { useState } from "react";
import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import SelectTeam from "components/layout/SelectTeam";
import AvatarEdit from "components/layout/AvatarPopoverEdit";
import FormCard from "components/layout/FormCard";
import Regex from "utils/Regex";
import { PERMISSIONS } from "utils/DataMaps";

import { useBehave, useTask, useTaskCallback } from "behave-react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import useToastNotification from "hooks/useToastNotification";
import withPermissions from "components/hoc/withPermissions";
import ButtonPermission from "components/layout/ButtonPermission";
import { AdminAction, AdminSelect } from "store/modules/admin";
import FormBuilder from "components/layout/FormBuilder";

interface CreateUpdateAdminProps {
  isEdit?: boolean;
}

const CreateUpdateAdmin = ({ isEdit }: CreateUpdateAdminProps) => {
  const { dispatch, select } = useBehave();
  const { t, i18n } = useTranslation();
  const { id: _id } = useParams<{ id: string }>();
  const id = _id ?? "";
  const admin = select(AdminSelect.admin(id));

  const { isOpen, onClose, onOpen } = useDisclosure();

  const navigate = useNavigate();
  const loading = useTask(isEdit ? "updating" : "creating", isEdit ? id : "admins");
  const loadingDelete = useTask("deleting", id);
  const [profilePic, setProfilePic] = useState(admin?.profile_pic);
  const [region, setRegion] = useState(admin?.region ?? "AW_IT");

  useTaskCallback(loading, {
    success: isEdit ? undefined : () => navigate(`/${i18n.language}/admin`),
  });
  useTaskCallback(loadingDelete, {
    success: () => navigate(`/${i18n.language}/admin`),
  });
  useToastNotification(loading, {
    success: isEdit
      ? {
          title: t("general.notification.success.title"),
          description: t("admin.notification.edited.description"),
        }
      : undefined,
  });
  useToastNotification(loadingDelete, {
    success: {
      title: t("general.notification.success.title"),
      description: t("admin.notification.removed.description"),
    },
  });

  const handleNewUser = (variables: CreateAdminPayload) => {
    const payload = { ...variables, region, profile_pic: profilePic };
    if (!profilePic?.length) delete payload.profile_pic;
    dispatch(AdminAction.createAdmin(payload));
  };
  const handleDelete = () => {
    dispatch(AdminAction.deleteAdmin(id));
  };

  const handleUpdateUser = (variables: CreateAdminPayload) => {
    const payload = {
      ...variables,
      region,
      profile_pic: profilePic,
      _id: id,
    } as any;
    if (!profilePic?.length) delete payload.profile_pic;
    delete payload.password;
    dispatch(AdminAction.updateAdmin(payload));
  };

  return (
    <Flex direction="column">
      <FormBuilder
        empty={!admin}
        initialValues={isEdit ? admin : { region: "AW_IT" }}
        editMode={isEdit}
        fallback={{
          message: (
            <>
              {t("adminCreate.formFallback.message_1")} <b>{id}</b>
              {t("adminCreate.formFallback.message_2")}
            </>
          ),
          action: (
            <NavLink to="admin">
              <Button>{t("general.button.goBack")}</Button>
            </NavLink>
          ),
        }}
        cardRender={FormCard}
        cards={[
          {
            title: t("adminCreate.form.title"),
            subtitle: t("adminCreate.form.subtitle"),
            rightAction: (
              <AvatarEdit
                zIndex="99"
                onChangeUrl={(url) => setProfilePic(url)}
                src={profilePic}
                isOpen={isOpen}
                onClose={onClose}
                onOpen={onOpen}
              />
            ),
            elements: [
              [
                {
                  id: "first_name",
                  type: "text",
                  label: t("general.form.label.name"),
                  placeholder: t("general.form.placeholder.name"),
                  required: true,
                },
                {
                  id: "last_name",
                  type: "text",
                  label: t("general.form.label.surname"),
                  placeholder: t("general.form.placeholder.surname"),
                  required: true,
                },
              ],
              [
                {
                  id: "email",
                  type: "email",
                  label: t("general.form.label.email"),
                  placeholder: t("general.form.placeholder.email"),
                  required: true,
                  disabled: isEdit,
                },

                {
                  id: "password",
                  type: "password",
                  label: t("general.form.label.password"),
                  placeholder: t("general.form.placeholder.password"),
                  pattern: Regex.password,
                  patternError: t("general.patternError.password"),
                  required: true,
                  hidden: isEdit,
                },
              ],
              {
                id: "role",
                type: "text",
                label: t("general.form.label.workPosition"),
                placeholder: t("general.form.placeholder.workPosition"),
              },
              <SelectTeam disabled={isEdit} selected={region} onSelect={setRegion} />,
              {
                id: "permission_level",
                type: "select",
                label: t("general.form.label.permissionLevel"),
                options: PERMISSIONS,
              },
            ],
          },
          {
            hide: !isEdit,
            title: t("adminCreate.form.delete.title"),
            subtitle: t("adminCreate.form.delete.subtitle"),
            rightAction: (
              <ButtonPermission
                action="deleteAdmin"
                colorScheme="red"
                onClick={handleDelete}
                isLoading={loadingDelete.isLoading}
                needConfirmation
              >
                {t("general.button.delete")}
              </ButtonPermission>
            ),
            elements: [],
          },
        ]}
        submit={{
          action: isEdit ? t("general.button.update") : t("general.button.create"),
          title: "Confirmation",
          color: isEdit ? "brand" : "green",
          loading: loading.isLoading,
          subtitle: isEdit
            ? t("adminCreate.form.update.subtitle")
            : t("adminCreate.form.create.subtitle"),
          onSubmit: isEdit ? handleUpdateUser : handleNewUser,
        }}
      />
    </Flex>
  );
};

export default withPermissions("createAdmin", CreateUpdateAdmin);
