import React from "react";
import { Button, Flex, HStack } from "@chakra-ui/react";
import { reduceEachTrailingCommentRange } from "typescript";

interface PaginationProps {
  onPageChange: (n: number) => void;
  pages: number;
  siblings?: number;
  current: number;
}

const Pagination = (props: PaginationProps) => {
  const { onPageChange, pages, siblings, current } = props;

  if (pages < 2) return null;

  const onNext = () => {
    if (current + 1 < pages) {
      onPageChange(current + 1);
    }
  };

  const onPrevious = () => {
    if (current === 0) return;
    onPageChange(current - 1);
  };

  const range = () => {
    const maxSiblings = Math.min(pages, siblings ?? pages);
    let start = 0;
    let end = pages;
    if (current < maxSiblings / 2) {
      start = 0;
      end = Math.min(maxSiblings, pages);
    } else if (current > pages - maxSiblings / 2) {
      start = pages - maxSiblings;
      end = pages;
    } else {
      start = Math.floor(current - maxSiblings / 2);
      end = Math.floor(current + maxSiblings / 2);
    }
    const pagination: number[] = [];
    for (let x = start; x < end; x++) pagination.push(x);
    return pagination;
  };

  return (
    <Flex w="100%" justifyContent="end" pb="12px" pr="24px">
      <Button onClick={onPrevious} size="sm" variant="ghost">
        {"<"}
      </Button>
      {range().map((x) => (
        <Button
          size="sm"
          key={x}
          variant={x === current ? "outline" : "link"}
          onClick={() => onPageChange(x)}
        >
          {x + 1}
        </Button>
      ))}
      <Button onClick={onNext} size="sm" variant="ghost">
        {">"}
      </Button>
    </Flex>
  );
};

export default Pagination;
