import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseCall =
  <T>(name: string) =>
  (payload: T) => {
    const functions = getFunctions();
    return new Promise<any>((resolve, reject) => {
      httpsCallable<T, any>(
        functions,
        name
      )(payload)
        .then((response) => {
          response.data.status === "SUCCESFULL" ? resolve(response) : reject(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

export { firebaseCall };
