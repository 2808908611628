import React, { useState, useRef } from "react";
import { Flex, Box, Button, Text, Icon, useToast } from "@chakra-ui/react";
import Card from "components/layout/Card";
import { TbDiscount2 } from "react-icons/tb";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import { UserAction, UserSelect } from "store/modules/users";
import useToastNotification from "hooks/useToastNotification";
import InputField from "components/layout/FormBuilder/Fields/InputField";
import ButtonPermission from "components/layout/ButtonPermission";
import { MdContentCopy } from "react-icons/md";
import { useTranslation } from "react-i18next";

const CreditsBar = (partner: UserPartner) => {
  const { dispatch } = useBehave();
  const [toggle, setToggle] = useState(true);
  const [credits, setCredits] = useState(0);
  const [referral, setReferral] = useState(partner.link_referral.url_ref);
  const { t } = useTranslation();

  const [referralLink, setReferralLink] = useState(
    `https://app.accesiway.com?ref=${partner.link_referral.url_ref}`
  );
  const toast = useToast();

  const loadingCredits = useTask("updating", `${partner.user_id}credits`);
  useToastNotification(loadingCredits, { success: { description: "Credits added succesfully" } });
  useTaskCallback(loadingCredits, { success: () => setToggle(true) });

  const handleAddCredits = () => {
    dispatch(UserAction.addPartnerCredits({ credits: credits * 100, user: partner.user_id }));
  };

  const loadingReferral = useTask("updating", `${partner.user_id}referral`);
  useToastNotification(loadingReferral, {
    success: { description: "Referral updated succesfully" },
  });

  const handleUpdateReferral = () => {
    dispatch(UserAction.editPartnerReferral({ new_code: referral, user: partner.user_id }));
    setReferralLink(`https://app.accesiway.com?ref=${referral}`);
  };

  return (
    <Flex gap="20px">
      <Card direction="column" w="100%" px="25px">
        <Box>
          <Text fontSize="20px" fontWeight="bold" mb="10px">
            {t("partnerPage.creditsBar.title.credits")}
          </Text>
        </Box>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex gap="10px" alignItems="center" hidden={!toggle}>
            <Icon as={TbDiscount2} h="10" w="10" color="blue.500" />
            <Text fontSize="20px" color="blue.500" fontWeight="bold">
              {((partner?.credits ?? 0) / 100).toFixed(2)}
            </Text>
          </Flex>
          <Box alignItems="center" hidden={toggle} w="100%">
            <InputField
              id=""
              w="100%"
              type="number"
              onChange={(v) => setCredits(parseInt(v.target.value))}
              placeholder="0"
            />
          </Box>
          <ButtonPermission
            action="addPartnerCredits"
            ml="10px"
            variant="outline"
            onClick={() => setToggle((v) => !v)}
          >
            {toggle ? t("general.action.recharge") : t("general.action.cancel")}
          </ButtonPermission>
          {!toggle && (
            <ButtonPermission
              ml="10px"
              variant="brand"
              type="submit"
              action="addPartnerCredits"
              onClick={handleAddCredits}
              isLoading={loadingCredits.isLoading}
            >
              {t("partnerPage.creditsBar.button.addCredits")}
            </ButtonPermission>
          )}
        </Flex>
      </Card>
      <Card direction="column" w="100%" px="25px">
        <Box>
          <Text fontSize="20px" fontWeight="bold" mb="10px">
            {t("partnerPage.creditsBar.title.referral")}
          </Text>
        </Box>
        <Flex gap="10px" alignItems="center">
          <InputField
            onChange={(t) => setReferral(t.target.value)}
            type="url"
            w="100%"
            id=""
            leftAddon="https://app.accesiway.com?ref="
            placeholder={partner?.link_referral?.url_ref}
          />
          <ButtonPermission
            variant="outline"
            action="editPartnerReferral"
            onClick={handleUpdateReferral}
            isLoading={loadingReferral.isLoading}
          >
            {t("partnerPage.creditsBar.button.editReferral")}
          </ButtonPermission>
          <Button
            variant="outline"
            alignItems="center"
            onClick={() =>
              navigator.clipboard.writeText(referralLink).then(() =>
                toast({
                  title: t("general.notification.success.titleClipboard"),
                  status: "success",
                  position: "bottom-right",
                  duration: 9000,
                  isClosable: true,
                })
              )
            }
          >
            <Icon
              as={MdContentCopy}
              w="5"
              h="5"
              _hover={{
                color: "#136ef8",
                cursor: "pointer",
              }}
            />
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default CreditsBar;
