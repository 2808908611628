import React, { ReactNode, useState } from "react";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputProps,
  InputRightElement,
  SpacerProps,
  Text,
  ThemingProps,
  useColorModeValue,
} from "@chakra-ui/react";

import access from "../../../../utils/DotNotation";

interface InputFieldProps extends InputProps {
  id: string;
  label?: string;
  disabled?: boolean;
  errors?: any;
  patternError?: string;
  formRef?: any;
  extra?: string;
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
}

export default function InputField({
  id,
  label,
  placeholder,
  type,
  leftAddon,
  rightAddon,
  variant,
  formRef,
  errors,
  disabled,
  extra,
  ...rest
}: InputFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Flex direction="column" w="100%">
      <FormControl isInvalid={access(errors, id)} isDisabled={disabled}>
        {label && (
          <FormLabel
            display="flex"
            ms="10px"
            htmlFor={id}
            fontSize="sm"
            fontWeight="bold"
            _hover={{ cursor: "pointer" }}
          >
            {label}
            <Text fontSize="sm" fontWeight="400" ms="8px">
              {extra}
            </Text>
          </FormLabel>
        )}
        <InputGroup>
          {leftAddon && <InputLeftAddon pointerEvents="none" children={leftAddon} />}
          <Input
            type={type === "password" ? (!showPassword ? "password" : "text") : type}
            id={id}
            autoComplete="off"
            fontWeight="500"
            disabled={disabled}
            variant={variant ?? "main"}
            placeholder={placeholder}
            borderColor={access(errors, id) ? "red.400" : undefined}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            {...formRef}
            {...rest}
          />
          {type === "password" ? (
            <InputRightElement width="4.5rem" borderRadius="16px">
              <Button
                h="1.75rem"
                size="sm"
                onClick={() => setShowPassword((s) => !s)}
                borderRadius="10px"
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          ) : (
            rightAddon && <InputRightElement pointerEvents="none" children={rightAddon} />
          )}
        </InputGroup>
        <FormErrorMessage fontSize="smaller">
          {access(errors, id) &&
            (access(errors, id).type === "pattern"
              ? rest.patternError
              : access(errors, id).message?.lenght > 0
              ? access(errors, id).message
              : "*  This field is required")}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
}
