import React from "react";
import { Avatar } from "@chakra-ui/react";
import { useBehave } from "behave-react";
import { AdminSelect } from "store/modules/admin";

interface AvatarRegionComponentProps {
  region: string;
  size?: string;
}
const AvatarRegionComponent = ({ region, size }: AvatarRegionComponentProps) => {
  const { select } = useBehave();
  const _region = select(AdminSelect.region(region));
  const profilePic = _region?.logo ?? "";

  return <Avatar src={profilePic} name={region} size={size} />;
};

export default AvatarRegionComponent;
