import { firebaseCall } from "../utils/firebase.api.utils";

export const isAdmin: ApiCallback<SimpleId, void> = (payload) => {
  return firebaseCall("admin-isAdmin")(payload);
};
// USER

export const getUser: ApiCallback<SimpleId, User> = (payload) => {
  return firebaseCall("admin-getUser")(payload);
};
export const getAllUsers: ApiCallback<void, User[]> = (payload) => {
  return firebaseCall("admin-getAllUsers")(payload);
};
export const createUser: ApiCallback<CreateUser, User> = (payload) => {
  return firebaseCall("admin-createUser")(payload);
};
export const editUser: ApiCallback<EditUserPaylad, boolean> = (payload) => {
  return firebaseCall("admin-editUser")(payload);
};
export const editBillingInfo: ApiCallback<EditBillingPayload, boolean> = (payload) => {
  return firebaseCall("admin-editBillingInfo")(payload);
};
export const editUserHubspotId: ApiCallback<EditUserHubspotId, boolean> = (payload) => {
  return firebaseCall("admin-editUserHubspotId")(payload);
};
export const disableUser: ApiCallback<SimpleId, boolean> = (payload) => {
  return firebaseCall("admin-disableUser")(payload);
};
export const activateUser: ApiCallback<SimpleId, boolean> = (payload) => {
  return firebaseCall("admin-activateUser")(payload);
};
export const forceDeleteUser: ApiCallback<DeleteUser, boolean> = (payload) => {
  return firebaseCall("admin-forceDeleteUser")(payload);
};

// ORDERS

export const getOrder: ApiCallback<SimpleId, Order> = (payload) => {
  return firebaseCall("admin-getOrder")(payload);
};
export const getUserOrders: ApiCallback<SimpleId, Order[]> = (payload) => {
  return firebaseCall("admin-getOrdersForUser")(payload);
};
export const updateOrderForUser: ApiCallback<UpdateOrder, boolean> = (payload) => {
  return firebaseCall("admin-updateOrderForUser")(payload);
};

// TRANSFERS

export const getTransferRequests: ApiCallback<void, TransferRequest[]> = (payload) => {
  return firebaseCall("admin-getTransferRequests")(payload);
};
export const acceptTransferRequest: ApiCallback<SimpleId, void> = (payload) => {
  return firebaseCall("admin-acceptTransferRequest")(payload);
};
export const rejectTransferRequest: ApiCallback<SimpleId, void> = (payload) => {
  return firebaseCall("admin-rejectTransferRequest")(payload);
};
export const deleteTransferRequest: ApiCallback<SimpleId, void> = (payload) => {
  return firebaseCall("admin-deleteTransferRequest")(payload);
};

// OVERVIEW

export const getOverview: ApiCallback<void, Overview> = (payload) => {
  return firebaseCall("admin-getOverview")(payload);
};

// COMMUINICATION

export const getCommunicationsForUser: ApiCallback<SimpleId, Communication[]> = (payload) => {
  return firebaseCall("admin-getCommunicationsForUser")(payload);
};
export const sendCommunicationToUser: ApiCallback<CreateCommunication, Communication> = (
  payload
) => {
  return firebaseCall("admin-sendCommunicationToId")(payload);
};
export const deleteCommunicationForUser: ApiCallback<SimpleIdWithUser, void> = (payload) => {
  return firebaseCall("admin-deleteCommunicationForUser")(payload);
};
export const markCommunicationAsRead: ApiCallback<SimpleId, void> = (payload) => {
  return firebaseCall("admin-markCommunicationAsRead")(payload);
};

// HUBSPOT

export const getAllHubspotOpportunities: ApiCallback<void, HSCompany[]> = (payload) => {
  return firebaseCall("admin-getAllHubspotOpportunities")(payload);
};
export const getHubspotRelatedCompany: ApiCallback<SimpleId, HSContact[]> = (payload) => {
  return firebaseCall("admin-getHubspotRelatedCompany")(payload);
};
export const getHubspotRelatedDeals: ApiCallback<SimpleId, any> = (payload) => {
  return firebaseCall("admin-getHubspotRelatedDeals")(payload);
};
