import i18n, { use } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";

import English from "../assets/locales/en.json";
import Italian from "../assets/locales/it.json";

const allowedLanguages = ["en", "it"];

use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translations: English },
      it: { translations: Italian },
    },
    fallbackLng: allowedLanguages,
    debug: false,

    ns: ["translations"],
    defaultNS: "translations",
    supportedLngs: allowedLanguages,
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
    },
  });

export default i18n;
