import { NavLink, useLocation } from "react-router-dom";
import { ListItem, Icon, Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

import { NavRoute } from "routes";
import { useTranslation } from "react-i18next";
import usePermissions from "hooks/usePermissions";
import { CgLock } from "react-icons/cg";

interface SidebarLinksProps {
  routes: NavRoute[];
}

export function SidebarLinks({ routes }: SidebarLinksProps) {
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  const activeIcon = useColorModeValue("brand.500", "white");
  const location = useLocation();
  const { i18n } = useTranslation();

  const { permit, loading } = usePermissions();

  const activeRoute = (routeName: string) => {
    return location?.pathname.includes(routeName);
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: NavRoute[]) => {
    return routes.map((route, key) => {
      const locked = route.permission ? !permit(route.permission) : false;
      if (route.hideFromSidebar) return null;
      else {
        return (
          <NavLink to={"/" + i18n.language + route.path} key={key}>
            {route.icon ? (
              <Flex
                align="center"
                justifyContent="space-between"
                w="100%"
                ps="17px"
                mb="0px"
                opacity={locked ? 0.5 : 1}
              >
                <HStack mb="6px" spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}>
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Box
                      color={activeRoute(route.path.toLowerCase()) ? activeIcon : inactiveColor}
                      me="12px"
                      mt="6px"
                    >
                      {locked ? (
                        <Icon as={CgLock} width="20px" height="20px" color="inherit" />
                      ) : (
                        route.icon
                      )}
                    </Box>
                    <Text
                      me="auto"
                      color={activeRoute(route.path.toLowerCase()) ? activeIcon : inactiveColor}
                      fontWeight="500"
                    >
                      {route.name}
                    </Text>
                  </Flex>
                </HStack>
              </Flex>
            ) : (
              <ListItem>
                <Flex ps="34px" alignItems="center" mb="8px">
                  <Text
                    color={activeRoute(route.path.toLowerCase()) ? activeIcon : inactiveColor}
                    fontWeight="500"
                    fontSize="sm"
                  >
                    {route.name}
                  </Text>
                </Flex>
              </ListItem>
            )}
          </NavLink>
        );
      }
    });
  };

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
