import { InputProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { FieldValues } from "react-hook-form";
import InputField from "../Fields/InputField";
import SwitchField from "../Fields/SwitchField";
import TagsField from "../Fields/TagsField";
import TextAreaField from "../Fields/TextAreaField";
import SelectField from "../Fields/SelectField";
import ArrayField from "../Fields/ArrayField";
import { BuilderElement } from "../Types/type";
import access from "utils/DotNotation";


interface FormElementProps<T extends FieldValues> {
  data: BuilderElement<T>;
  errors: any;
  register?: any;
  setValue: any;
  getValues?: any;
  control?: any;
}

const FormElement = <T extends FieldValues>({
  data,
  errors,
  register,
  setValue,
  control,
  getValues,
}: FormElementProps<T>) => {
  if (!data) return null;
  if (React.isValidElement(data)) return data;

  switch (data.type) {
    case "text":
    case "email":
    case "date":
    case "phone":
    case "number":
    case "password":
      return (
        <InputField
          errors={errors}
          id={data.id as string}
          type={data.type}
          placeholder={data.placeholder}
          label={data.label}
          disabled={data.disabled}
          textTransform={data.textTransform}
          rightAddon={data.rightAddon}
          value={data.value}
          leftAddon={data.leftAddon}
          patternError={data.patternError}
          onChange={!data.id ? data.onChange : undefined}
          formRef={
            data.id
              ? register?.(data.id as any, {
                  required: data.required,
                  pattern: data.pattern,
                  validate: data.validate,
                  maxLength: data.maxLength,
                  minLength: data.minLength,
                  onChange: data.onChange,
                })
              : undefined
          }
        />
      );
    case "switch":
      return (
        <SwitchField
          id={data.id as string}
          label={data.label}
          description={data.description}
          onChange={!data.id ? data.onChange : undefined}
          isChecked={data.value}
          formRef={
            data.id
              ? register?.(data.id as any, {
                  required: data.required,
                  validate: data.validate,
                  onChange: data.onChange,
                })
              : undefined
          }
        />
      );
    case "textArea":
      return (
        <TextAreaField
          id={data.id as string}
          label={data.label}
          placeholder={data.placeholder}
          onChange={!data.id ? data.onChange : undefined}
          value={data.value}
          formRef={
            data.id
              ? register?.(data.id as any, {
                  required: data.required,
                  validate: data.validate,
                  onChange: data.onChange,
                })
              : undefined
          }
        />
      );
    case "tags":
      return (
        <TagsField
          id={data.id as string}
          label={data.label}
          onChange={!data.id ? data.onChange : (a) => setValue(data.id as any, a as any)}
          value={data.value ?? access(getValues?.(), data.id)}
        />
      );
    case "select":
      return (
        <SelectField
          errors={errors}
          id={data.id as string}
          placeholder={data.placeholder}
          label={data.label}
          disabled={data.disabled}
          options={data.options}
          onSelected={data.onSelected}
          value={data.value}
          onChange={!data.id ? data.onChange : undefined}
          formRef={
            data.id
              ? register?.(data.id as any, {
                  required: data.required,
                  pattern: data.pattern,
                  validate: data.validate,
                  onChange: data.onChange,
                })
              : undefined
          }
        />
      );
    case "array":
      return (
        <ArrayField
          control={control}
          elements={data.arrayElements ?? []}
          errors={errors}
          id={data.id as string}
          register={register}
          setValue={setValue}
          getValues={getValues}
          disabled={data.disabled}
          key={data.id}
        />
      );
    default:
      return null;
  }
};

export default FormElement;
