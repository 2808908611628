import { useTranslation } from "react-i18next";
import { useBehave, useTask } from "behave-react";
import { AdminAction, AdminSelect } from "store/modules/admin";
import { IoAdd } from "react-icons/io5";
import TableComponent from "components/layout/TableComponent";
import ButtonPermission from "components/layout/ButtonPermission";
import RegionColumns from "./TableColumns";
import { useEffect } from "react";
import Card from "components/layout/Card";
import withPermissions from "components/hoc/withPermissions";

const RegionTable = () => {
  const { t } = useTranslation();
  const loadingRegions = useTask("fetching", "regions");
  const { select, dispatch } = useBehave();
  const regions = select(AdminSelect.regions);
  const handleRefresh = () => dispatch(AdminAction.getRegions());



  return (
    <Card direction="column" w="100%" px="0px">
      <TableComponent
        title="Manage"
        columns={RegionColumns(t)}
        data={regions}
        onRefresh={handleRefresh}
        action2={
          <ButtonPermission
            type="submit"
            to="create-region"
            size="md"
            action="createRegion"
            leftIcon={<IoAdd />}
            variant="brand"
          >
            {t("region.button.add")}
          </ButtonPermission>
        }
        isLoading={loadingRegions.isLoading}
      />
    </Card>
  );
};
export default withPermissions("getRegions", RegionTable);
