import { firebaseCall } from "api/utils/firebase.api.utils";
import {
  confirmPasswordReset,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

export const getAuthUser: ApiCallback<void, UserRecord[]> = (payload) => {
  return firebaseCall("admin-getAuthUser")(payload);
};
export const deleteAuthUsers: ApiCallback<DeleteAuths, boolean> = (payload) => {
  return firebaseCall("admin-deleteAuthUsers")(payload);
};

export const sendResetPasswordLink = (email: string) => {
  const auth = getAuth();
  return sendPasswordResetEmail(auth, email);
};
export const resetPassword = (password: string, code: string) => {
  const auth = getAuth();
  return confirmPasswordReset(auth, code, password);
};

export const loginWithEmailPassword = (email: string, password: string) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
};

export const logout = () => {
  const auth = getAuth();
  return signOut(auth);
};
export const loginAdmin: ApiCallback<void, boolean> = (payload) => {
  return firebaseCall("admin-loginAdmin")(payload);
};
export const getAdminTokenInfo: ApiCallback<void, any> = (payload) => {
  return firebaseCall("admin-getAdminTokenInfo")(payload);
};

export const isSessionAuthenticated = () => {
  return Boolean(getAuth().currentUser);
};
