import Card from "components/layout/Card";
import { Text } from "@chakra-ui/react";
import ButtonPermission from "components/layout/ButtonPermission";
import { useBehave, useTask, useTaskCallback } from "behave-react";
import { UserAction } from "store/modules/users";
import useToastNotification from "hooks/useToastNotification";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const DeletePartner = (partner: UserPartner) => {
  const { dispatch } = useBehave();
  const navigate = useNavigate();
  const loading = useTask("deleting", partner.user_id);
  const { i18n } = useTranslation();

  useToastNotification(loading, { success: { description: "Partner deleted successfully!" } });
  useTaskCallback(loading, {
    success: () => navigate(`/${i18n.language}/users/${partner.user_id}`),
  });

  return (
    <Card mt="24px" width="max-content" flexDirection="row" gap="32px" alignItems="center">
      <Text fontSize="24px" fontWeight="bold">
        Danger zone
      </Text>
      <ButtonPermission
        flex={1}
        needConfirmation
        children="Delete partner"
        action="deletePartner"
        colorScheme="red"
        variant="outline"
        onClick={() => dispatch(UserAction.deletePartner(partner.user_id))}
        isLoading={loading.isLoading}
      />
    </Card>
  );
};

export default DeletePartner;
