import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";

export interface EmptyTableProps {
  image?: string;
  title?: string;
  imageHeight?: string | number;
}

const EmptyTable = ({ image, title, imageHeight }: EmptyTableProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      w="100%"
      color="gray.200"
      pt="20px"
      direction="column"
    >
      <Image
        src={image ?? "https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"}
        style={{
          maxHeight: imageHeight ?? 400,
        }}
      />
      <Text pt="10px">{title ?? "No data here!"}</Text>
    </Flex>
  );
};

export default EmptyTable;
