import Card from "../../components/layout/Card";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBehave, useTask } from "behave-react";
import CouponsColumns from "./TableColumns";
import ButtonPermission from "components/layout/ButtonPermission";
import { IoAdd } from "react-icons/io5";
import { ShopAction, ShopSelect } from "store/modules/shop";
import TableComponent from "components/layout/TableComponent";
import withPermissions from "components/hoc/withPermissions";

const CouponsPage = () => {
  const { t } = useTranslation();
  const { select, dispatch } = useBehave();
  const loading = useTask("fetching", "coupons");
  const coupons = select(ShopSelect.coupons) ?? [];

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = () => dispatch(ShopAction.fetchCoupons());

  const handleQuery = (i: string, data: Coupon) =>
    data?.code?.toLowerCase().includes(i) || data?.description?.toLowerCase().includes(i);

  const handleDelete = (code: string) => dispatch(ShopAction.deleteCoupon(code));

  return (
    <Card direction="column" w="100%" px="0px">
      <TableComponent
        query={handleQuery}
        queryPlaceholder={t("coupon.table.queryPlaceholder")}
        columns={CouponsColumns({ onDelete: handleDelete, t })}
        data={coupons}
        isLoading={loading.isLoading}
        onRefresh={handleRefresh}
        action1={
          <ButtonPermission
            to="create"
            variant="brand"
            leftIcon={<IoAdd />}
            action="createCoupon"
            minW="fit-content"
          >
            {t("coupon.table.action.create")}
          </ButtonPermission>
        }
      />
    </Card>
  );
};

export default withPermissions("getAllCoupons", CouponsPage);
