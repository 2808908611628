import { Avatar, Tag, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { Column } from "components/layout/TableComponent";
import { AdminColorSchema } from "theme/ColorsSchema";

const UserRegionColumns: (t: (string) => string, locale: string) => Column<SimpleAdmin>[] = (
  t,
  l
) => [
  {
    width: "32px",
    title: t("general.tableColumn.title.profile"),
    id: "profile_pic",
    render: (value, data) => <Avatar size="sm" src={value} name={data.email} />,
  },
  {
    title: t("general.tableColumn.title.email"),
    id: "email",
    width: "70%",
    render: (value, data) => (
      <NavLink to={`/${l}/admin/${data._id}`}>
        <Text color="brand.500">{value}</Text>
      </NavLink>
    ),
  },
  {
    title: t("general.tableColumn.title.permissionLevel"),
    id: "permission_level",
    width: "100%",
    minWidth: "300px",
    render: (value) => (
      <Tag variant="subtle" colorScheme={(AdminColorSchema as any)[value]} fontWeight="700">
        {value === "admin"
          ? t("general.permissionLevel.admin")
          : value === "standard_sales"
          ? t("general.permissionLevel.standardSales")
          : value === "advanced_sales"
          ? t("general.permissionLevel.advancedSales")
          : value === "executive"
          ? t("general.permissionLevel.executive")
          : value === "customer_service"
          ? t("general.permissionLevel.customerService")
          : value}
      </Tag>
    ),
  },
];

export default UserRegionColumns;
