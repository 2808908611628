import usePermissions from "hooks/usePermissions";
import { IoLockClosed } from "react-icons/io5";
import {
  Popover,
  Box,
  PopoverTrigger as PT,
  PopoverContent,
  PopoverBody,
  Button,
  ButtonProps,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Modal,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { NavLink } from "react-router-dom";

const PopoverTrigger: any = PT;

interface ButtonPermissionProps extends ButtonProps {
  action: AdminActions;
  to?: string;
  needConfirmation?: boolean;
}

const ButtonPermission = ({ action, needConfirmation, ...rest }: ButtonPermissionProps) => {
  const prop = { action, needConfirmation, ...rest };
  const { onClick, ...restProp } = prop;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { permit, loading } = usePermissions();
  const { t } = useTranslation();

  const Wrapper = ({ children }: any) => {
    if (permit(action) && rest.to) return <NavLink to={rest.to}>{children}</NavLink>;
    if (!permit(action))
      return (
        <Box position="relative" zIndex="10">
          <Popover trigger="hover">
            <PopoverTrigger>{children}</PopoverTrigger>
            <PopoverContent>
              <PopoverBody>{t("buttonPermission.popoverBody")}</PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      );
    else return children;
  };

  return (
    <>
      {needConfirmation && !prop.to && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {t("buttonPermission.components.popover.text.needConfirmation")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>{t("buttonPermission.components.popover.text.areYouSure")}</Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" variant="ghost" mr="12px" onClick={onClose}>
                {t("general.button.cancel")}
              </Button>
              <Button
                variant="outline"
                onClick={(e) => {
                  rest?.onClick?.(e);
                  onClose();
                }}
              >
                {t("general.button.continue")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Wrapper>
        <Button
          {...rest}
          isLoading={loading.isLoading || rest.isLoading}
          leftIcon={!permit(action) ? <IoLockClosed /> : rest.leftIcon}
          onClick={permit(action) ? (needConfirmation ? onOpen : rest.onClick) : () => {}}
        />
      </Wrapper>
    </>
  );
};

export default ButtonPermission;
