import { Text } from "@chakra-ui/react";
import { Column } from "components/layout/TableComponent";

const CreditsColumns: Column<CreditPack>[] = [
  {
    title: "Name",
    id: "name_id",
    width: "25%",
    render: (value) => (
      <Text fontSize="14px" color="brand.200" fontWeight="500" textTransform="lowercase">
        <Text>{value}</Text>
      </Text>
    ),
  },
  {
    title: "Description",
    id: "description_id",
    width: "25%",
    render: (value) => (
      <Text fontSize="14px" color="brand.200" fontWeight="500" textTransform="lowercase">
        <Text>{value}</Text>
      </Text>
    ),
  },
  {
    title: "Crediti",
    id: "credits",
    width: "25%",
    render: (value) => (
      <Text fontSize="14px" color="brand.200" fontWeight="500" textTransform="lowercase">
        <Text> {((value ?? 0) / 100).toFixed(0)}</Text>
      </Text>
    ),
  },
  {
    title: "Credits price",
    id: "price",
    width: "25%",
    render: (value, data) => <Text> {((value ?? 0) / 100).toFixed(0)}</Text>,
  },
];

export default CreditsColumns;
