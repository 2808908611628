import { Text, Flex, IconButton, Button } from "@chakra-ui/react";
import dateFormatter from "../../utils/DateFormatter";
import { useTask } from "behave-react";
import useToastNotification from "hooks/useToastNotification";
import usePermissions from "hooks/usePermissions";
import { Column } from "components/layout/TableComponent";
import ButtonPermission from "components/layout/ButtonPermission";
import { NavLink } from "react-router-dom";
import PartnerRequestActions from "./PartnerRequestActions";

interface ColumnsPartnerProps {
  t: (t: string) => string;
  locale: string;
  loading?: boolean;
  handleRefresh?: () => void;
}
const ColumnsPartner: (p: ColumnsPartnerProps) => Column<PartnerRequest>[] = ({
  t,
  locale,
  loading,
  handleRefresh,
}) => [
  {
    title: t("general.tableColumn.title.userSyntetic"),
    id: "syntetic_user",
    width: "80%",

    render: (value, data) => (
      <NavLink to={`/${locale}/users/${data.user_id}`}>
        <Text fontSize="14px" color="brand.200" fontWeight="500" textTransform="lowercase">
          <Text textTransform="capitalize">{value}</Text>
        </Text>
      </NavLink>
    ),
  },
  {
    width: "20%",
    title: t("general.tableColumn.title.date"),
    id: "date",
    render: (value) => (
      <Text whiteSpace="nowrap">{dateFormatter(value, "month_name_day_year")}</Text>
    ),
  },
  {
    title: t("general.tableColumn.title.action"),
    id: "user_id",
    render: (value, data) => (
      <PartnerRequestActions id={data.user_id} t={t} handleRefresh={() => handleRefresh} />
    ),
  },
];

export default ColumnsPartner;
