import React, { ReactNode, useEffect } from "react";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { FieldValues, useForm } from "react-hook-form";
import access, { Dotted } from "../../../utils/DotNotation";

import SingleCard from "./Components/SingleCard";
import SubmitUser, { SubmitCardProps } from "./Components/SubmitCard";
import { CardConfig } from "./Types/type";

interface EditModeFallback {
  title?: string;
  message: ReactNode;
  content?: ReactNode;
  action?: ReactNode;
}

interface FormBuilderProps<T extends FieldValues> {
  initialValues?: Partial<T>;
  cards: (CardConfig<T> | ReactNode)[];
  intialTransforms?: [Dotted<T>, (value: any) => any, (value: any) => any][];
  submit?: SubmitCardProps<T>;
  editMode?: boolean;
  empty?: boolean;
  fallback?: ReactNode | EditModeFallback;
  loading?: boolean | string;
  floating?: ReactNode;
  cardRender?: React.JSXElementConstructor<any>;
}

const FormBuilder = <T extends FieldValues>({
  cards,
  initialValues,
  intialTransforms,
  submit,
  editMode,
  fallback,
  loading,
  cardRender,
  empty,
  floating,
}: FormBuilderProps<T>) => {
  const _handleSubmit = (data?: T) => {
    if (!data) return;
    const transformed = { ...data };

    intialTransforms?.forEach((element) => {
      const el = access(transformed, element[0]);
      access(transformed, element[0], true, element[2](el));
    });

    submit?.onSubmit?.(transformed);
  };

  const applyTransforms = (data?: Partial<T>) => {
    if (!data) return undefined;
    const transformed = { ...data };

    intialTransforms?.forEach((element) => {
      const el = access(transformed, element[0]);
      access(transformed, element[0], true, element[1](el));
    });

    return transformed;
  };

  useEffect(() => {
    reset(applyTransforms(initialValues) as any);
  }, [loading]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<T>({
    mode: "onSubmit",
    defaultValues: applyTransforms(initialValues) as any,
  });

  const FormWrapper = ({ children }: { children: any }) => {
    if (loading)
      return (
        <Flex
          width="100%"
          height="100vh"
          alignItems="center"
          direction="column"
          gap="12px"
          justifyContent="center"
        >
          <Spinner margin="0px 24px" />
          {typeof loading === "string" && <Text opacity="0.5">{loading}</Text>}
        </Flex>
      );

    if (editMode && empty) {
      const fbConfig = fallback as EditModeFallback;

      if (fbConfig?.message)
        return (
          <Flex
            width="100%"
            height="100vh"
            alignItems="center"
            direction="column"
            justifyContent="center"
            gap="12px"
          >
            {fbConfig.title && (
              <Text fontWeight="bold" fontSize="24px">
                {fbConfig.title}
              </Text>
            )}
            {fbConfig.message && <Text opacity="0.5">{fbConfig.message}</Text>}
            {fbConfig.content}
            {fbConfig.action}
          </Flex>
        );
      else return fallback;
    }

    if (submit)
      return (
        <form
          style={{ width: "100%" }}
          autoComplete="off"
          onSubmit={submit?.onSubmit ? handleSubmit(_handleSubmit as any) : undefined}
        >
          {children}
        </form>
      );
    else return children;
  };

  return (
    <FormWrapper>
      <Flex
        w="100%"
        wrap="wrap"
        gap="24px"
        alignItems="flex-start"
        pb={submit ? "140px" : undefined}
      >
        {cards
          .filter((a: any) => !a.hide)
          .map((card: any, i) =>
            React.isValidElement(card) ? (
              card
            ) : (
              <SingleCard
                cardRender={cardRender}
                {...card}
                _key={`${card.title ?? card.subtitle ?? i.toString()}`}
                _setValue={submit ? setValue : undefined}
                _register={submit ? register : undefined}
                _errors={submit ? errors : undefined}
                _control={submit ? control : undefined}
                _getValues={submit ? getValues : undefined}
              />
            )
          )}
        {submit && <SubmitUser cardRender={cardRender} {...submit} />}
      </Flex>
      {floating && (
        <Box position="fixed" bottom="32px" right="32px">
          {floating}
        </Box>
      )}
    </FormWrapper>
  );
};

export default FormBuilder;
