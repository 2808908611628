//import { createTask, PAction } from "behave-react";

import { PAction, putAction } from "behave-react";
import { takeLatest } from "typed-redux-saga";
import { SessionSagas } from ".";
import { AdminAction } from "../admin";
import { ShopAction } from "../shop";
import { UserAction } from "../users";

function* purge(action: PAction<string>) {
  yield putAction(ShopAction.purge());
  yield putAction(UserAction.purge());
  yield putAction(AdminAction.purge());
}

function* Saga() {
  yield* takeLatest<SessionSagas>("session/purge", purge);
}

export default Saga;
