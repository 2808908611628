import { useEffect, useState } from "react";
import Card from "components/layout/Card";
import { useBehave, useTask } from "behave-react";
import { Box, Flex, Text, Button, Tag, Divider, Input, Select, Spinner } from "@chakra-ui/react";
import CreditsColumn from "./components/CreditsColumns";
import dateFormatter from "../../utils/DateFormatter";

import { OrderStatusSchema } from "../../theme/ColorsSchema";
import { useParams } from "react-router-dom";
import { ShopAction, ShopSelect } from "store/modules/shop";
import LicenseColumns from "./components/DomainsColumns";
import useToastNotification from "hooks/useToastNotification";
import { useTranslation } from "react-i18next";
import TableComponent from "components/layout/TableComponent";
import ButtonPermission from "components/layout/ButtonPermission";
import withPermissions from "components/hoc/withPermissions";

const OrderPage = () => {
  const { oid } = useParams<{ id: string; oid: string }>();
  const { t } = useTranslation();
  const { dispatch, select } = useBehave();
  const order = select(ShopSelect.order(oid ?? ""));
  const loadingUpdate = useTask("updating", "orders");
  const [invoiceUrl, setInvoiceUrl] = useState(order?.invoice_url);
  const [paymentStatus, setPaymentStatus] = useState(order?.payment_status);

  useToastNotification(loadingUpdate, {
    success: { description: t("orders.notification.updated.description") },
  });

  const handleUpdateInvoice = () => {
    if (invoiceUrl) {
      const nd: UpdateOrder = { ...order, invoice_url: invoiceUrl, notify_user: false };
      dispatch(ShopAction.updateUserOrder(nd));
    }
  };
  const handleUpdateStatus = () => {
    const nd: UpdateOrder = { ...order, payment_status: paymentStatus, notify_user: false };
    dispatch(ShopAction.updateUserOrder(nd));
  };

  useEffect(() => {
    if (!oid) return;
    dispatch(ShopAction.fetchOrder(oid));
  }, [oid]);

  if (!order)
    return (
      <Flex alignItems="center" h="40vh" justifyContent="center" gap="16px" direction="column">
        <Text>{t("orders.orderNotFound.title")}</Text>
        <Text>
          <b>{oid}</b> {t("orders.orderNotFound.message")}
        </Text>
      </Flex>
    );

  return (
    <Flex direction="column" gap="20px">
      <Flex gap="20px" align="start">
        <Card direction="column">
          <Flex justifyContent="space-between" alignItems="center" mb="12px">
            <Flex>
              <Text mr="7px" fontSize="18px" color="gray.600" fontWeight="600" pl="20px">
                {t("orders.title.orderNumber")}
              </Text>
              <Text fontSize="18px" color="blue.500" fontWeight="600">
                #{order.orderNumber}
              </Text>
            </Flex>

            <Tag colorScheme={OrderStatusSchema[order.payment_status]} mr="20px" size="lg">
              {order.payment_status}
            </Tag>
          </Flex>
          <Divider />

          <Flex p="20px" direction="column" gap="6px">
            <Flex justifyContent="space-between" alignItems="center">
              <Text>{t("orders.title.total")}</Text>
              <Text fontWeight="600" color="blue.500" fontSize="32px">
                {((order.amount_with_vat ?? order.amount) / 100).toFixed(2)}€
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>{t("orders.title.iva")}</Text>
              <Text fontWeight="500" color="blue.500" fontSize="20px">
                {(((order.amount_with_vat ?? 0) * 0.22) / 1.22 / 100).toFixed(2)}€
              </Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex p="20px" direction="column" gap="6px">
            <Flex justifyContent="space-between">
              <Text>{t("orders.title.orderDate")}</Text>
              <Text fontWeight="500">{dateFormatter(order.date, "month_name_day_year")}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>{t("orders.title.coupon")}</Text>
              <Text fontWeight="500">{order.coupon ?? "No coupon used"}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text>{t("orders.title.referral")}</Text>
              <Text fontWeight="500">{order.referral ?? "No referral used"}</Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex p="20px" direction="column" gap="6px">
            <Flex justifyContent="space-between">
              <Text>{t("orders.title.paymantMethod")}</Text>
              <Tag colorScheme="telegram">{order.payment.method}</Tag>
            </Flex>
            {order.payment.cardInfo && (
              <>
                <Flex justifyContent="space-between">
                  <Text>{t("orders.title.cardEndingNumbers")}</Text>
                  <Text fontWeight="500">{order.payment.cardInfo.lastDigits}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text>{t("orders.title.cardBrand")}</Text>
                  <Text fontWeight="500">{order.payment.cardInfo.brand}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text>{t("orders.title.cardExpireDate")}</Text>
                  <Text fontWeight="500">
                    {order.payment.cardInfo.expMonth} / {order.payment.cardInfo.expYear}
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Card>
        <Card direction="column">
          <Text mr="7px" fontSize="18px" color="gray.600" fontWeight="600" pl="20px" mb="12px">
            {t("orders.title.billingInformation")}
          </Text>
          <Divider />
          {order.billing && (
            <Flex p="20px" direction="column" gap="6px">
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.nameAndSurname")}</Text>
                <Text fontWeight="500">
                  {order.billing.name} {order.billing.surname}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.emailPEC")}</Text>
                <Text fontWeight="500">{order.billing.emailPec}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.piva")}</Text>
                <Text fontWeight="500">{order.billing.vatNumber}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.uniqueCode")}</Text>
                <Text fontWeight="500">{order.billing.uniqueCode}</Text>
              </Flex>
              <Divider />
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.address")}</Text>
                <Text fontWeight="500">{order.billing.address.address}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.city")}</Text>
                <Text fontWeight="500">{order.billing.address.city}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.province")}</Text>
                <Text fontWeight="500">{order.billing.address.state}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.postalCode")}</Text>
                <Text fontWeight="500">{order.billing.address.postalCode}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.country")}</Text>
                <Text fontWeight="500">{order.billing.address.country}</Text>
              </Flex>
            </Flex>
          )}

          <Divider />
          {order.additional_payment_info && (
            <Flex p="20px" direction="column" gap="6px">
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.cig")}</Text>
                <Text fontWeight="500">{order.additional_payment_info.cig}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.cup")}</Text>
                <Text fontWeight="500">{order.additional_payment_info.cup}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text>{t("general.form.label.splitPayment")}</Text>
                <Text fontWeight="500">{order.additional_payment_info.splitPayment}</Text>
              </Flex>
            </Flex>
          )}
          <Divider />
          {order.payment_status !== "PAY_NOW" && (
            <>
              <Flex gap="10px" mt="16px" alignItems="center" justifyContent="space-between">
                <Text
                  whiteSpace="nowrap"
                  mr="7px"
                  fontSize="18px"
                  color="gray.600"
                  fontWeight="600"
                  pl="20px"
                >
                  {t("orders.title.editPayment")}
                </Text>
                <Select
                  defaultValue={paymentStatus}
                  onChange={(t: { target: { value: string } }) =>
                    setPaymentStatus(t.target.value as Order["payment_status"])
                  }
                  w="100%"
                >
                  <option value={"COMPLETED"}>{t("general.select.option.paid")}</option>
                  <option value={"PENDING"}>{t("general.select.option.pendingPayment")}</option>
                  <option value={"REJECTED"}>{t("general.select.option.rejected")}</option>
                </Select>
                <ButtonPermission
                  action="updateOrderForUser"
                  variant="outline"
                  onClick={handleUpdateStatus}
                  isLoading={loadingUpdate.isLoading}
                >
                  {t("general.button.save")}
                </ButtonPermission>
              </Flex>

              <Box w="100%" pt="15px" pb="15px">
                <Box borderBottom="1px solid #e2e8f0" w="100%"></Box>
              </Box>
              <Text mr="7px" fontSize="18px" color="gray.600" fontWeight="600" pl="20px">
                {t("orders.title.invoice")}
              </Text>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                pt="10px"
                pb="15px"
                gap="10px"
                pl="20px"
              >
                <Input
                  defaultValue={invoiceUrl}
                  onChange={(e) => setInvoiceUrl(e.target.value)}
                  inputMode="text"
                  contentEditable
                  placeholder={t("orders.placeholder.invoice")}
                />

                <ButtonPermission
                  action="updateOrderForUser"
                  variant="outline"
                  onClick={handleUpdateInvoice}
                  isLoading={loadingUpdate.isLoading}
                >
                  {t("general.button.update")}
                </ButtonPermission>
              </Flex>
            </>
          )}
        </Card>
      </Flex>
      <Card pl="0px" pr="0px" pt="32px" alignItems="stretch">
        {order.credits ? (
          <TableComponent
            data={[order.credits]}
            columns={CreditsColumn}
            title={t("orders.tableTitle.orders")}
          />
        ) : (
          <TableComponent
            data={order.domains}
            columns={LicenseColumns}
            title={t("orders.tableTitle.orders")}
          />
        )}
      </Card>
    </Flex>
  );
};

export default withPermissions("updateOrderForUser", OrderPage);
