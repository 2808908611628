import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import api from "api";
import { useBehave, useTask } from "behave-react";
import Card from "components/layout/Card";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoadingPage from "pages/LoadingPage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminAction } from "store/modules/admin";
import { SessionAction, SessionSelect } from "store/modules/session";
import Authorized from "./Authorized";
import Unauthorized from "./Unauthorized";

const Navigation = () => {
  const { select, dispatch } = useBehave();
  const authenticated = select(SessionSelect.authenticated);
  const loadingAuth = useTask("fetching", "auth");
  const [loading, isLoading] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    api.init();
  }, []);

  useEffect(() => {
    isLoading(true);

    onAuthStateChanged(getAuth(), (user) => {
      isLoading(false);
      if (user?.uid && !loadingAuth.isLoading) {
        dispatch(SessionAction.authenticate({ user, authenticated: user?.uid }));
        dispatch(AdminAction.getAdmin());
        dispatch(AdminAction.getRegion());
      } else dispatch(SessionAction.purge());
    });
  }, [loadingAuth.isLoading]);

  const PageRendered = () => {
    if (loading) return <LoadingPage />;
    return authenticated ? <Authorized /> : <Unauthorized />;
  };

  return (
    <Routes>
      <Route path="en/*" element={<PageRendered />} />
      <Route path="it/*" element={<PageRendered />} />
      <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
    </Routes>
  );
};

export default Navigation;
