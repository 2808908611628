import React from "react";
import { Text, Button, Flex, Icon, Avatar } from "@chakra-ui/react";
import dateFormatter from "../../../utils/DateFormatter";
import Card from "components/layout/Card";
import { AiOutlineDelete } from "react-icons/ai";
import { BsCheckAll, BsCheck } from "react-icons/bs";
import parse from "html-react-parser";
import { useBehave, useTask } from "behave-react";
import { UserAction } from "store/modules/users";

interface CommunicationItemProps {
  communication: Communication;
}
const CommunicationItem = ({ communication }: CommunicationItemProps) => {
  const loadingDelete = useTask("deleting", communication._id);
  const { dispatch } = useBehave();
  const handleDelete = () => {
    dispatch(
      UserAction.deleteCommunication({
        _id: communication._id,
        user: communication.user,
      })
    );
  };
  return (
    <Flex justifyContent={communication.sender === "ADMIN" ? "flex-end" : "start"}>
      <Flex direction="column">
        <Card
          width="fit-content"
          maxW="450px"
          background={communication.sender === "ADMIN" ? "transparent" : "#085ce0"}
          color={communication.sender === "ADMIN" ? "#000" : "#f4f4f4"}
          border={communication.sender === "ADMIN" ? "1px solid" : "none"}
          borderColor={communication.sender === "ADMIN" ? "#ededed" : "none"}
          padding={"15px 20px 15px 20px"}
          mt="10px"
          justifyContent="flex-end"
        >
          <Flex alignItems="center">
            {communication.sender === "ADMIN" ? (
              <Avatar name={communication.user} mb="10px" mr="10px" />
            ) : (
              <Avatar name={communication.user} mb="10px" mr="10px" />
            )}
            <Text fontWeight="700" mr="2px">
              {communication.sender}
            </Text>
            {communication.read ? <Icon as={BsCheckAll} /> : <Icon as={BsCheck} />}
          </Flex>
          {parse(communication.content)}
        </Card>
        <Flex justifyContent="space-between">
          <Text fontSize="13px" fontWeight="600" color="gray.500" mt="7px">
            {dateFormatter(communication.created_at, "month_name_day_year")}
          </Text>
          {communication.sender === "ADMIN" ? (
            <Button variant="unstyled" onClick={handleDelete} isLoading={loadingDelete.isLoading}>
              <Icon as={AiOutlineDelete} />
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CommunicationItem;
