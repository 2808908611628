import { useBehave, useTask, useTaskCallback } from "behave-react";
import withPermissions from "components/hoc/withPermissions";
import FormBuilder from "components/layout/FormBuilder";
import FormCard from "components/layout/FormCard";
import useToastNotification from "hooks/useToastNotification";
import { t } from "i18next";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ShopAction } from "store/modules/shop";
import { DISCOUNT_TYPE, DURATION_COUPON, TYPE_COUPON } from "utils/DataMaps";
import Regex from "../../utils/Regex";

const CreateCoupon = () => {
  const { dispatch } = useBehave();
  const loading = useTask("creating", "coupon");
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  useToastNotification(loading, {
    success: { description: `${t("couponCreate.notification.created.description")}` },
  });
  useTaskCallback(loading, { success: () => navigate(`/${i18n.language}/coupons`) });

  const [isPercentage, setIsPercentage] = useState(true);

  const handleSubmit = (variables: CreateCoupon) => {
    variables.discount.type = isPercentage ? "PERCENTAGE" : "NET";
    if ((variables.filter.to_plan as any) === "all") delete variables.filter.to_plan;
    if ((variables.filter.to_user as any) === "") delete variables.filter.to_user;
    if ((variables.filter.to_type as any) === "all") delete variables.filter.to_type;
    dispatch(ShopAction.createCoupon(variables));
  };
  return (
    <FormBuilder
      cardRender={FormCard}
      initialValues={{ discount: { type: "PERCENTAGE", amount: 0 } }}
      intialTransforms={[
        [
          "discount.amount",
          (v) => v * (isPercentage ? 100 : 0.01),
          (v) => v * (isPercentage ? 0.01 : 100),
        ],
        ["code", (v) => v?.toUpperCase?.(), (v) => v?.toUpperCase?.()],
        [
          "expire_date",
          (v) => moment(v).format("yyyy-MM-DD"),
          (v) => moment(v, "yyyy-MM-DD").toDate().getTime(),
        ],
      ]}
      submit={{
        loading: loading.isLoading,
        onSubmit: handleSubmit,
        action: t("couponCreate.form.action.create"),
        color: "green",
        title: t("couponCreate.form.action.title"),
        subtitle: t("couponCreate.form.action.subtitle"),
      }}
      cards={[
        {
          title: t("couponCreate.form.title1st"),
          subtitle: `${t("couponCreate.form.subtitle1st")}`,
          elements: [
            [
              {
                id: "code",
                type: "text",
                label: t("couponCreate.form.label.code"),
                textTransform: "uppercase",
                placeholder: t("couponCreate.form.placeholder.code"),
                pattern: Regex.couponCode,
                patternError: t("couponCreate.form.patternError.code"),
                required: true,
              },
              {
                id: "expire_date",
                type: "date",
                label: t("general.form.label.expiresAt"),
                required: true,
              },
            ],

            {
              id: "description",
              type: "text",
              label: t("general.form.label.description"),
              placeholder: t("general.form.placeholder.description"),
              required: true,
            },

            [
              {
                value: isPercentage ? "PERCENTAGE" : "NET",
                type: "select",
                label: t("general.form.label.discountType"),
                options: DISCOUNT_TYPE,
                onChange: (e) => setIsPercentage(e.currentTarget.value === "PERCENTAGE"),
                required: true,
              },
              {
                id: "discount.amount",
                type: "number",
                label: t("general.form.label.discountAmount"),
                rightAddon: isPercentage ? "%" : "€",
                required: true,
              },
            ],
          ],
        },
        {
          title: t("couponCreate.form.title2nd"),
          subtitle: `${t("couponCreate.form.subtitle2nd")}`,
          elements: [
            [
              {
                id: "filter.to_plan",
                type: "select",
                label: t("couponCreate.form.label.applyToPlan"),
                options: TYPE_COUPON,
              },
              {
                id: "filter.to_type",
                type: "select",
                label: t("couponCreate.form.label.applyToDuration"),
                options: DURATION_COUPON,
              },
            ],
            {
              id: "filter.to_user",
              type: "text",
              label: t("couponCreate.form.label.applyToUser"),
              placeholder: t("couponCreate.form.placeholder.applyToUser"),
            },
          ],
        },
      ]}
    />
  );
};

export default withPermissions("createCoupon", CreateCoupon);
