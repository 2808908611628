const access = <T>(object: T, dotted?: string, setNewValue?: boolean, newValue?: any) => {
  if (!object) return undefined;
  if (!dotted) return undefined;
  const key = dotted as string;
  let k = key.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  k = k.replace(/^\./, ""); // strip a leading dot
  const pList = k.split(".");

  let schema: any = object;
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  if (setNewValue) schema[pList[len - 1]] = newValue;
  return schema[pList[len - 1]];
};

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7];

type Paths<T, D extends number = 2> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
    }[keyof T]
  : "";

export type Dotted<T> = Paths<T>;

export default access;
