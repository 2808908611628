import { Text } from "@chakra-ui/react";
import withPermissions from "components/hoc/withPermissions";
import TableComponent from "components/layout/TableComponent";
import { useTranslation } from "react-i18next";
import Card from "../../../components/layout/Card";
import UserRegionColumns from "./UserColumns";

interface RegionUsersProps {
  region?: Region;
}

const RegionUsers = ({ region }: RegionUsersProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Card maxW="600px" p="0px" pt="24px" pb="24px">
      <TableComponent
        title="Admins"
        data={region?.admins ?? []}
        columns={UserRegionColumns(t, i18n.language)}
      />
    </Card>
  );
};
export default RegionUsers;
